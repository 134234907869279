const dateOptions = [
  {
    label: 'Hoje',
    value: 0
  },
  {
    label: 'Ontem',
    value: -1
  },
  {
    label: 'Últimos 7 Dias',
    value: -7
  },
  {
    label: 'Últimos 15 Dias',
    value: -15
  },
  {
    label: 'Últimos 30 Dias',
    value: -30
  }
];

export { dateOptions };
