import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './locales/en';
import pt from './locales/pt';

const resources = {
  en: {
    translation: en
  },
  pt: {
    translation: pt
  }
};

const language = localStorage.getItem('language') || 'pt';

i18n.use(initReactI18next).init({
  resources,
  lng: language, // 默认语言
  fallbackLng: 'pt', // 如果没有找到当前语言的翻译，使用该语言的翻译
  interpolation: {
    escapeValue: false // 不需要转义特殊字符
  }
});
export default i18n;
