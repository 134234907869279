// import CryptoJS from 'crypto-js';
import { isInPWA } from './index';
export const cryptoID = (eventName: string) => {
//   return CryptoJS.SHA512(
//     eventName + '-' + localStorage.getItem('pwaId')
//   ).toString();
  return eventName + '-' + localStorage.getItem('pwaId')
};

export const reportParams = () => {
  const params = new URLSearchParams(location.search);
  return {
    client_orgin: params.get('platform') === 'android' ? 'APK' : isInPWA() ? 'PWA' : 'website',
    client_ip_address: localStorage.getItem('clientIp'),
    client_user_agent: navigator.userAgent,
  }
}