import { Image } from 'antd-mobile';
import { useNavigate, useSearchParams } from 'react-router-dom';
// import sanjiao from '@/assets/images/sanjiao.png';
import { ToLoginOrRegister } from '@/components/popup/ToLoginOrRegister';
import { getGameUrl, isLogin, checkPined } from '@/utils';
import { useCommonVisible } from '@/hooks';
import { useEffect } from 'react';
import ActivityList from './ActivityList';

import category_HOT from '@/assets/images/home/hot.png';
import category_SLOTS from '@/assets/images/home/slots.png';
import category_FISH from '@/assets/icon/category_FISH.png';
import category_LIVE from '@/assets/icon/category_LIVE.png';
import category_MINI from '@/assets/images/home/mini.png';
import category_ARCADE from '@/assets/icon/category_ARCADE.png';
import category_BINGO from '@/assets/icon/category_BINGO.png';
import category_RNG from '@/assets/icon/category_RNG.png';
import category_POKER from '@/assets/images/home/poker.png';
import classNames from 'classnames';
import { getS3Url } from '@/utils';

export const categoryMap = {
  HOT: category_HOT,
  SLOTS: category_SLOTS,
  MINI: category_MINI,
  POKER: category_POKER,
  FISH: category_FISH,
  LIVE: category_LIVE,
  ARCADE: category_ARCADE,
  BINGO: category_BINGO,
  RNG: category_RNG
};

const Main = ({ allGameList, gameCategorys }) => {
  const language = localStorage.getItem('language');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const loginPopup = useCommonVisible();
  const gp = searchParams.get('gp') || '';

  const jumpGame = (item, index) => {
    if (isLogin()) {
      const _window = window as any;
      if (_window.trackFn && _window.trackName) {
        console.log('判断是否有值StartTrial');
        _window.trackFn(_window.trackName['StartTrial']);
      }
      _window.isFromHome = true;
      navigate(
        `/game?gId=${item.gameId}&language=${language}${gp ? `&gp=${gp}` : ''}`
      );
    } else {
      // loginPopup.open();
      if (gameCategorys[index].type === 'Custom') {
        const categoryId = gameCategorys[index].categoryId;
        const customCategory = gameCategorys.find(
          item => String(item.id) === String(categoryId)
        );
        navigate('/middlepage', {
          replace: false,
          state: {
            currentGame:item,
            currentCategory: customCategory,
            currentRoom: gameCategorys[index].roomList[0],
          }
        });
      } else {
        navigate('/middlepage', {
          replace: false,
          state: {
            currentGame:item,
            currentCategory: gameCategorys[index]
          }
        });
      }

    }
  };

  useEffect(() => {
    const el = document.querySelector('#game-filter') as HTMLInputElement;
    const t = parseInt(getComputedStyle(el).top);

    return checkPined(el, el => {
        return new Promise(resolve => {
          setTimeout(() => {
            resolve(el.getBoundingClientRect().top <= t + 1);
          }, 20);
        });
      },
      5
    );
  }, []);

  return (
    <div id="main-container" className="mt-4">
      <ActivityList />

      <div
        className="overflow-x-auto mt-[12px] flex sticky top-14 z-5 left-3"
        id="game-filter"
      >
        {gameCategorys.map(item => {
          // 全部显示和只显示横向时显示
          return (
            (item.showType === 1 || item.showType === 3) && (
              <div
                className={classNames(
                  'w-20 relative flex flex-none justify-center h-[46px] leading-[46px] pl-8 pr-[14px] mr-3'
                  // item.code === currentCategory.code
                  //   ? 'bg-[#3e606f]'
                  //   : 'bg-white/[0.04]'
                )}
                key={`${item.id}-${item.code}`}
                onClick={() => {
                  // setCurrentCategory(item);
                  // gameDrawer.open();
                  if (item.type === 'Custom') {
                    const categoryId = item.categoryId;
                    const customCategory = gameCategorys.find(
                      item => String(item.id) === String(categoryId)
                    );
                    navigate('/game_category', {
                      replace: false,
                      state: {
                        isCustom: true,
                        customDisplay: item.display,
                        currentCategory: customCategory,
                        currentRoom: item.roomList[0],
                        icon: item.customIcon || item.icon
                      }
                    });
                  } else {
                    navigate('/game_category', {
                      replace: false,
                      state: {
                        currentCategory: item
                      }
                    });
                  }
                }}
                // onClick={() => {
                //   setCurrentCategory(item);
                //   const scrollContainer = document.getElementById('topBar')!;
                //   const brand = document.getElementById('brand')!;
                //   const gameFilter = document.getElementById('game-filter')!;
                //   const dom = document.getElementById(item.code)!;

                //   const offsetY =
                //     dom.offsetTop - brand.offsetHeight - gameFilter.offsetHeight;

                //   scrollContainer.scrollTo({
                //     top: offsetY,
                //     behavior: 'smooth'
                //   });
                // }}
              >
                <div className="h-full">
                  <div className="h-full flex items-center justify-center">
                    <img
                      src={
                        item.customIcon || item.icon || categoryMap[item.code]
                      }
                      className="w-[24px] h-[24px] block object-cover mr-[5px]"
                    />
                    <span className="leading-3  text-[13px] whitespace-nowrap text-clip text-[#FFDF12]">
                      {item.display || item.code || item.name}
                    </span>
                  </div>
                </div>
                {/* <div className="group-item-arrow"></div> */}
              </div>
            )
          );
        })}
      </div>

      <div className="panels">
        {allGameList.map(
          (gameList, index) =>
            (gameCategorys[index]?.showType === 1 ||
              gameCategorys[index]?.showType === 4) && (
              <div
                className="panel"
                key={index}
                id={gameCategorys[index]?.code}
              >
                <div className="gpanel-more-frame">
                  {/* <div className="group-icon-line"></div> */}
                  <div className="group-icon  flex justify-start items-center">
                    {/* {!gameCategorys[index] &&
                    (gameCategorys[index]?.customIcon ||
                      gameCategorys[index]?.icon) && (
                      <img
                        src={
                          gameCategorys[index]?.customIcon ||
                          gameCategorys[index]?.icon
                        }
                        className="w-[27px] mr-1"
                      />
                    )} */}

                    {gameCategorys[index] && (
                      <img
                        src={
                          gameCategorys[index]?.customIcon ||
                          gameCategorys[index]?.icon ||
                          categoryMap[gameCategorys[index]?.code]
                        }
                        className="w-5 h-5 object-cover mr-2"
                      />
                    )}

                    <div className="text-[#FFDF12] font-thin text-[13px]">
                      {gameCategorys[index]?.display ||
                        gameCategorys[index]?.code ||
                        gameCategorys[index]?.name}
                    </div>
                  </div>

                  <div
                    className="ggame-more"
                    // onClick={() => openGameDrawer(gameList, index)}
                    onClick={() => {
                      if (gameCategorys[index].type === 'Custom') {
                        const categoryId = gameCategorys[index].categoryId;
                        const customCategory = gameCategorys.find(
                          item => String(item.id) === String(categoryId)
                        );
                        navigate('/game_category', {
                          replace: false,
                          state: {
                            isCustom: true,
                            customDisplay: gameCategorys[index].display,
                            currentCategory: customCategory,
                            currentRoom: gameCategorys[index].roomList[0],
                            icon: gameCategorys[index].customIcon || gameCategorys[index].icon
                          }
                        });
                      } else {
                        navigate('/game_category', {
                          replace: false,
                          state: {
                            currentCategory: gameCategorys[index]
                          }
                        });
                      }
                    }}
                  >
                    <span className="more-type2">Mais</span>
                    <img
                      className="more-arrow"
                      src={getS3Url('home/arrow_white.png')}
                    />
                  </div>
                </div>
                <div className="container">
                  {gameList
                    .slice(0, gameCategorys[index]?.count || 6)
                    .map(item => (
                      <div
                        className="item ggame-item-frame"
                        key={`${item.gameId}-${index}`}
                      >
                        <div className="ggame-item-content">
                          <Image
                            // lazy
                            onClick={() => jumpGame(item, index)}
                            src={getGameUrl(item, language)}
                            height={150}
                            style={{ borderRadius: 8 }}
                            placeholder={
                              <img src="/images/game_placeholder.png" />
                            }
                            className="ggame-item-content-img"
                            fallback={
                              <>
                                <Image
                                  src={`/images/default_img.png`}
                                  placeholder={
                                    <img src="/images/game_placeholder.png" />
                                  }
                                  height={150}
                                  onClick={() => jumpGame(item,index)}
                                />
                                <span className="!whitespace-break-spaces">
                                  {item.display}
                                </span>
                              </>
                            }
                          />
                          {item.display && (
                            <span className="!whitespace-break-spaces">
                              {item.display}
                            </span>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )
        )}
      </div>

      <ToLoginOrRegister
        isOpen={loginPopup.visible}
        setIsOpen={loginPopup.close}
      />
    </div>
  );
};

export default Main;
