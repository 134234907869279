import { useSearchParams } from 'react-router-dom';
import down_arrow from '@/assets/icons/rebate/down_arrow.png';
import { InfiniteScroll, Popup, SpinLoading } from 'antd-mobile';
import { useCommonVisible } from '@/hooks';
import classNames from 'classnames';
import { useState, useEffect, useMemo } from 'react';
import { dateOptions } from './contants';
import * as api from '@/api';
import dayjs from 'dayjs';
import NavBarComponent from '@/components/NavBar';
import NoData from '@/components/NoData';
import CommonTable from '@/components/Table';
import PopupFooter from '@/components/PopupFooter';

const RebateHistory = () => {
  const [searchParams] = useSearchParams();
  const activityId = searchParams.get('activityId');
  const dateFilterModal = useCommonVisible();
  const [activeDateValue, setActiveDateValue] = useState(0);
  const [currentDate, setCurrentDate] = useState(dateOptions[0]) as any;
  const [hasMore, setHasMore] = useState(false);
  const [pageOptions, setPageOptions] = useState({
    page: 1,
    size: 20
  });
  const [historyAmount, setHistoryAmount] = useState(0);
  const [historyList, setHistoryList] = useState<any[]>([]);

  useEffect(() => {
    getHistoryAmount();
    getHistoryList();
  }, [currentDate]);

  const { startTime, endTime } = useMemo(() => {
    if (currentDate.value === 0) {
      const startTime = dayjs().format('YYYY-MM-DD');
      const endTime = dayjs().format('YYYY-MM-DD');
      return { startTime, endTime };
    } else if (currentDate.value === -1) {
      const startTime = dayjs()
        .add(currentDate.value, 'day')
        .format('YYYY-MM-DD');
      const endTime = dayjs()
        .add(currentDate.value, 'day')
        .format('YYYY-MM-DD');
      return { startTime, endTime };
    } else {
      const startTime = dayjs()
        .add(currentDate.value + 1, 'day')
        .format('YYYY-MM-DD');
      const endTime = dayjs().format('YYYY-MM-DD');
      return { startTime, endTime };
    }
  }, [currentDate]);

  const getHistoryAmount = async () => {
    const result = await api.getHistoryAmount({
      startTime,
      endTime,
      activityId
    });
    if (result.code === 200) {
      setHistoryAmount(result.data);
    }
  };

  const getHistoryList = async () => {
    const result = await api.getRebateHistoryList({
      startTime,
      endTime,
      page: pageOptions.page,
      size: pageOptions.size,
      activityId
    });
    if (result.code === 200) {
      if (historyList.length >= result.data.total) {
        setHasMore(false);
      } else {
        setHasMore(true);
        setPageOptions({
          ...pageOptions,
          page: pageOptions.page + 1
        });
        setHistoryList([...historyList, ...(result.data.records || [])]);
      }
    }
  };

  const columns = [
    {
      title: 'Tempo',
      prop: 'claimDate',
      render: item => dayjs(item.claimDate).format('MM/DD')
    },
    {
      title: 'Bônus',
      prop: 'claimAmount',
      render: item => <div className="text-[#FFDF12]">{item.claimAmount}</div>
    }
  ];

  return (
    <div className="w-full h-[calc(100vh-var(--vh-offset,0px))] overflow-y-scroll">
      <NavBarComponent
        className="sticky"
        left={<span className="text-white text-lg font-[500]">Histórico</span>}
      />

      {/* 日期过滤器 */}
      <div className="flex items-center justify-between px-4 py-3">
        <div className="flex">
          <span className="text-white text-base">Bonus</span>
          <span className="text-[#FFDF12] font-bold text-base mx-1">
            {historyAmount}
          </span>
        </div>
        <div
          className="flex justify-center items-center"
          onClick={() => {
            dateFilterModal.open();
            setActiveDateValue(currentDate.value);
          }}
        >
          <span className="text-white text-base">{currentDate?.label}</span>
          <img src={down_arrow} className="w-6 h-6 ml-1" />
        </div>
      </div>

      {/* 领取历史列表 */}
      <div className="px-4 pt-3 pb-8">
        <CommonTable columns={columns} dataSource={historyList}></CommonTable>
        {historyList.length ? (
          hasMore && (
            <InfiniteScroll loadMore={getHistoryList} hasMore={hasMore}>
              {hasMore && (
                <>
                  <SpinLoading style={{ '--size': '16px' }} />
                  <span className="ml-[5px]">Carregando.....</span>
                </>
              )}
            </InfiniteScroll>
          )
        ) : (
          <NoData />
        )}
      </div>

      {/* 日期筛选模态框 */}
      <Popup
        visible={dateFilterModal.visible}
        onMaskClick={dateFilterModal.close}
        bodyClassName="w-full bg-[#2F1780] bottom-0 rounded-t-[24px]"
      >
        <div className="w-full h-11 text-base flex justify-center items-center bg-[#6453FD]/75 text-white sticky top-0 rounded-t-[20px]">
          Condições de Filtro
        </div>
        <p className="text-[#fff]/85 text-[17px] mt-[20px] px-4">
          Selecionar Tempo
        </p>
        <div className="px-4 flex justify-between flex-wrap mt-[20px] gap-y-[15px]">
          {dateOptions.map(item => {
            return (
              <div
                key={item.value}
                onClick={() => setActiveDateValue(item.value)}
                className={classNames(
                  'w-[172px] h-11 text-sm rounded-lg text-center leading-[49px]',
                  activeDateValue === item.value
                    ? 'bg-[#6453FD]/40 text-white'
                    : 'bg-[#6453FD]/20 text-white/75'
                )}
              >
                {item.label}
              </div>
            );
          })}
        </div>

        <div className="mx-4 my-8">
          <PopupFooter
            onCancel={dateFilterModal.close}
            onConfirm={() => {
              setCurrentDate(
                dateOptions.find(item => item.value === activeDateValue)
              );
              setPageOptions({
                page: 1,
                size: 20
              });
              setHistoryList([]);
              dateFilterModal.close();
            }}
          />
        </div>
      </Popup>
    </div>
  );
};

export default RebateHistory;
