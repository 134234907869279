import { useMemo } from "react";
import { useCountdownTime } from "@/utils/hooks";
import dayjs from "dayjs";

type CountDownProps = {
  targetTime: number;
  onEnd?: () => void;
  children: (time: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  }) => React.ReactNode;
};

const CountDown = ({
  targetTime = 10000,
  onEnd = () => {},
  children
}: CountDownProps) => {
  const initialTime = useMemo(() => {
    const endTimeMs = dayjs(Date.now() + targetTime).valueOf();

    const currentTimeMs = dayjs().valueOf();
    return endTimeMs - currentTimeMs;
  }, [targetTime]);

  const [days, hours, minutes, seconds] = useCountdownTime(initialTime, onEnd);

  return (
    <div className='countDown'>
      {children({ days, hours, minutes, seconds })}
    </div>
  );
};

export default CountDown;
