export const preloadRoute = (path: string) => {
  const routes = {
    '/promocao': () => import('@/views/Promocao'),
    '/servico': () => import('@/views/Servico'),
    '/userCenter': () => import('@/views/UserCenter'),
    '/deposito': () => import('@/views/Deposito'),
  };

  if (routes[path]) {
	if (window.requestIdleCallback) {
		window.requestIdleCallback(() => {
		routes[path]();
		});
	} else {
		setTimeout(() => {
			routes[path]();
		}, 0);
	}
  }
};