export default {
  hello: 'Olá Mundo!',
  errorMsg: {
    '101': 'O login está desativado, por favor faça login novamente.',
    '511': 'O envelope vermelho foi reivindicado.',
    '1016': 'Fora do horário do evento.',
    '40101': 'O nome de usuário já está em uso, por favor, altere-o.',
    '40102': 'Você não pode modificar estas informações do usuário.',
    '40104':
      'Inicio de sesión fallido, nombre de usuario o contraseña incorrectos.',
    '40201':
      'O nome de usuário está no formato errado, por favor, modifique e tente novamente.',
    '40202':
      'A senha está em formato incorreto, por favor, altere-a e tente novamente.',
    '40206': 'Senha antiga incorreta inserida. Por favor, insira novamente.',
    '40207':
      'O apelido não pode estar vazio, por favor, insira e tente novamente.',
    '40212':
      'O valor do depósito está fora do intervalo, por favor, insira um valor válido.',
    '40213':
      'O valor do saque está fora do intervalo, por favor, insira um valor válido.',
    '40320':
      'O canal de pagamento que você escolheu não está funcionando, por favor, escolha novamente.',
    '40321':
      'O canal de pagamento que você escolheu não está funcionando, por favor, escolha novamente.',
    '40322': 'O saque falhou, por favor, tente novamente mais tarde.',
    '40323': 'Falha ao depositar, exceção de parâmetro da ordem de pull.',
    '40324':
      'Falha ao depositar, nenhum valor foi enviado, por favor, tente novamente.',
    '40325':
      'Falha ao depositar, informações do canal erradas, selecione novamente o canal de depósito.',
    '40326':
      'Falha na recarga. Por favor, tente novamente a operação de recarga.Falha ao depositar, por favor, selecione novamente o seu canal de pagamento.',
    '40327':
      'O saque falhou. As informações de saque estão incorretas, por favor, tente novamente.',
    '40328':
      'O saque falhou, nenhum valor enviado, por favor, modifique e tente novamente.',
    '40329':
      'O saque falhou, informações do canal incorretas, por favor, tente novamente.',
    '40330':
      'O saque falhou, informações de coleta incorretas, por favor, tente novamente.',
    '40331':
      'O saque falhou, o canal está ocupado, por favor, tente novamente.',
    '40332':
      'Depósito falhou. Por favor, entre em contato com o serviço de atendimento ao cliente.',
    '40333':
      'Saque falhou, por favor, entre em contato com o serviço de atendimento ao cliente.',
    '40334':
      'Falha ao consultar o depósito, o parâmetro de solicitação está anormal, por favor, tente novamente.',
    '40335':
      'Falha ao consultar os saques, o parâmetro de solicitação está anormal, por favor, tente novamente.',
    '40336':
      'Falha ao consultar a ordem do canal tripartite, por favor, tente novamente.',
    '40337':
      'As informações do pedido estão anormais, por favor, entre em contato com o serviço de atendimento ao cliente para resolver o problema.',
    '40338':
      'As informações do pedido estão anormais, por favor, entre em contato com o serviço de atendimento ao cliente para resolver o problema.',
    '40339':
      'As informações do pedido estão anormais, por favor, entre em contato com o serviço de atendimento ao cliente para resolver o problema.',
    '40340':
      'As informações do pedido estão anormais, por favor, entre em contato com o serviço de atendimento ao cliente para resolver o problema.',
    '40341':
      'As informações do pedido estão anormais, por favor, entre em contato com o serviço de atendimento ao cliente para resolver o problema.',
    '40342':
      'As informações do pedido estão anormais, por favor, entre em contato com o serviço de atendimento ao cliente para resolver o problema.',
    '40343':
      'As informações do pedido estão anormais, por favor, entre em contato com o serviço de atendimento ao cliente para resolver o problema.',
    '40344':
      'Cantidad de apuestas insuficiente, no se puede procesar el retiro.',
    '40345': 'Solicitud anómala, por favor actualice e intente de nuevo!',
    '40346': 'Solicitud anómala, por favor actualice e intente de nuevo!',
    '40347':
      'As informações do pedido estão anormais, por favor, entre em contato com o serviço de atendimento ao cliente para resolver o problema.',
    '40349': 'El pedido ha sido actualizado, por favor actualice la página.',
    '40350': 'Excedeu o limite de contas vinculadas ao CPF',
    '41000': 'Opa, o servidor deu uma engasgada~',
    '41001': 'Erro na solicitação. Por favor, tente novamente mais tarde.',
    '41002': 'O processamento do serviço falhou, por favor, tente novamente.',
    '41003':
      'As informações da sua conta não foram encontradas, por favor, tente novamente.',
    '41010':
      'Não autorizado a consultar estas informações da carteira, por favor, tente novamente.',
    '41011':
      'No hay comisión disponible para retirar. Invita a más usuarios nuevos para ganar comisiones.',
    '41012':
      'Saldo de la billetera insuficiente, por favor complete una recarga primero.',
    '41013': 'Información anómala, por favor actualice e intente de nuevo.',
    '40400': 'O código de resgate não existe!',
    '40401':
      'O código de resgate expirou, por favor verifique e tente novamente!',
    '40402':
      'O código de resgate foi desativado, por favor verifique ou substitua por outro código de resgate!',
    '40403': 'Excedeu o limite de resgates!',
    '40404':
      'Este código de resgate já foi usado, por favor utilize outro código de resgate!',
    '40405': 'A campanha de resgate ainda não foi iniciada, por favor aguarde!',
    '40406': `Complete a tarefa de recarga de hoje para receber a recompensa do código de resgate!`,
    '40407': `Código de resgate não válido para este usuário! Por favor, use outro código de resgate!`,
    '40408': `Este código de resgate não pode ser utilizado, por favor, use outro código de resgate!`,
    '40409': `Falha na troca, por favor, tente novamente mais tarde!`,
    '40410': `O código de resgate não pode estar vazio!`,
    '40411': `Processando, sua ação foi rápida demais.`,
    '40412': `Falha na troca, por favor, tente novamente mais tarde!`,
    '20002': `As informações do pedido estão anormais, por favor, entre em contato com o serviço de atendimento ao cliente para resolver o problema.`,
    '40512': `ocê não tem recompensas para coletar.`,
    '40513': `Falha ao coletar a recompensa. Por favor, tente novamente.`,
    '41018': `Seu pedido não atende aos requisitos.`,
    '40451': `Sinto pena! Por favor, recupere suas recompensas!`,
    '40452': `Recebendo prêmios... aguarde!`,
    '40453': `Prezado! A solicitação falhou, tente novamente mais tarde!`,
    '40454': `o código de recompensa não existe ou expirou!`,
    '40530': `Falha ao receber, você não atingiu o nível necessário.`,
    '40531': `Falha ao receber, não há recompensas disponíveis para receber.`,
    '40532': `A recompensa está sendo processada, tente novamente mais tarde.`,
    '20003': `Falha ao receber a recompensa`,
    '40510': `A atividade do baú não está ativada, por favor, tente novamente`,
    '40511': `Falha ao receber, o prêmio do baú já foi coletadoVocê já resgatou a recompensa para este período!`,
    '40514': `Você não atende aos requisitos do evento`,
    '41014': `O evento não começou, por favor tente novamente!`,
    '41015': `O evento não começou, por favor tente novamente!`,
    '41016': `O evento não começou, por favor tente novamente!`,
    '41017': `Recompensa foi reivindicada.`,
    '40351': `É necessário realizar um depósito para poder sacar.`,
    '41021': `Você não pode reivindicar essa recompensa.`,
    '41022': `Falha ao reivindicar, você não atingiu o valor de depósito.`,
    '41023': `Falha ao reivindicar, você não atingiu o valor de apostas.`,
    '40199': `A restrição de registro por IP não foi atendida.`,
    '40301': 'Falha na criação do pedido de saque.',
    '40413': `É necessário recarregar dentro de 7 dias para poder resgatar.`,
    '80198': `Por favor, insira seu número de telefone antes de solicitar o código de verificação.`,
    '40610': `Envios de mensagens de texto estão muito frequentes.`,
    '40614': `Falha no envio do código de verificação, por favor, tente novamente.`,
    '40611': `Seu código de verificação está incorreto, por favor, insira novamente.`,
    '40612': `O número de telefone já está vinculado. Por favor, altere-o e tente novamente.`,
    '40613': `Falha no envio do código de verificação, limite de tentativas diárias excedido.`,
    '40099': `Falha no envio do código de verificação, o canal de SMS foi desativado.`,
    '40515': `Não existem registros de prêmios`,
    '40516': `O status do registro da recompensa está anormal, não é possível receber a recompensa`,
    '40517': `O evento Super Spin está fechado/expirado`,
    '40518': `Falha no Super Spin`,
    '40519': `A atividade do carrossel não é válida`,
    '40520': `Seu nível VIP não atende aos requisitos da atividade.`,
    '40521': `A verificação de SMS do usuário não satisfaz os requisitos de coleta`,
    '40522': `Configuração anormal dos prêmios da roda gigante`,
    '40523': `Por enquanto, você não pode participar do sorteio (o número de vezes que você pode participar do sorteio é 0)`,
    '40524': `Recompensa solicitada`,
    '40525': `anomalia na loteria`,
    '40526': `Falha na distribuição automática de prêmios (os usuários podem tentar coletar por conta própria)`,
    '40527': `Você ainda tem recompensas de convite não reclamadas, portanto, apresse-se e conclua seus convites de amigos para reclamar suas recompensas.`,
    '40630': 'Desculpe, você não concluiu as condições necessárias para raspar o cartão!',
    '40631': 'Desculpe, a atividade de raspadinha está temporariamente fechada!',
    '40632': 'Desculpe, o número de tentativas de raspadinha para hoje acabou!',
  },
  successMsg: 'sucesso',
  loading: 'Carregando',
  extractionFailed: 'Falha na extração',
  // 模板相关
  app: 'Verificado pelo aplicativo',
  reviews: 'revisões',
  downloads: 'Downloads',
  rated: 'Rated for',
  play: 'Jogar',
  install: 'Rapid Instalar',
  removed: 'O aplicativo foi removido',
  share: 'Compartilhar',
  wishlist: 'Adicionar à lista de desejos',
  about: 'Sobre este jogo',
  updated: 'Atualizado em',
  data: 'Segurança dos dados',
  safety:
    'A segurança começa com a compreensão de como os desenvolvedores coletam e compartilham seus dados. As práticas de privacidade e segurança de dados podem variar de acordo com seu uso, região e idade. O desenvolvedor forneceu essas informações e poderá atualizá-las com o tempo.',
  parties: 'Os dados não são compartilhados com terceiros',
  sharing:
    'Saiba mais sobre como os desenvolvedores declaram o compartilhamento',
  types: 'Este aplicativo pode coletar esses tipos de dados',
  location: 'Local, Atividade no app e Identificadores do dispositivo e outros',
  transit: 'Os dados são criptografados em trânsito',
  request: 'Você pode solicitar a exclusão dos dados',
  details: 'Mais detalhes',
  Games: 'Jogos',
  Apps: 'Apps',
  Movies: 'Filmes',
  Books: 'Livros',
  Kids: 'Crianças',
  now: 'agora',
  environmental: 'Detecção bem-sucedida',
  detecting: 'Detecção do ambiente de instalação...',
  found: 'Aplicativo não encontrado',
  installed: 'Instalado com sucesso', // 已成功安装
  experience:
    'Localize o ícone do aplicativo na sua tela inicial e toque em Executar para desfrutar de uma experiência mais conveniente e tranquila.', // 请在您的主屏幕上找到应用图标并点击运行，享受更加便捷流畅的体验。
  name: 'Nome do aplicativo', // 应用名称
  Mobile: 'Ícones móveis', // 手机端图标
  icon: 'Ícone do PC', // PC端图标
  noSupport: 'Seu navegador não suporta pwa',
  noDownloadUrl: 'Você não configurou o endereço de download do aplicativo',
  unregister: 'Desinstale o aplicativo na área de trabalho primeiro',
  noFindApp: 'Aplicativo não encontrado',
  cancelDownload: 'Você cancelou a instalação!',
  failDownload: 'A instalação falhou!',
  messageInfo: {
    1: ` Troca realizada com sucesso! 🎉
🏆 Valor do prêmio: R$ {{amount}} 🏆
O prêmio já foi creditado e você pode verificar e sacar na página 'Minha Conta'.
Obrigado por participar, mais surpresas esperam por você!`,
    2: `Parabéns, você recebeu o bônus de primeiro depósito de R$ {{ amount }}`,
    3: `Receba o bônus de agente de R$ {{ amount }}`,
    4: `Retirar comissäo R$ {{ amount }} com sucesso`,
    5: `Receba o bônus Magnata de R$ {{ amount }}`,
    6: `Receba o prêmio da chuva de envelopes vermelhos de R$ {{ amount }}`,
    7: `Receba a recompensa de nível R$ {{amount}} `,
    8: `Receba recompensa vip R$ {{amount}}`,
    9: `Receba o bônus cumulativo VIP de R$ {{amount}}`,
    10: `Receba o bônus PWA de R$ {{amount}}`,
    11: `Receba o Bônus diário de depósito R$ {{amount}}`,
    12: `Receba recompensas de baú de tesouro R$ {{amount}}`,
    13: `Tornando-se o jogador sortudo de hoje. Clique na blind box abaixo para receber um bônus, Recompensa máxima R$ {{amount}}`,
    14: `Receba o bônus de compensação semanal de R$ {{amount}}`,
    15: `Receba o bônus de auxílio de  R$ {{amount}}`,
    16: `Depósito R$ {{amount}} sucess`,
    18: `Submeta a saque R$ {{amount}} aplicacäo com sucesso`,
    19: `Saque R$ {{amount}} com sucesso`,
    20: `A retirada falhou. R$ {{amount}} foram devolvidos à conta,Retire o dinheiro novamente ou entre em contatocom o atendimento ao cliente.`,
    21: `Concedeu do sistema R$ {{amount}} `,
    22: `Débito do sistema R$ - {{amount}}`,
    23: `Receba R$ {{amount}} para ajudar um amigo a abrir a recompensa do baú.`,
    29: `Resgatar R$ {{amount}} de Recompensa de Rebate de Apostas`,
    31: `Resgatar recompensa da raspadinha de R$ {{amount}}.`
  }
};
