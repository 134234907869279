import { useMemo } from 'react';
import NavBarComponent from '@/components/NavBar';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';

const Details = () => {
  const location = useLocation();
  const detailsData = location.state?.data;

  const dataList = useMemo(() => [
    {
      title: 'ID',
      value: 'sourceId',
    }, {
      title: 'Valor',
      value: 'amount',
    }, {
      title: ['Atividade de origem', 'Recompensa do sistema'],
      value: 'sourceName',
    }, {
      title: 'Hora do prêmio', // 开奖时间
      value: 'createRecordTime',
    }, {
      title: 'Hora de recebimento', // 领取时间
      value: 'collectionTime',
    }
  ].map((item) => {
    const res: any = {
      title: '',
      value: '',
    }
    if (detailsData) {
      if (item.value === 'sourceName') {
        res.title = detailsData[item.value] == 'Sistema' ? item.title[1] : item.title[0];
        res.value = detailsData[item.value];
      } else {
        res.title = item.title;
        res.value = ['createRecordTime', 'collectionTime'].includes(item.value) ? dayjs(detailsData[item.value]).local().format('YYYY-MM-DD HH:mm:ss') : detailsData[item.value];
      }
    }
    return res;
  }), [detailsData]);

  // useEffect(() => {
  //   // getUrl(setUrl);
  // }, []);

  return (
    <div className='text-white'>
      <NavBarComponent
        left={
          <span className="text-white text-lg font-[500]">
            Detalhes
          </span>
        }
      />

      <div className='mx-4 mt-20 rounded-[8px] text-[15px] overflow-hidden'>
        {dataList.map(({title, value}, i) => (
          <div className='flex items-center justify-between bg-[#6453FD]/70 px-4 py-2 odd:bg-[#6453FD]/40 even:bg-[#6453FD]/20' key={i}>
            <span className='text-[#FFFFFFBF]'>{title}</span><span className=''>{value}</span>
        </div>
        ))}
      </div>
    </div>
  );
};

export default Details;
