import { useState } from 'react';

const useCommonVisible = (_visible = false) => {
  const [visible, setVisible] = useState(_visible);
  const open = () => setVisible(true);
  const close = () => setVisible(false);
  const toggle = () => setVisible(visible => !visible);
  return {
    visible,
    open,
    close,
    toggle
  };
};

type VisibleType = ReturnType<typeof useCommonVisible>;

export { useCommonVisible, type VisibleType };
