// import { Badge } from 'antd-mobile';
import { BarItem } from './BarItem';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getUserInfo } from '@/tools';
// import { ToLoginOrRegister } from '@/components/popup/ToLoginOrRegister';
import { getPayChannel } from '@/api';
import { useActiveConfigList } from '@/store';
import { getConfigList } from '@/api';
// import { useActivate, useUnactivate } from 'react-activation';
import './index.less';
import classNames from 'classnames';

interface iTabItem {
  key: string;
  title: string;
  icon: JSX.Element;
  badge?: boolean;
}
const tabList: iTabItem[] = [
  {
    key: 'inicio',
    title: '',
    icon: <BarItem type="inicio" />
  },
  {
    key: 'promocao',
    title: '',
    icon: <BarItem type="promocao" />,
    badge: false
  },
  {
    key: 'deposito',
    title: '',
    icon: <BarItem type="deposito" />
  },
  {
    key: 'servico',
    title: '',
    icon: <BarItem type="servico" hlName="_3" />
  },
  {
    key: 'meu',
    title: '',
    icon: <BarItem type="meu" />
  }
];

const BottomBar = () => {
  const allConfigList = useActiveConfigList(state => state.allConfigList);
  const updateAllConfigList = useActiveConfigList(
    state => state?.updateAllConfigList
  );

  const [searchParams] = useSearchParams();
  const { getToken } = getUserInfo();
  const [isLogin, setIsLogin] = useState(true);
  const [tabs, setTabs] = useState<iTabItem[]>(tabList);
  const navigate = useNavigate();

  // const tabKey = useTabKey(state => state.tabKey);
  // const setTabKey = useTabKey(state => state.setTabKey);
  // console.log('🚀 ~ BottomBar ~ tabKey:', tabKey);

  const getChannelList = async () => {
    const res = await getPayChannel();
    if (res.code === 200) setIsLogin(true);
    if (res.code === 101) setIsLogin(false);
  };

  // useActivate(() => {
  //   const isShowPoint = allConfigList.find(item => item.showRedPoint);
  //   tabs.find(item => item.key == 'promocao').badge = isShowPoint
  //     ? Badge.dot
  //     : false;
  //   setTabs([...tabs]);
  // });

  const queryConfigList = async () => {
    const list = await getConfigList();
    updateAllConfigList([...list]);
  };

  // @ts-ignore
  useEffect(() => {
    queryConfigList();
    return () => delete localStorage.pTabIndex
  }, []);

  useEffect(() => {
    const isShowPoint = !!allConfigList.find(item => item.showRedPoint);
    const promocaoTab = tabs.find(item => item.key === 'promocao');
    if (promocaoTab) {
      promocaoTab.badge = isShowPoint ? true : false;
      setTabs([...tabs]);
    }
  }, [allConfigList]);

  useEffect(() => {
    if (getToken()) getChannelList();
  }, []);

  const onTabItemChange = (key: string) => {
    // setTabKey(key)
    switch (key) {
      case 'promocao':
        navigate(`/promocao?${searchParams}`);
        break;
      case 'servico':
        navigate(`/servico?${searchParams}`);
        break;
      case 'deposito':
        if (!getToken() || !isLogin) {
          navigate(`/login/1?${searchParams}`);
          return;
        }
        navigate(`/deposito?${searchParams}`);
        break;
      case 'inicio':
        sessionStorage.fromSite = 1 // 隐藏首页弹框
        navigate(`/home?${searchParams}`);
        break;
      case 'meu':
        if (!getToken() || !isLogin) {
          navigate(`/login/1?${searchParams}`);
          return;
        }
        navigate(`/userCenter?${searchParams}`, {
          state: {
            activityList: allConfigList
          }
        });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="bottomBar">
          {tabs.map((item, i) => (
            <div
              className={classNames('item relative', {
                'on': item.badge
              })}
              key={i}
              onClick={() => {
                onTabItemChange(item.key)
              }}
              >
            {item.icon}
            </div>
          ))}
      </div>
    </>
  );
};

export default BottomBar;
