import request from './request';

// 查询代理用户信息
export const getAgentUserInfo = params =>
  request({
    url: '/agentUser/info',
    method: 'GET',
    params
  });
// 查询代理活动配置
export const getAgentConfig = () =>
  request({
    url: '/operation/agentConfig',
    method: 'GET'
  });

// 查询当天直属代理绩效列表
export const getSubAgentList = data =>
  request({
    url: '/agentUser/subAgent/list',
    method: 'POST',
    data
  });
// 代理佣金划转
export const agentProfitsTransfer = () =>
  request({
    url: '/wallet/agentProfitsTransfer',
    method: 'POST'
  });
// 领取代理奖励
export const agentProfitsReceive = () =>
  request({
    url: '/agentUser/receive',
    method: 'POST'
  });
// 代理中心-代理详情
export const agentDetailSearch = data =>
  request({
    url: '/agentUser/detailSearch',
    method: 'POST',
    data
  });
// 兑换码兑换
export const codeExchange = data =>
  request({
    url: '/redeem/code/exchange',
    method: 'POST',
    data
  });
// 代理中心收益报表
export const agentReportList = data =>
  request({
    url: '/agentUser/centerReport',
    method: 'POST',
    // headers:{'Content-Type': 'application/json'},
    data
  });
// 代理中心下级列表
export const agentSubList = data =>
  request({
    url: '/agentUser/sublist',
    method: 'POST',
    // headers:{'Content-Type': 'application/json'},
    data
  });

// 查询用户下代理返现的相关信息
export const getCashbackInfo = () =>
  request({
    url: '/agentUser/agent/cashback',
    method: 'GET'
  });
// 用户下代理返现提现
export const cashbackWithdraw = params =>
  request({
    url: '/agentUser/agent/cashback/withdraw',
    method: 'GET',
    params
  });
// 查询用户下代理返现的基于日期的详细信息
export const cashbackDetail = params =>
  request({
    url: '/agentUser/agent/cashback/detail',
    method: 'GET',
    params
  });
// 复制邀请链接
export const copyInviteRefer = () =>
  request({
    url: '/remain/activation/copyRefer',
    method: 'GET'
  });
// 查询域名配置
export const domainConfigList = () =>
  request({
    url: '/operation/domain/config/list',
    method: 'GET'
  });
// 获取用户救济金信息
export const getReliefInfo = params =>
  request({
    url: '/activity/relief/getReliefInfo',
    method: 'GET',
    params
  });
// 用户救济金领取
export const reliefReceive = params =>
  request({
    url: '/activity/relief/reliefReceive',
    method: 'GET',
    params
  });
