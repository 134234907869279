import { RightOutline } from 'antd-mobile-icons';
import { Mask, ProgressBar, Skeleton, Toast } from 'antd-mobile';
import { useState, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import * as api from '@/api';
import reward_bg from '@/assets/icons/rebate/reward_bg.png';
import reward_money from '@/assets/icons/rebate/reward_money.png';
import wallet_bg from '@/assets/icons/rebate/wallet_bg.png';
import closeIcon from '@/assets/icons/rebate/reward_close.png';
import realtimeBanner from '@/assets/rebate_banner1.png';
import nextDayBanner from '@/assets/rebate_banner2.png';
import activity_circle_tip from '@/assets/icons/common/activity_circle_tip.png';
import './index.less';
import { categoryMap } from '../Home/Main.tsx';
import { useCommonVisible } from '@/hooks/index.tsx';
import { getS3Url, isLogin } from '@/utils/index.ts';
import { debounce } from 'lodash';
import PhoneBindBox from '../Home/PhoneBindBox.tsx';
import { getUserInfo } from '@/tools/index.ts';
import { formatter } from '@/utils/mathWays.ts';
import NavBarComponent from '@/components/NavBar/index.tsx';
import { useCountdownTime } from '@/utils/hooks';
import dayjs from 'dayjs';

const skeletonAmount = 5;
const Rebate = () => {
  const navigate = useNavigate();
  const homeParams = localStorage.getItem('homeParams') || '';
  const [searchParams] = useSearchParams();
  const activityId = searchParams.get('activityId') || '';
  const checkPhoneSms = useCommonVisible();
  const [roomListOptions, setRoomListOptions] = useState<any>({
    list: [],
    loading: true
  });

  const [activityInfo, setActivityInfo] = useState<any>(null);
  const [gameCategorysOptions, setGameCategorysOptions] = useState({
    list: [] as any[],
    loading: true
  });
  const [activeCategory, setActiveCategory] = useState<any>(null);
  const rewardModal = useCommonVisible();
  const [rechargeOptions, setRechargeOptions] = useState<any>({
    data: null,
    loading: true
  });
  const [availableAmount, setAvailableAmount] = useState<any>(null);

  const initialTime = useMemo(() => {
    let ms = 0;
    if (activityInfo?.type === "NEXTDAY") {
      const h = +activityInfo?.dispatch;
      const curHour = dayjs().hour();
      const endTimeMs = dayjs().add(curHour >= h ? 1 : 0, 'day').set('hour', h).set('minute', 0).set('second', 0).valueOf();
      const currentTimeMs = dayjs().valueOf();
      ms = endTimeMs - currentTimeMs;
    }
    return ms;
  }, [activityInfo?.dispatch]);
  const [days, hours, minutes, seconds] = useCountdownTime(initialTime, () => getAvailableAmount());

  useEffect(() => {
    getRechargeDisplay();
    getGameCategorys().then(res => {
      setActiveCategory(res[0]);
    });
    getAvailableAmount();
    getActivityInfo();
  }, []);

  useEffect(() => {
    if (activeCategory) {
      getRoomList();
    }
  }, [activeCategory]);

  const canReward = useMemo(() => availableAmount > 0, [availableAmount]);

  const getActivityInfo = async () => {
    const params = new FormData();
    params.append('activityId', activityId);
    const {code, data} = await api.getActivityInfo(params);
    // console.log('data', data);
    const {
      defaultName,
      customName,
      customDesc,
      defaultDesc,
      useCustomName,
      useCustomDesc,
      awardLimitSms,
      configJson
    } = data || {};
    if (code === 200) {
      const d: any = {
        awardLimitSms,
        title: useCustomName === 'Y' ? customName : defaultName,
        desc:
          useCustomDesc === 'Y'
            ? customDesc?.split('\n')
            : defaultDesc?.split('\n')
      }

      if (configJson) {
        const config = JSON.parse(configJson);
        // console.log('config', config);
        d.type = config.rebaseType || 'REALTIME';
        d.dispatch = config.dispatch;
      }

      setActivityInfo(d);
    }
  };

  const getAvailableAmount = async () => {
    const params = new FormData();
    params.append('activityId', activityId);
    const result = await api.getAvailableAmount(params);
    if (result.code === 200 && result.data) {
      // console.log('result.data', result.data);
      setAvailableAmount(result.data.amount || 0);
    }
  };

  const getRoomList = async () => {
    const params = new FormData();
    params.append('activityId', activityId);
    params.append('categoryId', activeCategory?.id);
    const result = await api.getRoomList(params);
    if (result.code === 200) {
      setRoomListOptions({
        list: result.data,
        loading: false
      });
    }
  };

  const getRechargeDisplay = async () => {
    const params = new FormData();
    params.append('activityId', activityId);
    const result = await api.getRechargeDisplay(params);
    if (result.code === 200) {
      setRechargeOptions({
        data: result.data,
        loading: false
      });
    }
  };

  const getGameCategorys = async () => {
    const params = new FormData();
    params.append('activityId', activityId);
    const result = await api.getCategoryList(params);
    if (result.code === 200) {
      const categorys = result.data.filter(item => item.showType !== 2);
      setGameCategorysOptions({
        list: categorys,
        loading: false
      });
      return categorys;
    }
    return [];
  };

  const handleReward = debounce(async () => {
    if (activityInfo?.awardLimitSms) {
      if (!getUserInfo().userInfo?.smsVerify) {
        checkPhoneSms.open();
        return;
      }
    }
    const params = new FormData();
    params.append('activityId', activityId);
    const result = await api.confirmReward(params);
    if (result.code === 200) {
      if (result.data.success) {
        rewardModal.open();
        setAvailableAmount(result.data.amount);
      } else {
        Toast.show({
          content: 'Falha na coleta'
        });
      }
    }
  }, 250);

  return (
    <div className="w-full h-[calc(100vh-var(--vh-offset,0px))] rebate overflow-y-scroll">
      <NavBarComponent
        left={
          <span className="text-white text-lg font-[500]">
            {activityInfo?.title || 'Rebate de Apostas'}
          </span>
        }
        right={
          <div
            className="flex items-center justify-end"
            onClick={() => {
              if (isLogin()) {
                navigate(`/rebate/history?activityId=${activityId}`);
              } else {
                navigate(
                  searchParams.size ? `/login/1?${searchParams}` : '/login/1'
                );
              }
            }}
          >
            <span className="text-white/75 text-sm mr-1.5">Histórico</span>
            <RightOutline className="text-white w-4 h-4" />
          </div>
        }
        className="sticky"
      />

      <div className="px-4 pt-6">
        {activityInfo?.type == 'REALTIME' ? (
          <img src={realtimeBanner} className="w-full" />
        ) : (
          <div className="relative">
            <img src={nextDayBanner} className="w-full" />
              <div className="text-[#2F1780] absolute left-[168px] bottom-[7px] text-[11px] leading-[13px] flex items-center text-center">
                <span className="w-[18px] h-[16px] bg-[#FFDF12] py-[2px] rounded-[4px] font-bold">{(Math.max(days * 24 + +hours, 0) + '').padStart(2, '0')}</span>
                <span className="text-white mx-[2px]">:</span>
                <span className="w-[18px] h-[16px] bg-[#FFDF12] py-[2px] rounded-[4px] font-bold">{(Math.max(minutes, 0) + '').padStart(2, '0')}</span>
                <span className="text-white mx-[2px]">:</span>
                <span className="w-[18px] h-[16px] bg-[#FFDF12] py-[2px] rounded-[4px] font-bold">{(Math.max(seconds, 0) + '').padStart(2, '0')}</span>
              </div>
          </div>
        )}
      </div>

      {/* 显示分类列表 */}
      <div className="px-4 py-3 container_bg">
        {rechargeOptions.data?.display && (
          <div
            className="flex items-center mb-6 h-[86px] activity_bg justify-around"
            style={{
              background: `url(${getS3Url(
                'rebate/activity_bg.png'
              )}) no-repeat bottom / 100%`
            }}
          >
            <img src={wallet_bg} className="w-18 h-[76px] mb-1" />
            <div className="self-end w-44">
              <div className="text-xs text-white/75 font-[600]">
                Deposite e participe da atividade
              </div>
              <div className="flex items-center">
                <span className="text-white/75 text-[10px]">
                  Depósito Atual
                </span>
                <span className="text-[#FFDF12] font-[600] text-[15px] mx-1">
                  {rechargeOptions.data?.actualAmout}
                </span>
                <span className="text-white/75 text-[10px]">
                  / {rechargeOptions.data?.rechargeNum}
                </span>
              </div>
            </div>
            <div
              className="flex items-center recharge_btn w-24 h-[29px] justify-center self-center mt-[9px] mr-1"
              onClick={() =>
                homeParams
                  ? navigate(`/deposito?${homeParams}`)
                  : navigate(`/deposito`)
              }
            >
              <span className="text-sm font-bold text-[#2F1780]">Depósito</span>
            </div>
          </div>
        )}

        {rechargeOptions.loading && (
          <Skeleton animated className="h-[86px] mb-6 rounded-lg"></Skeleton>
        )}

        <div className="flex overflow-x-auto">
          {!!gameCategorysOptions.list.length &&
            gameCategorysOptions.list.map(item => (
              <div
                key={item.id}
                onClick={() => setActiveCategory(item)}
                className="flex flex-col items-center w-[20%] flex-none"
              >
                <img
                  src={item.customIcon || item.icon || categoryMap[item.code]}
                  className="w-9 h-9 mb-1"
                />
                <span
                  className={classNames(
                    'text-xs',
                    activeCategory?.id === item.id
                      ? 'text-[#FFDF12]'
                      : 'text-white/75'
                  )}
                >
                  {item.display || item.code || item.name}
                </span>

                <div
                  className={classNames(
                    'h-[2px] w-14 rounded-full mt-1',
                    activeCategory?.id === item.id
                      ? 'bg-[#FFDF12]'
                      : 'bg-transparent'
                  )}
                ></div>
              </div>
            ))}

          {gameCategorysOptions.loading && (
            <div className="flex items-center justify-between w-full">
              {[...Array(skeletonAmount).keys()].map(item => (
                <Skeleton
                  animated
                  key={item}
                  className={'w-12 h-14 rounded-sm'}
                ></Skeleton>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* 显示厅室列表信息 */}
      <div className="px-4 pt-4">
        {!!roomListOptions.list.length &&
          roomListOptions.list.map((item, index) => (
            <div
              key={item.id}
              onClick={() => {
                navigate(`/rebate/detail?activityId=${activityId}`, {
                  state: {
                    gameCategorys: gameCategorysOptions.list,
                    currentCategory: activeCategory,
                    currentRoom: item
                  }
                });
              }}
              className={classNames(
                'rounded-lg p-3',
                index !== 0 ? 'mt-2' : '',
                index % 2 ? 'bg-[#6453FD]/20' : 'bg-[#6453FD]/40'
              )}
            >
              <div className="flex items-center justify-between">
                <div className="flex justify-between items-center">
                  <img
                    src={item.customIcon || item.icon}
                    // className="w-7 h-7 mr-2"
                    className="w-7 h-[38px] mr-2 object-contain"
                  />
                  <span className="text-white font-[500] text-[15px]">
                    {item.display || item.code || item.name}
                  </span>
                </div>
                <div className="flex items-center">
                  <span className="text-white/75 text-xs">
                    Taxa de reembolso
                  </span>
                  <span className="text-white font-[500] text-[15px] ml-1 mr-2">
                    {item.currentRebateRate}%
                  </span>
                  <RightOutline className="text-white w-4 h-4" />
                </div>
              </div>
              <div className="flex justify-between items-center ml-9 mt-1 mb-2">
                <div className="text-white/50 text-xs">
                  {item.lastLevel ? (
                    <>
                      Recebendo a Taxa Máxima de Reembolso
                      <span className="mx-[2px]">{item.nextRebateRate}%</span>
                    </>
                  ) : (
                    <>
                      Aposte
                      <span className="mx-[2px]">{item.continueBet}</span>
                      para receber
                      <span className="mx-[2px]">{item.nextRebateRate}%</span>
                    </>
                  )}
                </div>
                <ProgressBar
                  percent={(item.progress || 0) * 100}
                  className="w-[76px]"
                  style={{
                    '--track-width': '3px',
                    '--fill-color': '#FFDF12',
                    '--track-color': 'rgba(255,255,255,0.3)'
                  }}
                />
              </div>
              <div className="flex justify-between items-center text-sm">
                <div>
                  <span className="text-white/75 mr-1.5">Apostas Válidas</span>
                  <span className="text-white font-[500] text-[15px]">
                    {item.validAmount}
                  </span>
                </div>
                <div>
                  <span className="text-white/75 mr-1.5">Coletável</span>
                  <span
                    className={classNames(
                      'font-[500] text-[15px]',
                      +item.rewardAmount ? 'text-[#0BCF33]' : 'text-white'
                    )}
                  >
                    {item.rewardAmount}
                  </span>
                </div>
              </div>
            </div>
          ))}

        {roomListOptions.loading &&
          [...Array(skeletonAmount).keys()].map((item, index) => (
            <Skeleton
              animated
              key={item}
              className={classNames(
                'h-24 mb rounded-lg',
                index !== 0 ? 'mt-2' : ''
              )}
            ></Skeleton>
          ))}
      </div>

      {/* 显示活动说明信息 */}
      <div className="px-4 pt-5 pb-20">
        <div className="flex items-center h-10 rounded-lg bg-[#6453FD]/70">
          <img src={activity_circle_tip} className="w-6 h-6 mx-3" />
          <span className="text-white text-base font-[600]">
            Descrição da Atividade
          </span>
        </div>
        <div className="py-2 text-white text-sm font-[400]">
          <ul>
            {(activityInfo?.desc || []).map((item, index) => (
              <li key={index} className="break-words py-1">
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* 领取奖励 */}
      <div className="fixed flex justify-between items-center px-4 bottom-0 h-15 bg-[#6453FD] rounded-tl-2xl rounded-tr-2xl w-[393px]">
        <div className="flex">
          <img src={reward_bg} className="w-10 h-10" />
          <div className="flex flex-col justify-between ml-1.5">
            <span className="text-xs text-white/75">Pode ser coletado:</span>
            <span className="text-xl text-[#FFDF12]">
              {availableAmount || formatter.format(0)}
            </span>
          </div>
        </div>
        <div
          onClick={() => canReward && handleReward()}
          className={classNames(
            'w-24 h-9 rounded-xl leading-9 text-center',
            canReward
              ? 'bg-[#FFDF12] text-[#2F1780]'
              : 'text-white/50 border-white/50 border-[1px]'
          )}
        >
          Receber
        </div>
      </div>

      {/* 领取奖励模态框 */}
      <Mask visible={rewardModal.visible} className="backdrop-blur-md">
        <div className="w-[330px] h-[231px] rounded-[10px] p-[2px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 reward_gradient">
          <div className="bg-[#21262C] w-full h-full rounded-[10px] relative flex flex-col items-center justify-around">
            <img
              className="text-white/75 text-sm absolute top-2 right-2 w-7 h-7"
              src={closeIcon}
              onClick={() => {
                rewardModal.close();
                getAvailableAmount();
                getRoomList();
              }}
            />
            <div className="mt-4 -mb-4 text-white text-lg font-bold">
              Recebido com sucesso
            </div>
            <img src={reward_money} className="w-15 h-10" />
            <div className="text-white text-sm -mt-4 -mb-4 px-5">
              Parabéns por ganhar
              <span className="text-[#FFDF12] mx-1">R${availableAmount}</span>
              em fichas de bônus
            </div>
            <div
              className="w-24 h-9 rounded-xl leading-9 text-base text-center reward_btn text-[#422E00] mb-4"
              onClick={() => {
                rewardModal.close();
                getAvailableAmount();
                getRoomList();
              }}
            >
              confirmar
            </div>
          </div>
        </div>
      </Mask>

      {checkPhoneSms.visible && (
        <PhoneBindBox setCheckPhoneSms={checkPhoneSms.close} activity={true} />
      )}
    </div>
  );
};

export default Rebate;
