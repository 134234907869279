import { FC, useEffect, useState } from 'react';
import { Mask } from 'antd-mobile';
import sjyzlb from '@/assets/images/sjyzlb.png';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './pddcontent.less';
import { usePhoneBoxShow } from '@/store';
import { useActivate } from 'react-activation';
import { getUserInfo } from '@/api/phoneSms';
import classNames from 'classnames';

interface PddContentProps {
  setCheckPhoneSms?: () => void;
  activity?: boolean;
}

const PddContent: FC<PddContentProps> = ({ setCheckPhoneSms, activity }) => {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const queryParams = searchParams.toString();
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  const updateShowNum = usePhoneBoxShow(state => state?.updateShowNum);
  const phoneBoxShowNum = usePhoneBoxShow(state => state?.phoneBoxShowNum);
  const [pageBoxStatus, setPageBoxStatus] = useState<any>({});
  const queryConfig = async () => {
    if (userInfo.token) {
      const { code, data: res }: any = await getUserInfo();
      if (code != 200) return

      if (activity) {
        //   活动领取打开弹框  直接打开
        setVisible(true);
      } else {
        //   首页判断
        if (
          !res.smsVerify &&
          res.mobileSecurity &&
          res.loginAction &&
          (res.loginActionType == 'Notice' ? phoneBoxShowNum == 0 : true)
        ) {
          setPageBoxStatus(res);
          setVisible(true);
          updateShowNum();
        }
      }
    }
  };

  const handleNavigateLink = link => {
    setVisible(false);
    navigate(queryParams ? `${link}?${queryParams}` : link);
  };

  useEffect(() => {
    queryConfig();
  }, []);

  useActivate(() => {
    queryConfig();
  });

  return (
    <>
      <Mask visible={visible} className="pdd-share-content">
        <div className="bg-[#2F1780B2] w-[361px] mx-5 pb-6 text-center rounded-lg border-2 border-[#FFDF12]">
          <div className="w-full text-white text-center mt-6 mb-4 text-[18px] font-bold inline-block">
            Central de segurança
          </div>
          <div className="w-full flex justify-center">
            <img className="h-[100px] w-[100px]" src={sjyzlb} alt="" />
          </div>
          <div className=" text-white text-center mt-4 mb-6  px-8  text-[14px] ">
            Caro usuário, para a segurança da sua conta, por favor, vá até o
            centro pessoal e vincule seu número de celular.
          </div>
          <div
            className={classNames(
              'w-full px-4',
              pageBoxStatus?.loginActionType == 'Notice' || activity
                ? 'mt-2'
                : 'mt-8'
            )}
          >
            <div
              className="text-black text-center  w-full h-[46px] leading-[46px] bg-[#FFDF12]  text-[20px]   rounded-lg"
              onClick={() => handleNavigateLink('/bindphone')}
            >
              Verificar agora
            </div>
            {pageBoxStatus?.loginActionType == 'Notice' || activity ? (
              <div
                onClick={() => {
                  setVisible(false);
                  setCheckPhoneSms?.();
                }}
                className="text-[#FFFFFFBF] text-[20px] text-center mt-4 w-full h-[46px] leading-[46px] rounded-lg border-2 border-[#CDC6E1]"
              >
                Verificar mais tarde
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </Mask>
    </>
  );
};

export default PddContent;
