import NavBarComponent from '@/components/NavBar';
import servico from '@/assets/icons/common/servico.png';
import messageOff from '@/assets/icons/common/message_off.png';
import copy from '@/assets/icons/common/copy.png';
import update from '@/assets/icons/common/update.png';
import recharge from '@/assets/icons/userCenter/recharge.png';
import messageOn from '@/assets/icons/common/message_on.png';
import agentCenter from '@/assets/icons/common/agent_center.png';
import accountData from '@/assets/icons/common/account_data.png';
import configuration from '@/assets/icons/common/configuration.png';
import guaguale from '@/assets/images/ggl/ico2.png';
import banner from '@/assets/images/ggl/banner.png';
import saque from '@/assets/icons/userCenter/saque.png';
import CPF from '@/assets/icons/userCenter/CPF.png';
import logout from '@/assets/icons/common/login_out.png';
import warning from '@/assets/icons/common/warning.png';
import * as api from '@/api';
import './index.less';
import { deleteUserInfo, useUserInfo } from '@/store';
import { Grid, Mask, Toast } from 'antd-mobile';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useCommonVisible } from '@/hooks';
import BottomBar from '@/components/BottomBar';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { formatter } from '@/utils/mathWays';
import { copyText } from '@/utils';

import classNames from 'classnames';
import NickNamePopup from '../Profile/NickNamePopup';
import _ from 'lodash';

const _descriptions = [
  {
    label: 'Centro de Agentes',
    value: 'AGENT_REWARD',
    icon: agentCenter,
    isActivity: true
  },
  {
    label: 'Meus relatórios',
    icon: accountData,
    isActivity: false,
    link: '/registro'
  },
  {
    label: 'Configuração',
    icon: configuration,
    isActivity: false,
    link: '/profile'
  },
  {
    label: 'Raspadinha',
    icon: guaguale,
    isActivity: false,
    link: '/guaguale',
    count: 0
  },
  {
    label: 'Mensagem',
    icon: messageOn,
    isActivity: false,
    link: '/mensagem',
    count: 0
  }
];
const UserCenter = () => {
  const userInfo = useUserInfo();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [userDetail, setUserDetail] = useState<any>(null);
  const logoutModal = useCommonVisible();
  const [searchParams] = useSearchParams();
  const activityList = location.state?.activityList || [];
  const homeParams = localStorage.getItem('homeParams') || '';
  const [descriptions, setDescriptions] = useState(_descriptions);
  const nickNameModal = useCommonVisible();
  const [messageCount, setMessageCount] = useState(0);
  const [totalMoney, setTotalMoney] = useState(0);

  useEffect(() => {
    getUserDetail();
    setTimeout(() => {
      getUserCount();
      queryMoney();
      getGglCount();
    }, 500);
  }, []);

  const updateDescriptionsCount = (index: number, count: number = 0) => {
    descriptions[index].count = count;
    setDescriptions([
      ...descriptions,
    ]);
  }

  const getGglCount = async () => {
    const {code, data = 0} = await api.getGglScratched();
    if (code === 200) {
      updateDescriptionsCount(3, data);
    }
  }

  const getUserCount = async () => {
    const result = await api.getUserCount();
    if (result.code === 200 && result.data) {
      setMessageCount(result.data);
      updateDescriptionsCount(4, result.data);
    }
  };

  const getUserDetail = async () => {
    const result = await api.getUserDetail();
    if (result.code === 200 && result.data) {
      setUserDetail(result.data);
    }
  };

  const queryMoney = async () => {
    const res = await api.getMoney();
    if (res.code === 200) {
      setTotalMoney(res.data?.amount || 0);
    } else {
      setTotalMoney(0);
    }
  };

  const jumpAgent = (link, activityId) => {
    if (activityId) {
      searchParams.set('activityId', activityId);
    }
    navigate(`${link}?${searchParams}`);
  };

  const handleLogout = async () => {
    const result = await api.logout();
    if (result.code === 200 && result.data) {
      Toast.show({
        icon: 'success',
        content: t('successMsg')
      });
      logoutModal.close();
      deleteUserInfo();
      localStorage.removeItem('channelType');
      localStorage.removeItem('channelPixelId');
      homeParams ? navigate(`/home?${homeParams}`) : navigate(`/home`);
    }
  };

  return (
    <div className="w-full h-[calc(100vh-var(--vh-offset,0px))] userCenter overflow-y-scroll">
      {/* <NavBarComponent
        className="sticky"
        left={
          <span className="text-white text-lg font-[500]">Centro Pessoal</span>
        }
      /> */}

      <div className="mx-5 mt-5">
        <div className="flex justify-between mb-4">
          <div className="w-[75px] h-[75px] user_bg">
            <img
              src={`/images${userInfo.avatar}`}
              className="w-full h-full object-cover"
            />
          </div>
          <div className="text-white text-[15px] font-[600] mx-4 flex flex-grow">
            <div className="flex flex-col justify-center flex-grow">
              <div
                className="flex items-center mb-2"
                onClick={nickNameModal.open}
              >
                <span className="truncate">{userInfo.nickname}</span>
                <img src={update} alt="" className="w-6 h-6 ml-1" />
              </div>
              <div
                className="flex items-center"
                onClick={() => copyText(userInfo.userId)}
              >
                <span>ID: {userInfo.userId}</span>
                <img src={copy} alt="" className="w-6 h-6 ml-1" />
              </div>
            </div>

            <div className="flex">
              <img
                src={servico}
                className="w-8 h-8 mr-3"
                onClick={() => navigate('/servico')}
              />
              <div className="relative">
                <img
                  src={messageOff}
                  className="w-8 h-8"
                  onClick={() => navigate('/mensagem')}
                />
                {!!messageCount && (
                  <div className="w-2 h-2 rounded-full bg-[#FF3B30] absolute top-0 right-0" />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-between px-2">
          <div
            className="flex items-center"
            onClick={() => navigate('/deposito')}
          >
            <img src={recharge} alt="" className="w-[30px] h-[33px]" />
            <span className="text-[13px] text-white font-[600] ml-2">
              Depósito
            </span>
          </div>
          <div
            className="flex items-center"
            onClick={() => navigate('/chooseSaque')}
          >
            <img src={saque} alt="" className="w-[30px] h-[33px]" />
            <span className="text-[13px] text-white font-[600] ml-2">
              Saque
            </span>
          </div>
          <div className="flex items-center" onClick={() => navigate('/CPF')}>
            <img src={CPF} alt="" className="w-[30px] h-[33px]" />
            <span className="text-[13px] text-white font-[600] ml-2">CPF</span>
          </div>
        </div>
      </div>

      {/* <div className="px-4">
        <img src={banner} alt="" className="w-full h-[95px] mt-4" onClick={() => navigate('/guaguale')} />
      </div> */}

      <div className="px-5 pt-5 pb-20 userContainer relative z-5">
        <div className="flex justify-around userBalance p-3 rounded-xl h-30 box-border overflow-hidden">
          <div className="flex flex-col items-center">
            <span className="text-lg font-[600] text-white">
              {formatter.format(totalMoney || 0)}
            </span>
            <span className="text-xs text-white/75 mt-1">Saldo</span>
          </div>
          <div className="flex flex-col items-center">
            <span className="text-lg font-[600] text-white">
              {formatter.format(userDetail?.singleRedeemAmount || 0)}
            </span>
            <span className="text-xs text-white/75 mt-1">
              Bônus da Atividade
            </span>
          </div>
          <div className="flex flex-col items-center">
            <span className="text-lg font-[600] text-white">
              {formatter.format(userDetail?.withdrawalFee || 0)}
            </span>
            <span className="text-xs text-white/75 mt-1">Bônus do Agente</span>
          </div>
        </div>

        <Grid columns={5} gap={[0, 9]}>
          {activityList.map((item, index) => (
            <Grid.Item
              key={index}
              className="relative"
              onClick={() => {
                jumpAgent(item.link, item.id);
              }}
            >
              <div className="flex items-center justify-center flex-col">
                <img
                  src={item.menuLogo}
                  alt={item.activityType}
                  className="w-8 h-8 object-contain"
                />
                <span className="text-xs w-4/5 text-center text-white my-1 truncate break-all">
                  {item.logoName}
                </span>
              </div>
              {item.showRedPoint && (
                <i className="w-2 h-2 rounded-full bg-[#FF3B30] absolute top-0 right-1.5" />
              )}
            </Grid.Item>
          ))}
        </Grid>

        <div className="my-5 rounded-lg overflow-hidden">
          {descriptions.map((item, index) => (
            <div
              key={item.label}
              className={classNames(
                'flex items-center  h-11 pl-4 mb-[2px]',
                index % 2 ? 'bg-[#6453FD]/20' : 'bg-[#6453FD]/40'
              )}
              onClick={() => {
                if (item.isActivity) {
                  const currentActivity = activityList.find(
                    cItem => cItem.activityType === item.value
                  );
                  jumpAgent(currentActivity.link, currentActivity.id);
                } else {
                  navigate(item.link!);
                }
              }}
            >
              <img src={item.icon} alt="" className="w-6 h-6 mr-3" />
              <span className="text-[15px] text-white">{item.label}</span>
              {!!item.count && (
                <span className="text-xs text-black px-3 py-1 bg-[#FFDF12] rounded-full ml-auto mr-5">
                  {item.count}
                </span>
              )}
            </div>
          ))}
        </div>

        <div
          className="flex items-center justify-center bg-black/20 h-11 pl-4 rounded-lg"
          onClick={logoutModal.open}
        >
          <img src={logout} alt="" className="w-6 h-6 mr-2" />
          <span className="text-[15px] text-white">Sair</span>
        </div>
        {/* 退出登录模态框 */}
        <Mask visible={logoutModal.visible} className="backdrop-blur-md">
          <div className="absolute m-auto left-4 right-4 bg-[#2F1780]/70 border-[2px] border-[#FFDF12] p-6 rounded-[10px] flex flex-col items-center top-[50%] -translate-y-1/2">
            <div className="text-lg text-white font-[600]">
              Sair da conta atual
            </div>
            <img src={warning} className="w-15 h-15 object-contain my-4" />
            <p className="text-white text-sm font-[500] mb-4 text-center">
              Por favor, lembre-se de sua conta de login e senha para fazer
              login novamente.
            </p>

            <div className="flex justify-between w-full">
              <div
                onClick={logoutModal.close}
                className="w-[47%] text-center text-white text-lg font-[600] rounded-lg border-[1.5px] border-white h-[46px] leading-[46px]"
              >
                Cancelar
              </div>
              <div
                onClick={_.debounce(handleLogout, 250)}
                className="w-[47%] text-center text-black text-lg font-[600] bg-[#FFDF12] rounded-lg h-[46px] leading-[46px]"
              >
                Sair
              </div>
            </div>
          </div>
        </Mask>
      </div>

      <BottomBar />
      {/* 编辑昵称弹出层 */}
      <NickNamePopup nickNameModal={nickNameModal} />
    </div>
  );
};

export default UserCenter;
