import { useEffect, useMemo, useState, useCallback } from 'react';
import { getS3Url } from '@/utils';
import NavBarComponent from '@/components/NavBar';
import icon1 from '@/assets/images/ggl/icon_1.png';
import icon2 from '@/assets/images/ggl/icon_2.png';
import i1 from '@/assets/images/ggl/i1.png';
import i2 from '@/assets/images/ggl/i2.png';
import i3 from '@/assets/images/ggl/i3.png';
import CountDown from '@/components/CountDown';
import Tips from '@/components/ActivityComponent/Tips';
import { useNavigate } from 'react-router-dom';
import { getRevelUrl, getGglInfo } from '@/api';
import { formatter } from '@/utils/mathWays.ts';
import classNames from 'classnames';
import dayjs from 'dayjs';
import qs from 'qs';
import './index.less';

const getGameData = async () => {
  const {code, data} = await getRevelUrl();
  if (code === 200) {
    return data
  }
  return null
}

const getActivityInfo = async (setActivityInfo) => {
  const {code, data} = await getGglInfo();
  if (code === 200 && data) {
    console.log(data);
    setActivityInfo(data);
  }
}

const Guaguale = () => {
  const navigate = useNavigate();

  const [activityInfo, setActivityInfo] = useState<any>({});

  const gotoGame = async () => {
    if (!activityInfo.butRevel) return;

    const data = await getGameData();
    if (data) {
      data.urlInfo = location.origin + data.urlInfo
      data.urlRd = location.origin + data.urlRd
      location.href = '/ggl/index.html?' + qs.stringify(data);
    } else {
      // 如果data为空，则重新获取活动信息
      await getActivityInfo(setActivityInfo)
    }
  };

  useEffect(() => {
    getActivityInfo(setActivityInfo);
  }, []);

  const checkList = useMemo(() => {
    const t1 = activityInfo.rechargeCtTime && (dayjs.utc(+activityInfo.rechargeCtTime).local().valueOf() - Date.now()) || 0

    // console.log('utc:',
    //   dayjs(+activityInfo.rechargeCtTime).format('YYYY-MM-DD HH:mm:ss') == dayjs.utc(+activityInfo.rechargeCtTime).format('YYYY-MM-DD HH:mm:ss'),
    //   dayjs(+activityInfo.rechargeCtTime).format('YYYY-MM-DD HH:mm:ss'), // 5:30
    //   dayjs.utc(+activityInfo.rechargeCtTime).format('YYYY-MM-DD HH:mm:ss') // 0:00
    // )

    // console.log('utc:', dayjs(+activityInfo.rechargeCtTime).format('YYYY-MM-DD HH:mm:ss'), dayjs(+activityInfo.rechargeCtTime).valueOf())
    // console.log('utc + utc:', dayjs.utc(+activityInfo.rechargeCtTime).format('YYYY-MM-DD HH:mm:ss'), dayjs.utc(+activityInfo.rechargeCtTime).valueOf())
    // console.log('local:', dayjs(+activityInfo.rechargeCtTime).local().format('YYYY-MM-DD HH:mm:ss'), dayjs(+activityInfo.rechargeCtTime).local().valueOf())
    // console.log('local + utc:', dayjs.utc(+activityInfo.rechargeCtTime).local().format('YYYY-MM-DD HH:mm:ss'), dayjs.utc(+activityInfo.rechargeCtTime).local().valueOf())

    const t2 = activityInfo.betCtTime && (dayjs.utc(+activityInfo.betCtTime).local().valueOf() - Date.now()) || 0

    return [
      {
        fullfilled: activityInfo.rechargeAmtHas || false,
        target: activityInfo.rechargeAmt || 0,
        time: t1,
        date: t1 > 24 * 60 * 60 * 1000 ? dayjs.utc(+activityInfo.rechargeCtTime).local().format('YYYY-MM-DD') : '',
        link: '/deposito',
        icon: i1,
        linkName: 'Depósito>',
        tip: 'Valor de Depósito Diário≥'
      }, {
        fullfilled: activityInfo.betAmtHas || false,
        target: activityInfo.betAmt || 0,
        time: t2,
        date: t2 > 24 * 60 * 60 * 1000 ? dayjs.utc(+activityInfo.betCtTime).local().format('YYYY-MM-DD') : '',
        link: '/home',
        icon: i2,
        linkName: 'Aposta>',
        tip: 'Valor de Aposta Diário≥'
      }, {
        fullfilled: activityInfo.smsHas || false,
        link: '/profile',
        state: {
          verify: true
        },
        icon: i3,
        linkName: 'Verificação>',
      }
    ]
  }, [activityInfo]);

  return (
    <div className='ggl min-h-[100vh] bg-no-repeat bg-[length:100%_auto] text-white pt-[68px] bg-[#351e8f]'>
      <NavBarComponent
        left={
          <span className="text-white text-lg font-[500]">
            Raspadinhas
          </span>
        }
        className="!absolute"
      />

      <div className="px-4">
        <div className='w-[171px] h-[46px] bg-[url(@/assets/images/ggl/bn_ggl.png)] bg-no-repeat bg-contain bg-cover text-[11px] flex'>
          <div className='w-[42px] flex items-center justify-center'>
            <img src={icon2} className='w-[24px] h-[24px] ml-1.5' alt="" />
          </div>
          <div className='flex flex-col pt-1'>
            <span className='text-[11px] text-[#FFFFFFBF] leading-[16px]'>
              Ganhos Acumulados
            </span>
            <p className='leading-[21px] font-[600]' onClick={() => {
                navigate('/guaguale/history', {
                  state: {
                    totalAmount: formatter.format(activityInfo.totalAmount || 0),
                  }
                })}
              }>
              <span className='text-[11px] mr-1'>{activityInfo.symbol}</span>
              <span className='text-[15px]'>
                {formatter.format(activityInfo.totalAmount || 0)}
                <span className='inline-block border border-2 border-white w-1.5 h-1.5 rotate-45 border-b-0 border-l-0 -translate-y-0.5 translate-x-0.5'></span>
              </span>
            </p>
          </div>
        </div>

        <div className='mt-[377px] flex justify-center items-center text-[#FFDF12]'>
          <img src={icon1} className='w-[32px] h-[32px]' alt="" />
          <span className='text-[24px] font-[600] block ml-2 mr-1'>x</span>
          <span className='text-[24px] font-[600]'>{activityInfo.totalNumbs || 0}</span>
        </div>

        <div
        className={classNames('mainBn text-[11px] w-[192px] h-[107px] mx-auto mt-[20px] flex flex-col items-center justify-end', {
          'off': !activityInfo.butRevel
        })}
        onClick={gotoGame}
        >
        {
          activityInfo.scratchesCtTime && (
            (+activityInfo.scratchesCtTime - Date.now()) > 24 * 60 * 60 * 1000 ? (
              <div className="pl-[15px] date">
                <span>{dayjs(+activityInfo.scratchesCtTime).local().format('YYYY-MM-DD')}</span>
              </div>
            ) : (
              <CountDown targetTime={+activityInfo.scratchesCtTime - Date.now()}>
                {({ hours, minutes, seconds }) => (
                  <div className="pl-[15px]">
                    <span>{hours.toString().padStart(2, '0')}</span>:<span>{minutes.toString().padStart(2, '0')}</span>:<span>{seconds.toString().padStart(2, '0')}</span>
                  </div>
                )}
              </CountDown>
          ))
        }
          <div className="btn">
            Restantes: <span className='text-[15px] font-[600]'>{activityInfo.scratchesRemainNum || 0}</span>/{activityInfo.scratchesTotalNum || 0}
          </div>
        </div>

        <div className='mt-4'>
        {
          checkList.map((item, index)=> (
          <div key={index} className={classNames('bg-[#9508B440] bg-no-repeat bg-[95%_40%] bg-[length:24px] rounded-[8px] px-4 py-2 mb-[2px] relative', {
            'bg-[url(@/assets/images/ggl/checked.png)]': item.fullfilled,
          })}>

            <div className='flex items-end mb-1'>
              <img src={item.icon} className='w-[24px] h-[24px] mr-2' alt="" />
              {
                index < 2
                  ? <p>{item.tip}{item.target}</p>
                  : <p>Verificação de Celular</p>
              }
            </div>

            {
              index < 2 && item.time ? (
                <div className='flex'>
                  Redefinir
                  {
                    (item.date ? (
                      <div className="pl-1 text-[#0BCF33]">
                        <span>{item.date}</span>
                      </div>
                    ) : (
                      <CountDown targetTime={item.time}>
                      {({ hours, minutes, seconds }) => (
                        <div className="pl-1 text-[#0BCF33]">
                          <span>{hours.toString().padStart(2, '0')}</span>:<span>{minutes.toString().padStart(2, '0')}</span>:<span>{seconds.toString().padStart(2, '0')}</span>
                        </div>
                      )}
                      </CountDown>
                    ))
                  }
                  </div>
              ) : null
            }

            {
              !item.fullfilled && (
                <p
                className='absolute top-3 right-4 text-[#FFDF12] text-[11px]'
                onClick={() => navigate(item.link, {
                  state: item.state
                })}
                > {item.linkName} </p>
              )
            }
          </div>
          ))
        }
        </div>

        <Tips
          tipTitle="Descrição da Atividade"
          tipList={activityInfo.actDesc?.split('\n') || []}
          tipIcon={getS3Url('home/i_36.png')}
          tipClassName="text-[13px] leading-[20px]"
        />

      </div>

      {/* <div
            className="btn text-white border border-white rounded-full px-4 py-2"
          onClick={() => {
            if (url) window.location.href = url;
          }}
          >
          REVEL
        </div> */}

    </div>
  );
};

export default Guaguale;
