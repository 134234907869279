import { useActiveConfigList } from '@/store';
import { gotoActivity } from '@/utils';

const ActiviyList = () => {
  const allConfigList = useActiveConfigList(state => state?.allConfigList);

  return (
    <div className="activityList">
      {allConfigList?.map(item => (
        <div
          className="activity relative"
          key={item.id}
          onClick={() => { gotoActivity(item.link, item.id) }}
        >
          <img src={item.menuLogo} alt="" />
          {item.showRedPoint && (
            <i className="w-2.5 h-2.5 rounded-full bg-[#EE0A24] absolute top-0 right-5" />
          )}
          <p className="truncate">
            {item.logoName}
          </p>
        </div>
      ))}
    </div>
  );
};

export default ActiviyList;
