import { useEffect, useMemo, useRef, useState } from 'react';
import { getGameUrl, isLogin } from '@/utils';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import * as api from '@/api';
import { Image, Toast } from 'antd-mobile';
import classNames from 'classnames';
import { debounce } from 'lodash';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import { categoryMap } from '../Home/Main.tsx';
import { useCommonVisible } from '@/hooks/index.tsx';
import { ToLoginOrRegister } from '@/components/popup/ToLoginOrRegister.tsx';
import SEARCH from '@/assets/icons/game/search.png';
import CLOSE from '@/assets/icons/common/close.png';
import Game_Collect from '@/assets/icons/game/collect.png';
import Game_UnCollect from '@/assets/icons/game/uncollect.png';
import './index.less';
import NavBarComponent from '@/components/NavBar/index.tsx';
import NoData from '@/components/NoData/index.tsx';

const GamePageSize = 30;

function GameCategory() {
  const language = localStorage.getItem('language');
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const {
    deferGameName: _deferGameName,
    pageOptions: _pageOptions,
    currentSortType: _currentSortType
  } = location.state?.categoryStates || {};

  const navigate = useNavigate();
  const getUserInfo = () => {
    const userInfo = localStorage.getItem('userInfo');
    return userInfo ? JSON.parse(userInfo) : null;
  };
  const [gameList, setGameList] = useState<any[]>([]);
  const [currentRoomIndex, setCurrentRoomIndex] = useState(0);
  const [currentSortType, setCurrentSortType] = useState(
    _currentSortType || -1
  );

  const [room, setRoom] = useState({
    code: ''
  }) as any;

  const gp = searchParams.get('gp') || '';
  const [gameCollects, setGameCollects] = useState<any[]>([]);

  const currentRoom = location.state?.currentRoom;
  const [gameName, setGameName] = useState(_deferGameName || '');
  const [deferGameName, setDeferGameName] = useState(_deferGameName || '');
  const [currentCategory, setCurrentCategory] = useState<any>(
    location.state?.currentCategory
  );
  const icon = location.state?.icon;
  const isCustom = location.state?.isCustom || currentCategory.type == 'Hot';
  const customDisplay = location.state?.customDisplay;

  // const isSystemCat = [
  //   'slots',
  //   'mini',
  //   'bingo',
  //   'fish',
  //   'poker',
  //   'live',
  //   'arcade'
  // ].some(item => item.toUpperCase() == currentCategory.code)

  const [gameNameCompose, setGameNameCompose] = useState(false);
  const searchRef = useRef() as any;
  const [total, setTotal] = useState(10);
  const loginPopup = useCommonVisible();
  const homeParams = localStorage.getItem('homeParams') || '';

  // fetchId记录请求Id防止切换厅室时 页面渲染紊乱
  const fetchId = useRef('') as any;
  const [pageOptions, setPageOptions] = useState(
    _pageOptions || {
      pageIndex: 1,
      pageSize: GamePageSize
    }
  );

  useEffect(() => {
    getGameCategorys();
  }, []);

  useEffect(() => {
    if (currentSortType === -1) return;
    getGameListByCategory({
      category: currentCategory?.id,
      gameName: deferGameName,
      ...pageOptions,
      sortType: currentSortType,
      ...([3, 4].includes(currentSortType) && {
        userId: getUserInfo() ? getUserInfo().userId : ''
      }),
      ...(room.code && currentRoomIndex !== 0 && { room: room.id })
    });
  }, [currentCategory, room, pageOptions, currentSortType, deferGameName]);

  useEffect(() => {
    isLogin() && gameCollectList();
  }, []);

  const gameCollectList = async () => {
    const result = await api.gameCollectList();
    if (result.code === 200) {
      setGameCollects(result.data);
    }
  };
  const getGameCategorys = async () => {
    const result = await api.getGameCategorys();
    if (result.code === 200) {
      const list = result.data.filter(item => item.onShow);
      const category = location.state?.currentCategory;
      const stateCategory = list.find(
        li => String(li.id) === String(category.id)
      );
      // 无分类或无厅室或者非HOT分类就要跳转至首页
      if (
        !stateCategory ||
        !stateCategory?.roomList ||
        (stateCategory?.code !== 'HOT' && stateCategory?.roomList.length === 0)
      ) {
        homeParams ? navigate(`/home?${homeParams}`) : navigate(`/home`);
      }
      setCurrentCategory(stateCategory);
    }
  };

  const jumpGame = item => {
    if (isLogin()) {
      const _window = window as any;
      if (_window.trackFn && _window.trackName) {
        console.log('判断是否有值StartTrial');
        _window.trackFn(_window.trackName['StartTrial']);
      }
      _window.isFromHome = true;
      localStorage.setItem('currentCategory', JSON.stringify(currentCategory));
      localStorage.setItem('currentRoom', JSON.stringify(room));
      localStorage.setItem(
        'categoryStates',
        JSON.stringify({
          deferGameName,
          pageOptions,
          currentSortType
        })
      );
      navigate(
        `/game?gId=${item.gameId}&language=${language}${gp ? `&gp=${gp}` : ''}`
      );
    } else {
      loginPopup.open();
    }
  };

  const getGameListByCategory = async params => {
    Toast.show({
      icon: 'loading'
    });
    try {
      const currentFetchId = Symbol();
      fetchId.current = currentFetchId;
      const result = await api.getGameListByCategory(params);
      // console.log("🚀 ~ getGameListByCategory ~ result:", result)
      if (result.code === 200 && result.data) {
        if (currentFetchId === fetchId.current) {
          setGameList(result.data.records);
          setTotal(result.data.total);
        }
      }
    } finally {
      Toast.clear();
    }
  };

  const sortTypeFilters = useMemo(() => {
    const buttons = [
      {
        en_label: 'hot',
        pt_label: 'Popular',
        value: 2
      },
      {
        en_label: 'recently',
        pt_label: 'Recente',
        value: 3
      },
      {
        en_label: 'favour',
        pt_label: 'Favoritos',
        value: 4
      }
    ];
    if (currentRoomIndex === 0) {
      return buttons;
    } else {
      return [
        {
          en_label: 'all',
          pt_label: 'All',
          value: 1
        },
        ...buttons
      ];
    }
  }, [currentRoomIndex]);

  useEffect(() => {
    if (!_currentSortType) {
      setCurrentSortType(sortTypeFilters[0].value);
    }
  }, [currentRoomIndex]);

  const roomList: any = useMemo(() => {
    const _roomList = [...currentCategory.roomList];
    _roomList.unshift({
      code: currentCategory.code,
      id: currentCategory.id,
      name: currentCategory.name,
      customIcon: currentCategory.customIcon,
      icon: currentCategory?.icon || categoryMap[currentCategory?.code],
      onShow: true
    });
    return _roomList;
  }, [currentCategory?.roomList]);

  useEffect(() => {
    // 只有从home页面跳转的自定义分类才会进入该条件  从game页面返回到当前页面不能进入该条件
    if (roomList.length && currentRoom && currentRoom.categoryId) {
      const currentIndex = roomList?.findLastIndex(
        item => String(item.id) === String(currentRoom.id)
      );
      setCurrentRoomIndex(currentIndex);
      setRoom(currentRoom);
    }
  }, [roomList, currentRoom]);

  const debouncedChangeHandler = useMemo(
    () =>
      debounce(value => {
        setDeferGameName(value);
        setPageOptions({
          pageIndex: 1,
          pageSize: GamePageSize
        });
      }, 500),
    []
  );

  const handleChange = e => {
    //组合输入法下 只修改输入框的输入 不调接搜索口  在输入完成后调用搜索接口
    if (gameNameCompose) {
      setGameName(e.target.value);
    } else {
      setGameName(e.target.value);
      debouncedChangeHandler(e.target.value);
    }
  };

  const changeCollect = async item => {
    if (isLogin()) {
      const _isGameCollect = isGameCollect(gameCollects, item.gameId);
      const request = _isGameCollect
        ? api.deleteGameCollect
        : api.addGameCollect;
      const params = new FormData();
      params.append('gameId', item.gameId);
      Toast.show({
        icon: 'loading'
      });

      try {
        const result = await request(params);
        if (result.code === 200) {
          if (_isGameCollect) {
            setGameCollects(
              gameCollects.filter(cItem => cItem !== item.gameId)
            );
          } else {
            setGameCollects([...gameCollects, item.gameId]);
          }
        }
      } finally {
        Toast.clear();
      }
    } else {
      loginPopup.open();
    }
  };

  const isGameCollect = (gameCollects, id) => {
    return gameCollects.includes(id);
  };

  return (
    <div className="w-[100vw] h-[calc(100vh-var(--vh-offset,0px))] overflow-hidden relative">
      <NavBarComponent
        className="sticky"
        left={
          <span className="text-white text-lg font-[500]">
            {(isCustom && customDisplay) ||
              currentCategory.display ||
              currentCategory.name}
          </span>
        }
      />

      <div className="mx-4 mt-4 h-10 flex rounded-[20px] overflow-hidden border-transparent border-[1px] bg-[#6543FD]/20 focus-within:border-[#6453FD]">
        <input
          type="text"
          value={gameName}
          ref={searchRef}
          className="w-[90%] bg-transparent outline-0 text-white indent-4"
          onChange={handleChange}
          onCompositionStart={() => setGameNameCompose(true)}
          onCompositionEnd={e => {
            setGameNameCompose(false);
            setDeferGameName((e.target as HTMLInputElement).value);
          }}
        />
        <div className="flex-1 flex items-center mr-2">
          {gameName && (
            <div
              className="w-4 h-4 mr-2"
              onClick={() => {
                searchRef.current.focus(); //此处待优化  不应手动聚焦
                setGameName('');
                setDeferGameName('');
              }}
            >
              <img src={CLOSE} className="text-[#ccc]" />
            </div>
          )}
          <div className="w-5 h-5" onClick={() => searchRef.current.focus()}>
            <img src={SEARCH} alt="" className="w-full h-full" />
          </div>
        </div>
      </div>

      <div className="absolute top-30 left-4 right-4 flex z-10 h-[calc(100%-8rem)]">
        {!isCustom && (
          <div className="w-15 flex flex-col overflow-y-auto mb-20 overflow-x-hidden">
            {roomList
              .filter(item => item.onShow)
              .map((item, index) => (
                <div
                  key={`${item.id}-${item.code}`}
                  onClick={debounce(() => {
                    if (currentRoomIndex === index) return;
                    setCurrentRoomIndex(index);
                    if (index === 0) {
                      setCurrentSortType(2);
                    } else {
                      setCurrentSortType(1);
                    }
                    setRoom(item);
                    setPageOptions({
                      pageIndex: 1,
                      pageSize: GamePageSize
                    });
                    setGameList([]);
                  }, 500)}
                  className={classNames(
                    'w-full flex flex-col justify-center items-center h-14 rounded text-[12px] flex-shrink-0',
                    index === 0 ? '' : 'mt-2.5',
                    currentRoomIndex === index
                      ? 'text-white bg-[#6453FD]'
                      : 'text-white/75 bg-[#6543FD]/20'
                  )}
                >
                  {(item.customIcon || item.icon) && (
                    <img
                      src={item.customIcon || item.icon}
                      className="w-7"
                      alt=""
                    />
                  )}
                  <span className="text-xs mt-1 whitespace-nowrap overflow-x-hidden text-clip">
                    {item.display || item.code || item.name}
                  </span>
                </div>
              ))}
          </div>
        )}

        <div
          className={classNames('w-[calc(100%-67px)] relative', {
            'w-full': isCustom
          })}
        >
          <div className="flex overflow-x-auto items-center">
            {isCustom && icon && (
              <img src={icon} className="w-[36px] h-[36px] rounded-sm mr-[12px] ml-1" alt="" />
            )}

            {sortTypeFilters?.map(item => (
              <div
                key={item.value}
                onClick={debounce(() => {
                  if (currentSortType === item.value) return;
                  setCurrentSortType(item.value);
                  setPageOptions({
                    pageIndex: 1,
                    pageSize: GamePageSize
                  });
                  setGameList([]);
                }, 500)}
                className={classNames(
                  'px-3 py-1 mx-1 rounded-md border border-white/10 text-xs',
                  currentSortType === item.value
                    ? 'bg-[#EEE54C] text-[#2F1780]'
                    : 'text-white/60 border-white/75'
                )}
              >
                {item[`${language}_label`]}
              </div>
            ))}
          </div>
          <div className="overflow-y-auto overflow-x-hidden flex justify-center w-full absolute top-7 bottom-15 mt-1.5">
            <div className="flex flex-wrap justify-start content-start w-full">
              {gameList.length ? (
                gameList.map(item => (
                  <div className="inner-game-content" key={item.gameId}>
                    <div className="absolute inset-0 h-full border-[6px] border-transparent">
                      <div
                        className="absolute right-[2px] top-[2px]"
                        onClick={() => changeCollect(item)}
                      >
                        {isGameCollect(gameCollects, item.gameId) ? (
                          <img src={Game_Collect} className="w-4 h-4" />
                        ) : (
                          <img src={Game_UnCollect} className="w-4 h-4" />
                        )}
                      </div>
                      <Image
                        // lazy
                        onClick={() => jumpGame(item)}
                        src={getGameUrl(item, language)}
                        className="w-full h-full"
                        style={{ borderRadius: 8 }}
                        placeholder={<img src="/images/game_placeholder.png" />}
                        fallback={
                          <>
                            <Image
                              src={`/images/default_img.png`}
                              className="w-full h-full"
                              fit="cover"
                              placeholder={
                                <img src="/images/game_placeholder.png" />
                              }
                              onClick={() => jumpGame(item)}
                            />
                            <span className="!whitespace-break-spaces">
                              {item.display}
                            </span>
                          </>
                        }
                      />
                      {item.display && (
                        <span className="!whitespace-break-spaces absolute bottom-1 text-xs w-full text-center text-white">
                          {item.display}
                        </span>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <div className="w-full h-full flex justify-center">
                  <NoData />
                </div>
              )}
            </div>
          </div>
          <div className="h-8 absolute bottom-4 w-full flex justify-center">
            <Pagination
              onChange={(pageIndex, pageSize) =>
                setPageOptions({
                  pageIndex,
                  pageSize
                })
              }
              current={pageOptions.pageIndex}
              total={total}
              pageSize={pageOptions.pageSize}
              className="custom-pagination"
              showLessItems
            />
          </div>
        </div>
      </div>

      <ToLoginOrRegister
        isOpen={loginPopup.visible}
        setIsOpen={loginPopup.close}
      />
    </div>
  );
}

export default GameCategory;
