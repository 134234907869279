// @ts-nocheck
import UAParser from 'ua-parser-js';

export const params = new URLSearchParams(location.search);

const ua = navigator.userAgent;
export const isAndroid = ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1;
export const isIOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
export const isWindows = !!ua.match(/Windows/i);
export const isMacOS = !!ua.match(/Macintosh/i);

// 检测是否在pwa应用中
// 可能因和h5共享内存产生误报
export const isInPWA = () => {
	return ['fullscreen', 'standalone', 'minimal-ui'].some(
		mode => window.matchMedia(`(display-mode: ${mode})`).matches
	) && !isIOS
}
// 检测是否添加到iOS桌面
// 可能因和h5共享内存产生误报
export const isFromIOSScreen = () => isIOS && window.navigator.standalone;
// 检测是否是facebook浏览器
const isFacebookApp = () => {
  const parser = new UAParser(navigator.userAgent);
  const browserName = parser.getBrowser().name;

  // 检查浏览器名称是否包含Facebook相关标识
  return /FBAV|FBAN|FBIOS|FB|FACEBOOK/i.test(browserName || '');
};

// 检测是否在浏览器app中 // 不一定可靠
const isInBrowserApp = /chrome|safari|firefox|opera|edge/i.test(ua);

export const isWebView = /wv|WebView/i.test(navigator.userAgent);
export const hasAndroidBridge = typeof window.jsBridge !== 'undefined';

// 检测是否是在ios app且不在facebook浏览器且不在浏览器app中
export const isInIosApp = isIOS && (!!window.webkit && !!window.webkit.messageHandlers) && !isFacebookApp() && !isInBrowserApp;
export const isInAndroidApp = isAndroid && (
  params.get('platform') == 'android' ||
  localStorage.platform == 'android' ||
  (isWebView && hasAndroidBridge)
) // android webview
export const isInApp = isInIosApp || isInAndroidApp;

// 跟mike约好，打包app的ua中必须包含MobileUserAgent, 如 MobileUserAgent/1.0
export const isInAppByUa = /\s+MobileUserAgent\/\d+/.test(ua);

export const isInBrowser = !isInApp && !isInPWA() && !isFromIOSScreen() && !isInAppByUa;

export const PWA_Installed = '__yz_pwa_installed__';

// 检测是否已安装pwa
export const isInstallPWA = !!localStorage.getItem(PWA_Installed);

function initUrlParams () {
	if (params.get('ch')) {
		localStorage.setItem('ch', params.get('ch') || '');
	}
	if (params.get('type')) {
		localStorage.setItem('type', params.get('type') || '');
	}
	if (params.get('pixelId')) {
		localStorage.setItem('pixelId', params.get('pixelId') || '');
	}
	if (params.get('platform')) {
		localStorage.setItem('platform', params.get('platform') || '');
	}
}

initUrlParams()

export const ch = params.get('ch') || localStorage.getItem('ch') || '';
export const type = params.get('type') || localStorage.getItem('type') || '';
export const platform = params.get('platform') || localStorage.getItem('platform') || '';
export const pixelId = params.get('pixelId') || localStorage.getItem('pixelId') || '';

// export const language = params.get('language') || 'pt';
// export const themeStr = params.get('theme') || 'theme1';
