import { isValidElement, ReactNode } from 'react';

interface IInputFormWrapperProps {
  label?: ReactNode;
  errorMsg?: ReactNode;
  addonBefore?: ReactNode;
  addonAfter?: ReactNode;
  children: ReactNode;
}
export const InputFormWrapper = ({
  label = '',
  errorMsg = '',
  addonBefore = '',
  addonAfter = '',
  children
}: IInputFormWrapperProps) => {
  const _label = isValidElement(label) ? (
    label
  ) : (
    <p className="text-white/75 text-[15px] py-2">{label}</p>
  );

  const _message = isValidElement(errorMsg) ? (
    errorMsg
  ) : (
    <p className="text-red-500 text-sm mt-1">{errorMsg}</p>
  );
  return (
    <>
      {_label}
      <div className="flex items-center bg-[#6453FD]/40 rounded-lg h-11 px-3">
        {addonBefore}
        {children}
        {addonAfter}
      </div>
      {_message}
    </>
  );
};
