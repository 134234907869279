import request from './request';

export const getPWAId = params =>
  request({
    url: '/promotion/user/event/info',
    method: 'GET',
    params
  });

export const reportEvent = data =>
  request({
    url: '/promotion/user/event/record',
    method: 'POST',
    data
  });
