import request from './request';

export const getRebateHistoryList = data =>
  request({
    url: '/rebate/claimHistory',
    method: 'POST',
    data
  });

export const getCategoryList = data =>
  request({
    url: '/rebate/categoryLabel',
    method: 'POST',
    data
  });

export const getRechargeDisplay = data =>
  request({
    url: '/rebate/rechargeDisplay',
    method: 'POST',
    data
  });

export const getRoomList = data =>
  request({
    url: '/rebate/roomList',
    method: 'POST',
    data
  });

export const getAvailableAmount = data =>
  request({
    url: '/rebate/totalAmountAvailable',
    method: 'POST',
    data
  });

export const confirmReward = data =>
  request({
    url: '/rebate/claimCashback',
    method: 'POST',
    data
  });

export const getActivityInfo = data =>
  request({
    url: '/rebate/getRebateInfo',
    method: 'POST',
    data
  });

export const getRebateRate = data =>
  request({
    url: '/rebate/getRoomRate',
    method: 'POST',
    data
  });

export const getExcludeGameList = data =>
  request({
    url: '/rebate/excludeGame',
    method: 'POST',
    data
  });

export const getHistoryAmount = data =>
  request({
    url: '/rebate/historyAmout',
    method: 'POST',
    data
  });
