import dayjs from 'dayjs';
import { formatter } from '@/utils/mathWays';

export function getUserInfo() {
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');

  return {
    getToken() {
      return userInfo.token;
    },
    userInfo
  };
}

const PromiseAny = <T>(promises: Promise<T>[]): Promise<T> => {
  return new Promise((resolve, reject) => {
    let rejectionCount = 0;
    const totalPromises = promises.length;
    for (const promise of promises) {
      promise
        .then(value => resolve(value))
        .catch(() => {
          rejectionCount++;
          if (rejectionCount === totalPromises) {
            reject(new Error('PromiseAny 全部失败'));
          }
        });
    }
  });
};

const fetchTradeCloudflare = () => {
  const uri = 'https://www.cloudflare.com/cdn-cgi/trace';
  // curl https://www.cloudflare.com/cdn-cgi/trace
  const extractIP = (text: string) => {
    const ipLineStartIndex = text.indexOf('ip=');
    const ipLineEndIndex = text.indexOf('\n', ipLineStartIndex);
    const ipLine = text.slice(ipLineStartIndex, ipLineEndIndex);
    const ip = ipLine.split('=')[1];
    console.log('cloudflare ip 获取成功:', ip);
    if (ip == undefined) {
      return Promise.reject();
    }
    return ip;
  };
  return fetch(uri, { mode: 'cors' })
    .then(response => response.text())
    .then(extractIP);
};

const fetchTradeIpApi = () => {
  // const uri = 'http://ip-api.com/json/';
  // curl http://ip-api.com/json/
  // 可以看一下结构
  const extractIP = (ip: string) => {
    if (ip == undefined) {
      return Promise.reject();
    }
    console.log('https://ident.me ip 获取成功:', ip);
    return ip;
  };

  const uri = 'https://ident.me';
  return fetch(uri, { mode: 'cors' })
    .then(response => response.text())
    .then(extractIP);
};

const fetchTradeRPS = () => {
  const uri = 'https://a2.xdv5efw.xyz:32201/nnnipv/';
  // curl https://a2.xdv5efw.xyz:32201/nnnipv
  // 可以看一下结构
  const extractIP = (text: string) => {
    const ip = text.trim();
    console.log('RPS ip 获取成功:', ip);
    return ip;
  };
  return fetch(uri, { mode: 'cors' })
    .then(response => response.text())
    .then(extractIP);
};

export const fetchTraceIP = async () => {
  const ip = await PromiseAny([
    fetchTradeIpApi(),
    fetchTradeCloudflare()
  ]).catch(() => {
    return fetchTradeRPS();
  });
  return ip;
};

const getCountryInfo1 = () => {
  return fetch('http://ip-api.com/json/', { mode: 'cors' })
    .then(response => response.json())
    .then(data => {
      console.log('getCountryInfo1国家', data.country);
      return data.country;
    })
    .catch(error => {
      console.error('Error fetching IP information:', error);
    });
};
const getCountryInfo2 = () => {
  return fetch('https://ipapi.co/json/', { mode: 'cors' })
    .then(response => response.json())
    .then(data => {
      console.log('getCountryInfo2国家', data.country);
      return data.country;
    })
    .catch(error => {
      console.error('Error fetching IP information:', error);
    });
};
const getCountryInfo3 = () => {
  return fetch(`https://ipinfo.io/json?token=bb466c628fc5a2`)
    .then(response => response.json())
    .then(data => {
      console.log('getCountryInfo3国家', data.country);
      return data.country;
    })
    .catch(error => {
      console.error('Error fetching IP information:', error);
    });
};

export const getCountry = async () => {
  const res1 = await getCountryInfo1();
  console.log(res1, 'res1');
  if (res1) {
    return res1;
  }
  const res2 = await getCountryInfo2();
  console.log(res2, 'res2');
  if (res2) {
    return res2;
  }
  const res3 = await getCountryInfo3();
  if (res3) {
    return res3;
  }
};

// 字符串数字 >= 1000000 时，显示 1.00M 保留两位小数，截取显示
export function formatNumber(value) {
  const number = parseFloat(value);

  if (isNaN(number)) {
    return value; // 如果不是有效数字，直接返回原值
  }

  if (number >= 1000000) {
    const formattedValue = number / 1000000;
    return `${formatter.format(formattedValue)}M`;
  }

  return formatter.format(value); // 小于1000000，返回原值
}

//计算当前是第几天
export function calculateStartDay(
  createTime: any,
  remainConfigFirstRechargeGet: number | undefined
): number {
  // 将 createTime 转换为 UTC 日期，并设置时间为 0 点
  const startDate = dayjs.utc(createTime).startOf('day');

  // 获取今天的 UTC 日期，并设置时间为 0 点
  const currentDate = dayjs.utc().startOf('day');

  // 计算从活动开始时间到当前日期之间的天数差
  let daysDifference = currentDate.diff(startDate, 'day');

  // 根据 remainConfigFirstRechargeGet 的值调整天数
  if (remainConfigFirstRechargeGet === 1) {
    daysDifference += 1; // 从第二天开始算作第 1 天
  } else {
    daysDifference += 0; // 从当天开始算作第 1 天
  }

  return daysDifference + 1; // 返回第几天，+1 是因为当天也算作一天
}

// 判断是今天否为周期的最后一天
export function isLastDayOfCycleUTC(startTime, cycleLength) {
  // 解析为 UTC 时间
  const start = dayjs.utc(startTime).startOf('day');

  // 获取当前 UTC 日期
  const today = dayjs.utc().startOf('day');

  // 计算从周期开始到今天经过了多少天
  const daysSinceStart = today.diff(start, 'day');

  // 判断今天是否是周期的最后一天
  return (daysSinceStart + 1) % cycleLength === 0;
}

// 判断是否已过期
export function isExpired(createTime: string): boolean {
  // 解析 createTime 为 dayjs 对象
  const creationDate = dayjs(createTime);

  // 计算 expirationDate，增加 30 分钟
  const expirationDate = creationDate.add(30, 'minute');

  // 获取当前时间
  const currentDate = dayjs();

  // 判断当前时间是否已经超过 expirationDate
  return currentDate.isAfter(expirationDate);
}

export const isToday = date => {
  return dayjs(date).isSame(dayjs(), 'day');
};
