import request from './request';

export const getPayChannel = () =>
  request({
    url: '/generic/payment/methods/pay',
    method: 'GET'
  });
// 查询钱包可用余额
export const getMoney = () =>
  request({
    url: '/wallet/single',
    method: 'GET'
  });
// 查询提现金额配置
export const getWithdrawalLimit = () =>
  request({
    url: '/order/withdrawalLimit',
    method: 'GET'
  });
// 获取
export const getPaymentInfoList = () =>
  request({
    url: '/payment_info',
    method: 'GET'
  });
// 删除用户支付信息（未使用）
export const deletePaymentInfo = id =>
  request({
    url: `/payment_info/delete/${id}`,
    method: 'DELETE'
  });
// 保存用户支付信息（未使用）
export const savePaymentInfo = data =>
  request({
    url: '/payment_info/save_payee_info',
    method: 'POST',
    data
  });
// 生成提现订单
export const withdrawOrder = data =>
  request({
    url: '/order/withdraw',
    method: 'POST',
    data
  });
// 获取提款背景图
export const getAdvertisingSpaceInfo = () =>
  request({
    url: '/advertising/space/getAdvertisingSpaceInfo',
    method: 'GET'
  });
