/* eslint-disable react-refresh/only-export-components */
import ChooseSaque from '@/views/ChooseSaque';
import CPF from '@/views/CPF';
import GameCategory from '@/views/GameCategory';
import Guaguale from '@/views/Guaguale';
import GuagualeHistory from '@/views/Guaguale/history';
import GuagualeDetails from '@/views/Guaguale/details';
// import GuagualeGame from '@/views/Guaguale/game';
import Rebate from '@/views/Rebate';
import RebateDetail from '@/views/Rebate/detail';
import RebateHistory from '@/views/Rebate/history';
import UserCenter from '@/views/UserCenter';
import React from 'react';
import { Navigate, RouteObject, useLocation } from 'react-router-dom';

const Home = React.lazy(() => import('../views/Home'));
const Game = React.lazy(() => import('../views/Game'));
const Profile = React.lazy(() => import('../views/Profile'));
const Promocao = React.lazy(() => import('../views/Promocao'));
const Saque = React.lazy(() => import('../views/Saque'));
const Deposito = React.lazy(() => import('../views/Deposito'));
const DepositoResults = React.lazy(() => import('../views/DepositoResults'));
const DepositoQRcode = React.lazy(() => import('../views/DepositoQRcode'));
const Mensagem = React.lazy(() => import('../views/Mensagem'));
const Servico = React.lazy(() => import('../views/Servico'));
const Registro = React.lazy(() => import('../views/Registro'));
const Register = React.lazy(() => import('../views/Register'));
const Login = React.lazy(() => import('../views/Login'));

// const AgentCenter = React.lazy(() => import('../views/Activities/AgentCenter'));
const AgentCenterDetail = React.lazy(
  () => import('../views/Activities/AgentCenter/AgentCenterDetail')
);
const AgentRule = React.lazy(
  () => import('../views/Activities/AgentCenter/AgentRule')
);
const IncomeStatement = React.lazy(
  () => import('../views/Activities/AgentCenter/IncomeStatement')
);
const SubordinateList = React.lazy(
  () => import('../views/Activities/AgentCenter/SubordinateList')
);
// const Cashback = React.lazy(() => import('../views/Activities/Cashback'));
const Liveness = React.lazy(
  () => import('../views/Activities/Cashback/Liveness')
);
// const Nivel = React.lazy(() => import('../views/Activities/Nivel'));
const Week = React.lazy(() => import('../views/Activities/Week'));
// const GiftCode = React.lazy(() => import('../views/Activities/GiftCode'));
const Cashwheel = React.lazy(() => import('../views/Activities/Cashwheel'));
const Lotterywheel = React.lazy(
  () => import('../views/Activities/Lotterywheel')
);
const PrizeRecordList = React.lazy(
  () => import('../views/Activities/Lotterywheel/PrizeRecordList')
);
const RaffleTicketList = React.lazy(
  () => import('../views/Activities/Lotterywheel/RaffleTicketList')
);
const InviteDetail = React.lazy(
  () => import('../views/Activities/Lotterywheel/InviteDetail')
);
// const VIP = React.lazy(() => import('../views/Activities/VIP'));
const Vipreward = React.lazy(() => import('../views/Activities/VIP/Vipreward'));
const Bonus = React.lazy(() => import('../views/Activities/Bonus'));
// const Retention = React.lazy(() => import('../views/Activities/Retention'));
const RetentionReward = React.lazy(
  () => import('../views/Activities/Retention/RetentionReward')
);
const Template = React.lazy(() => import('../views/Template'));
const Index2 = React.lazy(() => import('../views/Index2'));
const BindPhonePage = React.lazy(() => import('../views/BindPhone'));
const MiddlePage = React.lazy(() => import('../views/MiddlePage'));
const RedPackage = React.lazy(() => import('../views/Activities/RedPackage'));
const TreasureBox = React.lazy(() => import('../views/Activities/TreasureBox'));
const TreasureBoxInviteDetail = React.lazy(() => import('../views/Activities/TreasureBox/InviteDetail'));
const RootRedirect = () => {
  const location = useLocation();
  const searchParams = location.search;
  return <Navigate to={`/home${searchParams}`} replace />;
};

type ExtraRouteProps = { keepAlive?: boolean };
export const routes: (RouteObject & ExtraRouteProps)[] = [
  {
    path: '/',
    element: <RootRedirect />
  },
  {
    path: '/home',
    element: <Home />,
    keepAlive: true
  },
  {
    path: '/remote',
    element: <Index2 />
  },
  // {
  //   path: '/promocao',
  //   element: <Promocao />
  // },
  {
    path: '/promocao/:type?',
    element: <Promocao />
  },
  {
    path: '/saque',
    element: <Saque />
  },
  {
    path: '/chooseSaque',
    element: <ChooseSaque />
  },

  {
    path: '/deposito',
    element: <Deposito />
  },
  {
    path: '/depositoResults',
    element: <DepositoResults />
  },
  {
    path: '/depositoqrPay',
    element: <DepositoQRcode />
  },
  {
    path: '/mensagem',
    element: <Mensagem />
  },
  {
    path: '/servico',
    element: <Servico />
  },
  {
    path: '/registro',
    element: <Registro />
  },
  {
    path: '/register',
    element: <Register />
  },
  {
    path: '/login/:type?',
    element: <Login />
  },

  // 新接入的PG平台的游戏
  {
    path: '/game',
    element: <Game />
  },
  {
    path: '/game_category',
    element: <GameCategory />
    // keepAlive: true
  },
  // {
  //   path: '/ios_game',
  //   element: <IosGame />
  // },
  // 最早开始接入的PG游戏(自己做的残疾游戏)
  // {
  //   path: '/o_game',
  //   element: <OldGame />
  // },
  {
    path: '/profile',
    element: <Profile />
  },
  {
    path: '/CPF',
    element: <CPF />
  },
  {
    path: '/userCenter',
    element: <UserCenter />
  },
  // {
  //   path: '/cashback',
  //   element: <Cashback />
  // },
  {
    path: '/liveness',
    element: <Liveness />
  },
  // {
  //   path: '/level',
  //   element: <Nivel />
  // },
  {
    path: '/week',
    element: <Week />
  },
  // {
  //   path: '/giftcode',
  //   element: <GiftCode />
  // },
  {
    path: '/template',
    element: <Template />
  },
  {
    path: '/cashwheel',
    element: <Cashwheel />
  },
  {
    path: '/lottery',
    element: <Lotterywheel />
  },
  {
    path: '/prizelist',
    element: <PrizeRecordList />
  },
  {
    path: '/rafflelist',
    element: <RaffleTicketList />
  },
  {
    path: '/invitedetail',
    element: <InviteDetail />
  },
  // {
  //   path: '/vip',
  //   element: <VIP />
  // },
  {
    path: '/vipreward',
    element: <Vipreward />
  },
  {
    path: '/bonus',
    element: <Bonus />
  },
  // {
  //   path: '/promocao/retention',
  //   element: <Retention />
  // },
  {
    path: '/retentionreward',
    element: <RetentionReward />
  },
  // {
  //   path: '/agentcenter',
  //   element: <AgentCenter />
  // },
  {
    path: '/agentdetail/:searchUserId?',
    element: <AgentCenterDetail />
  },
  {
    path: '/agentrule',
    element: <AgentRule />
  },
  {
    path: '/income',
    element: <IncomeStatement />
  },
  {
    path: '/subordinatelist/:agentType?',
    element: <SubordinateList />
  },
  {
    path: '/bindphone',
    element: <BindPhonePage />
  },
  {
    path: '/rebate',
    element: <Rebate />
  },
  {
    path: '/rebate/history',
    element: <RebateHistory />
  },
  {
    path: '/rebate/detail',
    element: <RebateDetail />
  },
  {
    path: '/middlepage',
    element: <MiddlePage />
  },
  {
    path: '/redpackage',
    element: <RedPackage />
  },
  {
    path: '/treasurebox',
    element: <TreasureBox />
  },
  {
    path: '/treasurebox/invitedetail',
    element: <TreasureBoxInviteDetail />
  // {
  //   path: '/gglgame',
  //   element: <GuagualeGame />
  },
  {
    path: '/guaguale',
    element: <Guaguale />
  },
  {
    path: '/guaguale/history',
    keepAlive: true,
    element: <GuagualeHistory />
  },
  {
    path: '/guaguale/details',
    element: <GuagualeDetails />
  }
];
