import { Popup, Toast } from 'antd-mobile';
import { InputFormWrapper } from './InputFormWrapper';
import { z } from 'zod';
import { createStringValidator } from '.';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { updateUserInfo, useUserInfo } from '@/store';
import PopupFooter from '@/components/PopupFooter';
import { useTranslation } from 'react-i18next';
import * as api from '@/api';
import { debounce } from 'lodash';

const nicknameValidator = createStringValidator(
  1,
  50,
  'Nome é obrigatório',
  'O nome não pode exceder 50 caracteres'
);
export const nickNameSchema = z.object({
  nickname: nicknameValidator
});
export type TNickNameFormData = z.infer<typeof nickNameSchema>;

const NickNamePopup = ({ nickNameModal }) => {
  const { t } = useTranslation();
  const userInfo = useUserInfo();
  const nickNameForm = useForm<TNickNameFormData>({
    resolver: zodResolver(nickNameSchema),
    defaultValues: {
      nickname: userInfo.nickname!
    }
  });

  const nickNameSubmit = nickNameForm.handleSubmit(async data => {
    if (data.nickname === userInfo.nickname) {
      nickNameModal.close();
    } else {
      const result = await api.changeNickname({
        nickname: data.nickname
      });
      if (result.code === 200 && result.data) {
        Toast.show({
          icon: 'success',
          content: t('successMsg')
        });
        nickNameModal.close();
        updateUserInfo({
          ...userInfo,
          nickname: data.nickname
        });
      }
    }
  });

  return (
    <Popup
      visible={nickNameModal.visible}
      bodyClassName="w-full bg-[#2F1780] bottom-0 h-[600px]"
    >
      <div className="w-full h-11 text-base flex justify-center items-center bg-[#6453FD]/75 text-white sticky top-0 rounded-t-lg">
        Configuração de Apelido
      </div>
      <div className="px-4 py-1">
        <div className="mb-8">
          <InputFormWrapper
            label="Inserir Novo Apelido"
            errorMsg={nickNameForm.formState.errors.nickname?.message}
          >
            <input
              type="text"
              {...nickNameForm.register('nickname')}
              placeholder="Paul the Octopus"
              className="w-full  bg-transparent h-11 px-3 placeholder-[#ddd] text-white outline-none"
            />
          </InputFormWrapper>
        </div>
        <PopupFooter
          onCancel={() => {
            nickNameForm.reset({ nickname: userInfo.nickname! });
            nickNameModal.close();
          }}
          onConfirm={debounce(nickNameSubmit, 250)}
        />
      </div>
    </Popup>
  );
};

export default NickNamePopup;
