const PopupFooter = ({
  onCancel,
  cancelText = 'Cancelar',
  onConfirm,
  confirmText = 'Confirme'
}) => {
  return (
    <div className="flex justify-between mt-3 mb-5">
      <div
        className="w-[172px] h-11 text-white text-base rounded-lg font-[600] text-center border leading-[44px] border-white"
        onClick={onCancel}
      >
        {cancelText}
      </div>
      <div
        className="w-[172px] h-11 bg-[#FFDF12] text-[#2F1780] text-base rounded-lg font-[600] text-center border leading-[44px] border-white"
        onClick={onConfirm}
      >
        {confirmText}
      </div>
    </div>
  );
};

export default PopupFooter;
