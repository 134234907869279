import NavBarComponent from '@/components/NavBar';
import { useEffect, useState } from 'react';
import * as api from '@/api';
import { defaultText } from '@/utils/contants';

const CPF = () => {
  const [paymentInfo, setPaymentInfo] = useState<any>({});

  useEffect(() => {
    getPaymentInfo();
  }, []);

  const getPaymentInfo = async () => {
    const result = await api.getPaymentInfo();
    console.log(result);
    if (result.code === 200 && result.data.length === 1) {
      setPaymentInfo(result.data[0]);
    }
  };
  return (
    <div className="w-full h-[calc(100vh-var(--vh-offset,0px))] userCenter overflow-y-scroll">
      <NavBarComponent
        className="sticky"
        left={
          <span className="text-white text-lg font-[500]">
            Informações do CPF
          </span>
        }
      />
      <div className="mx-5 mt-5">
        <div className="flex items-center mb-3">
          <div className="w-[3px] h-4 rounded-[3px] bg-white mr-2" />
          <span className="text-white text-[15px]">Informações Pessoais</span>
        </div>
        <div className="rounded-lg overflow-hidden">
          <div className="flex justify-between items-center bg-[#6453FD]/40 px-5 py-3 mb-[2px]">
            <span className="text-white text-[15px]">Nome:</span>
            <span className="text-white/75 text-[15px]">
              {paymentInfo.accountName || defaultText}
            </span>
          </div>
          <div className="flex justify-between items-center bg-[#6453FD]/20 px-5 py-3 mb-[2px]">
            <span className="text-white text-[15px]">CPF:</span>
            <span className="text-white/75 text-[15px]">
              {paymentInfo.cpf || defaultText}
            </span>
          </div>
        </div>
      </div>

      <div className="mx-5 mt-5">
        <div className="flex items-center mb-3">
          <div className="w-[3px] h-4 rounded-[3px] bg-white mr-2" />
          <span className="text-white text-[15px]">Conta PIX</span>
        </div>
        <div className="rounded-lg overflow-hidden">
          <div className="flex justify-between items-center bg-[#6453FD]/40 px-5 py-3 mb-[2px]">
            <span className="text-white text-[15px]">CPF ID:</span>
            <span className="text-white/75 text-[15px]">
              {paymentInfo.cpfId || defaultText}
            </span>
          </div>
          <div className="flex justify-between items-center bg-[#6453FD]/20 px-5 py-3 mb-[2px]">
            <span className="text-white text-[15px]">Celular:</span>
            <span className="text-white/75 text-[15px]">
              {paymentInfo.accountMobile || defaultText}
            </span>
          </div>
          <div className="flex justify-between items-center bg-[#6453FD]/40 px-5 py-3 mb-[2px]">
            <span className="text-white text-[15px]">Email:</span>
            <span className="text-white/75 text-[15px]">
              {paymentInfo.accountEmail || defaultText}
            </span>
          </div>
          <div className="flex justify-between items-center bg-[#6453FD]/20 px-5 py-3 mb-[2px]">
            <span className="text-white text-[15px]">CNPJ:</span>
            <span className="text-white/75 text-[15px]">
              {paymentInfo.accountCnpj || defaultText}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CPF;
