import { getGglRecord } from '@/api/guaguale';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import NavBarComponent from '@/components/NavBar';
import { formatter } from '@/utils/mathWays';
import { useNavigate, useLocation } from 'react-router-dom';
import { InfiniteScroll } from 'antd-mobile'
import NoData from '@/components/NoData';

const getRecordList = async (params: {page: number, size: number}) => {
  const {data = {
    records: [],
    total: 0
  }} = await getGglRecord(params);
  return data
}

let isLoading = false;
const Guaguale = () => {
  const [recordList, setRecordList] = useState<any>([]);
  const { totalAmount = 0 } = useLocation().state || {};
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const size = 10

  const loadMore = () => {
    if (isLoading) return Promise.reject();
    isLoading = true;

    return getRecordList({ page: page + 1, size }).then((data) => {
      setPage(page + 1);
      setRecordList([...recordList, ...data.records]);

      if (data.records.length < size) {
        setHasMore(false);
      }
    }).finally(() => {
      isLoading = false;
    });
  }

  useEffect(() => {
    loadMore();
  }, []);

  return (
    <div className='text-white'>
      <NavBarComponent
        left={
          <span className="text-white text-lg font-[500]">
            Ganhos  Acumulados
          </span>
        }
      />

      <div className='mt-[78px] px-4'>
        <p className='text-[15px] font-[500] mb-3'>Ganhos  Acumulados <span className='text-[15px] font-[600] text-[#FFDF12]'>{totalAmount}</span></p>
        <div className='list'>
          {recordList.length ? (
            <>
              {recordList.map((item, index) => (
                <div
                  className='bg-[#6453FD66] rounded-[8px] px-4 py-2 mb-2'
              onClick={() => navigate(`/guaguale/details`, {
                state: { data: item }
              })}
              key={index}>
              <div className='flex items-end mb-1 text-[15px] font-[600]'>
                <p className='flex-1 tracking-wide bg-[url(@/assets/images/ggl/ico2.png)] bg-no-repeat bg-contain pl-9 truncate'>ID: {item.sourceId}</p>
                <p className='mr-2'>
                  <span className='mr-1'>+ {formatter.format(item.amount)}</span>
                  <span className='inline-block border border-2 border-white w-2 h-2 rotate-45 border-b-0 border-l-0 -translate-y-0.5 translate-x-0.5'></span>
                </p>
              </div>
              <div className='flex justify-between text-[11px] text-[#FFFFFFBF]'>
                <p>Fonte：{item.sourceName}</p>
                <p>{item.collectionTime && dayjs(item.collectionTime).local().format('YYYY-MM-DD HH:mm:ss')}</p>
              </div>
            </div>
              ))}
            {hasMore && (
              <InfiniteScroll loadMore={loadMore} hasMore={hasMore}>
                {/* <InfiniteScrollContent hasMore={hasMore} /> */}
              </InfiniteScroll>
            )}
            </>) : <NoData />
          }
        </div>
      </div>
    </div>
  );
};

export default Guaguale;
