import { getS3Url } from "@/utils"
// import { useTabKey } from '@/store';
// import { useEffect } from 'react';

const URLS = {
  // 空字符串没有高亮
  inicio: '/home',
  promocao: '/promocao',
  deposito: '',
  servico: '/servico',
  meu: '/userCenter'
};

export const BarItem = ({type, hlName = '_2'}) => {
  const isActive = URLS[type] && location.pathname.startsWith(URLS[type])
  const src = getS3Url(`home/i_${isActive ? type + hlName : type}.png`)

  return <img className="w-full" src={src} alt="" />
};
