import { NavBar } from 'antd-mobile';
import { type FC, ReactNode } from 'react';

import './index.less';
import { useNavigate, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

interface Props {
  back?: ReactNode | null;
  backArrow?: boolean | ReactNode;
  children?: ReactNode;
  left?: ReactNode;
  onBack?: () => void;
  right?: ReactNode;
  className?: string;
}

const NavBarComponent: FC<Props> = props => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const queryParams = searchParams.toString();
  const onBackClick = () => {
    // 历史栈信息超过2就可以返回上一页，否则返回首页
    if (window.history.length > 2) {
      navigate(-1);
    } else {
      navigate(queryParams ? `/home?${queryParams}` : '/home');
    }
  };

  return (
    <div className={classNames("fixed top-0 w-[393px] z-[100]", props?.className)}>
      <NavBar
        onBack={() => onBackClick()}
        {...props}
        style={{
          '--height': '52px',
          background: 'linear-gradient(180deg, #412290 0%, #3C228C 100%)'
        }}
        className="shadow-[0px_4px_4px_0px_#0000001A]"
      >
        {props?.children}
      </NavBar>
    </div>
  );
};

export default NavBarComponent;
