import { Suspense, useEffect, useState } from 'react';
import { useLocation, useRoutes } from 'react-router-dom';
import { routes } from './routes';
import { ConfigProvider } from 'antd-mobile';
import './App.css';
import {
  changeLang,
  changeTheme,
  useShowAnimate,
  useThemeStore,
  useUserInfo
} from '@/store';
import ptBR from 'antd-mobile/es/locales/pt-BR';
import enUS from 'antd-mobile/es/locales/en-US';
import { fetchTraceIP, getCountry } from '@/tools/index.ts';
import { report } from './api/report';
import { getInstallConfig } from './api/pwa';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { generateFingerprint } from './api/record';
import { getMoney } from './api';
// import { getGenericShare } from '@/api/servico';
import { reportEvent } from './api/dealWithReport';
import { cryptoID, reportParams } from './utils/crypto';
import { isAndroid, isFromDrainageWebsite, isInPWA, prefetch } from './utils';
import { If, Then, When } from 'react-if';
import tips_403 from '@/assets/images/tips_403.png';
import { PWAProvider } from './providers/PWA';
import KeepAlive from 'react-activation';
import { useServicoList, usePromocaoList } from '@/store';
import { preloadRoute } from '@/utils/preloadRoute';

dayjs.extend(utc);
dayjs.extend(timezone); // 使用时区插件
dayjs.extend(weekOfYear);
dayjs.extend(customParseFormat);

const languageConfig = {
  pt: ptBR,
  en: enUS
};

preloadRoute('/promocao');
preloadRoute('/servico');
preloadRoute('/userCenter');
preloadRoute('/deposito');

prefetch('/images/bg1b.png', 'image/png', 'image');
prefetch('/images/bg2b.png', 'image/png', 'image');
prefetch('/images/bg3b.png', 'image/png', 'image');
prefetch('/images/bg4b.png', 'image/png', 'image');

function App() {
  const closeShowAnimate = useShowAnimate(state => state.closeShowAnimate);
  const userInfo = useUserInfo.getState();

  const route = useRoutes(
    routes.map(item => ({
      ...item,
      element: item.keepAlive ? (
        <KeepAlive cacheKey={item.path} name={item.path}>
          <PWAProvider>{item.element}</PWAProvider>
        </KeepAlive>
      ) : (
        <PWAProvider>{item.element}</PWAProvider>
      )
    }))
  );
  const { theme } = useThemeStore();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const language = params.get('language') || 'pt';
  const themeStr = params.get('theme') || 'theme1';

  useEffect(() => {
    changeLang(language);
    changeTheme(themeStr);
  }, [language, themeStr]);

  // 登录状态更新刷新页面
  useEffect(() => {
    window.addEventListener(
      'storage',
      res => res.key === 'userInfo' && window.location.reload()
    );
  }, []);

  const getClientIp = async () => {
    const clientIp = await fetchTraceIP();
    localStorage.setItem('clientIp', clientIp);
    console.log('获取到的ip为：', clientIp);
  };

  const [isBR, setIsBR] = useState(true);
  const getCountryInfo = async () => {
    // 获取时区
    // const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // timeZone === 'America/Sao_Paulo'
    // 获取语言
    // const language = navigator.language || navigator.languages[0];
    // language.startsWith('pt')
    const country = await getCountry();
    console.log('获取到的国家为：', country);
    if (country === 'BR' || country === 'Brazil') {
      setIsBR(true);
      // } else {
      // setIsBR(false);
    }
  };

  // 首充上报
  const firstRecharge = async () => {
    const { code, data } = await getMoney();
    const _window = window as any;
    if (
      code === 200 &&
      data.firstRechargeFlag === 1 &&
      _window.trackFn &&
      _window.trackName
    ) {
      console.log('判断是否有值Purchase');
      console.log(
        '%c 首充上报',
        'font-size:13px; background:pink; color:#bf2c9f;'
      );
      const res = await reportEvent({
        pwaId: localStorage.getItem('pwaId'),
        eventType: 'USER_FIRST_RECHARGE_SUCCESS'
      });
      if (res.code === 200) {
        localStorage.setItem('eventTypes2', 'USER_FIRST_RECHARGE_SUCCESS');
        if (localStorage.getItem('thirdPlatform') == 'ttq') {
          // @ts-ignore
          window.trackFn(window.trackName['Purchase'], {
            contents: [
              {
                ...reportParams(),
                event_id: cryptoID('USER_FIRST_RECHARGE_SUCCESS'),
                value: data.amount,
                currency: 'BRL',
                product_catalog_id: 'deposit_id',
                content_id: 'deposit_id'
              }
            ]
          });
          console.log(
            'ttq首充上报参数',
            reportParams(),
            cryptoID('USER_FIRST_RECHARGE_SUCCESS'),
            data.amount,
            'deposit_id'
          );
        } else {
          // @ts-ignore
          window.trackFn(window.trackName['Purchase'], {
            ...reportParams(),
            event_id: cryptoID('USER_FIRST_RECHARGE_SUCCESS'),
            value: data.amount,
            currency: 'BRL',
            product_catalog_id: 'deposit_id'
          });
        }
      }
    }
  };
  useEffect(() => {
    // getCountryInfo();
    // queryGetGenericShare();
    if (!localStorage.getItem('clientIp')) {
      console.log('准备获取ip，执行getClientIp方法');
      getClientIp();
    }
  }, []);

  const initTrack = async () => {
    const ch = params.get('ch') || localStorage.getItem('ch') || '';
    if (!ch) return;

    const { code, data } = await getInstallConfig({ ch });
    if (code == 200 && data) {
      const isFromDrainageWebsiteVar = params.get('isFromDrainageWebsite');
      const isFromFb = !!isFromDrainageWebsiteVar || isFromDrainageWebsite();

      const { type, pixelId } = data;
      if (type && pixelId) {
        if (!isFromFb && (type == 2 || type == 3 || type == 7 || type == 8)) {
          // 如果不是来自fb，即使type=。。。也不上报
          return;
        }

        // type为3不上报, 3: fb-api
        if (isFromFb && (type == 3 || type == 8)) return;

        console.log('准备初始化', isFromFb && type && pixelId);
        // @ts-ignore
        window.init(type, pixelId);
        localStorage.setItem('type', type);
        localStorage.setItem('pixelId', pixelId);
      } else {
        localStorage.removeItem('type');
        localStorage.removeItem('pixelId');
      }
      if (userInfo.token && !localStorage.getItem('hasViewContent')) {
        // @ts-ignore
        if (window.trackFn && window.trackName) {
          console.log('判断是否有值ViewContent');
          // @ts-ignore
          window.trackFn(window.trackName['ViewContent'], {
            ...reportParams(),
            event_id: cryptoID('FIRST_OPEN_PAGE')
          });
          localStorage.setItem('hasViewContent', 'true');
        }
      }
    }
  };

  useEffect(() => {
    initTrack();
    checkPWAAndPreventionJump();
  }, []);

  const reportPage = async (userId, page) => {
    const allowAll = false;
    const alowLists = ['/home', '/login', '/template', '/game_category'];

    if (!allowAll && !alowLists.some(path => page.startsWith(path))) return;

    if (!localStorage.getItem('clientIp')) {
      await fetchTraceIP();
    }
    if (!localStorage.getItem('yz_finger_print_key')) {
      await generateFingerprint();
    }
    const res = await report({
      user_id: userId,
      visit_page: page,
      create_time: dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss')
    });
    // console.log(`访问${page}页面上报结果`, res);
  };
  useEffect(() => {
    // game_category页面不能滚
    // if (['/game_category'].includes(location.pathname)) {
    //   document.body.style.setProperty('overflow', 'hidden', 'important');
    // } else {
    //   document.body.style.setProperty('overflow', 'visible', 'important');
    // }
    // console.log('Route changed to:', location.pathname);
    // 在路由变化时执行的操作
    const page = location.pathname + location.search;
    // console.log('访问页面:', page);
    // 不做登录限制
    reportPage(userInfo?.userId, page);
    // console.log(
    //   '%c 首充前上报判断token和eventTypes2',
    //   'font-size:13px; background:pink; color:#bf2c9f;',
    //   userInfo.token,
    //   !localStorage.getItem('eventTypes2')
    // );
    if (userInfo.token && !localStorage.getItem('eventTypes2')) {
      // 首充上报
      firstRecharge();
    }
  }, [location.pathname]);

  // 检测是否在pwa应用中，并且是否需要跳转
  const [pwaJump, setPwaJump] = useState<string>('');
  const [showPage, setShowPage] = useState<string>('');
  const checkPWAAndPreventionJump = async () => {
    if (!isInPWA()) {
      setShowPage('home');
      return;
    }
    console.log('检测是否在pwa应用打开的项目``111111111```````');
    console.log('当前全链接:', window.location.href);
    const hostname = window.location.hostname;
    const currentUrl = new URL(window.location.href);
    const params = new URLSearchParams(currentUrl.search);
    const newUrlParams = Array.from(params.entries())
      .filter(([key]) => key !== 'preventionJump' && key !== 'currentDomain')
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    if (currentUrl.searchParams.get('preventionJump') !== 'true') {
      setShowPage('home');
      return;
    }
    const currentDomain = currentUrl.searchParams.get('currentDomain');
    const result = await postData(`https://${currentDomain}/hdc-api/domain`, {
      domain: hostname
    });
    const randomDomain = result[0];
    const jumpDomain = getRandomElement(randomDomain.lunchDomain.split(','));

    console.log('检测是否在pwa应用打开的项目``22222222```````', jumpDomain);
    const newUrl = `https://${jumpDomain}/home?${newUrlParams}`;
    console.log(
      '检测是否在pwa应用打开的项目````````33333333333````````',
      newUrl
    );
    if (newUrl) {
      setPwaJump(newUrl);
      setShowPage('iframe');
      // window.location.href = newUrl;
    }
  };

  function getRandomElement(array) {
    return array[Math.floor(Math.random() * array.length)];
  }

  async function postData(url = '', data: Record<string, unknown>) {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    } catch (error) {
      console.error('Error during fetch:', error);
      return null;
    }
  }

  useEffect(() => {
    const container = document.getElementById('bg_container');

    const observer = new MutationObserver((mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          if (
            (mutation.target as HTMLElement)?.classList?.contains(
              'ggame-item-content-img'
            )
          ) {
            setTimeout(() => {
              closeShowAnimate();
            }, 500);
            observer.disconnect();
          }
        }
      }
    });

    if (container) {
      observer.observe(container, { childList: true, subtree: true });
    }

    return () => {
      if (container) {
        observer.disconnect();
      }
    };
  }, [showPage]);

  const getAndUpdateServioList = useServicoList(
    state => state.getAndUpdateList
  );
  const getAndUpdatePromocaoList = usePromocaoList(
    state => state.getAndUpdateList
  );

  useEffect(() => {
    // 客服数据
    getAndUpdateServioList();
    // 活动页数据
    getAndUpdatePromocaoList();
  }, []);

  return (
    <ConfigProvider locale={languageConfig[language]}>
      <When condition={showPage == 'iframe' && pwaJump !== ''}>
        <iframe src={pwaJump} style={{ width: '100%', height: '100%' }} />
      </When>
      <When condition={showPage == 'home'}>
        <div
          className={`w-full  flex justify-center ${theme} relative main-bg ${
            location.pathname === '/home' || location.pathname === '/'
              ? ''
              : 'z-50'
          } ${isAndroid ? 'min-h-screen' : 'min-h-full'}`}
          id="bg_container"
        >
          <div className="w-[393px] h-full">
            <If condition={isBR}>
              <Then>
                <Suspense>{route}</Suspense>
              </Then>
            </If>
            <If condition={!isBR}>
              <Then>
                <div className="w-full h-full bg-white pt-[120px]">
                  <div className="relative">
                    <img
                      className="w-[337px] h-[270px] m-auto"
                      src={tips_403}
                      alt=""
                    />
                    <div className="w-full text-[26px] font-bold text-center absolute bottom-[25px]">
                      Acesso Restrito
                    </div>
                    <div className="w-full text-[14px] text-[#000]/45 text-center absolute bottom-[5px]">
                      Sua região está impedida de acessar nossos serviços.
                    </div>
                  </div>
                </div>
              </Then>
            </If>
            {/* </Else> */}
          </div>
        </div>
      </When>
    </ConfigProvider>
  );
}

export default App;

