import { defaultText } from '@/utils/contants';
import { Grid, GridProps } from 'antd-mobile';
import classNames from 'classnames';
import { Fragment } from 'react/jsx-runtime';

interface IColumn {
  title: React.ReactNode;
  prop: string;
  span?: number;
  render?: (item) => React.ReactNode;
}

type CommonTableProps = {
  columns: IColumn[];
  dataSource: any[];
  customHeader?: React.ReactNode;
  gridGapProps?: GridProps['gap'];
  showDefaultHeader?: boolean;
};

const CommonTable = ({
  columns = [],
  dataSource = [],
  customHeader,
  showDefaultHeader = true,
  gridGapProps = 2
}: CommonTableProps) => {
  const columnsLength = columns.reduce(
    (prev, cur) => prev + (cur.span || 1),
    0
  );
  const showCustomHeader = !!customHeader;

  return (
    <Grid
      columns={columnsLength}
      gap={gridGapProps}
      className="rounded-2xl overflow-hidden"
    >
      {showCustomHeader && customHeader}
      {showDefaultHeader &&
        columns.map(item => (
          <Grid.Item
            key={item.prop}
            span={item.span || 1}
            className={classNames(
              'text-white py-2.5 text-center text-base',
              customHeader ? 'bg-[#6453FD]/40' : 'bg-[#6453FD]/70'
            )}
          >
            {item.title}
          </Grid.Item>
        ))}
      {dataSource.map((item, index) => (
        <Fragment key={item.id || index}>
          {columns.map(column => {
            const flag = [showCustomHeader, showDefaultHeader].every(Boolean);
            return (
              <Grid.Item
                key={column.prop}
                span={column.span || 1}
                className={classNames(
                  'text-white py-2.5 text-center text-sm ',
                  (index + 1) % 2 === 0 ? 'bg-[#6453FD]/40' : 'bg-[#6453FD]/20'
                )}
              >
                {column.render
                  ? column.render(item)
                  : item[column.prop] || defaultText}
              </Grid.Item>
            );
          })}
        </Fragment>
      ))}
    </Grid>
  );
};

export default CommonTable;
