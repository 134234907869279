export default {
  hello: 'Hello, world!',
  errorMsg: {
    '101': 'Login is disabled, please login again.',
    '40101': 'The username has been used, please change it.',
    '40102': 'You cannot modify this user information.',
    '40104': 'Login failed with wrong username or password.',
    '40201':
      'The user name is in the wrong format, please modify it and try again.',
    '40202':
      'The password is in the wrong format, please change it and try again.',
    '40206': 'Incorrect old password entered. Please enter again.',
    '40207': 'Nickname cannot be empty, please enter it and try again.',
    '40212':
      'The recharge amount is out of range, please enter a valid amount.',
    '40213':
      'The withdrawal amount is out of range, please enter a valid amount.',
    '40301': 'Failed to create withdrawal order',
    '40320':
      'The payment channel you have chosen is not working, please choose again.',
    '40321':
      'The payment channel you have chosen is not working, please choose again.',
    '40322': 'The withdrawal failed, please try again later.',
    '40323': 'Failed to recharge, pull list parameter exception.',
    '40324': 'Failed to recharge, no amount submitted, please try again.',
    '40325':
      'Failed to recharge, wrong channel information, re-select recharge channel.',
    '40326': 'Failed to recharge, please re-select your payment channel.',
    '40327':
      'Withdrawal failed. Withdrawal information is incorrect, please try again.',
    '40328': 'Withdrawal failed, no amount submitted, please modify and retry.',
    '40329': 'Withdrawal failed, wrong channel information, please try again.',
    '40330':
      'Withdrawal failed, incorrect collection information, please try again.',
    '40331': 'Withdrawal failed, channel is busy, please try again.',
    '40332': 'Recharge failed. Please contact customer service.',
    '40333': 'Withdrawal failed, please contact customer service.',
    '40334':
      'Failed to query recharge, the request parameter is abnormal, please retry.',
    '40335':
      'Failed to query withdrawals, the request parameter is abnormal, please retry.',
    '40336': 'Query tripartite channel order exception, please retry.',
    '40337':
      'Order information is abnormal. Please contact customer service to resolve the issue.',
    '40338':
      'Order information is abnormal. Please contact customer service to resolve the issue.',
    '40339':
      'Order information is abnormal. Please contact customer service to resolve the issue.',
    '40340':
      'Order information is abnormal. Please contact customer service to resolve the issue.',
    '40341':
      'Order information is abnormal. Please contact customer service to resolve the issue.',
    '40342':
      'Order information is abnormal. Please contact customer service to resolve the issue.',
    '40343':
      'Order information is abnormal. Please contact customer service to resolve the issue.',
    '40344': 'Insufficient wagering amount, withdrawal cannot be processed.',
    '40345': 'Request error, please refresh and try again!',
    '40346': 'Request error, please refresh and try again!',
    '40347':
      'Order information is abnormal. Please contact customer service to resolve the issue.',
    '40349': 'The order has been updated, please refresh the page.',
    '40350': 'Exceeded the limit of accounts linked to the CPF',
    '41000': 'Oops, the server hiccuped~',
    '41001': 'Request error. Please try again later.',
    '41002': 'Service processing failed, please try again.',
    '41003': 'Your account information was not found, please try again.',
    '41010':
      'Not authorized to query this wallet information, please try again.',
    '41011':
      'No commission available to withdraw. Invite more new users to earn commissions.',
    '41012': 'Insufficient wallet balance, please complete a recharge first.',
    '41013': 'Information error, please refresh and try again.',
    '40400': 'The redemption code does not exist!',
    '40401': 'The redemption code has expired, please check and try again!',
    '40402':
      'The redemption code has been deactivated, please check or use a different redemption code!',
    '40403': 'You have exceeded the redemption limit!',
    '40404':
      'This redemption code has already been used, please use a different redemption code!',
    '40405': 'The redemption event has not started yet, please stay tuned!',
    '40406': `Complete today's recharge task to receive the redemption code reward!`,
    '40407': `Not eligible for use by this user! Please use another redemption code!`,
    '40408': `This redemption code cannot be used, please use another redemption code!`,
    '40409': `Redemption failed, please try again later!`,
    '40410': `Redemption code cannot be empty!`,
    '40411': `Processing, your action was too fast.`,
    '40412': `Redemption failed, please try again later!`,
    '20002': `Order information is abnormal. Please contact customer service to resolve the issue.`,
    '40512': `You have no rewards to collect.`,
    '40513': `Failed to collect the reward. Please try again.`,
    '41018': `Your request does not meet the requirements.`,
    '40451': `Sorry! Please claim your reward again!`,
    '40452': `Receiving prizes...Please wait!`,
    '40453': `Dear!  claim failed, please try again later!`,
    '40454': `reward code does not exist or has expired!`,
    '40530': `Claim failed, you haven't met the level requirement.`,
    '40531': `Claim failed, there are no rewards available to claim.`,
    '40532': `Reward is being settled, please try again later.`,
    '20003': `Reward claim failed`,
    '40510': `The treasure chest event is not activated, please try again`,
    '40511': `You have already claimed the reward for this time slot!`,
    '40514': `You do not meet the event requirements`,
    '41014': `The event has not started, please try again!`,
    '41015': `The event has not started, please try again!`,
    '41016': `The event has not started, please try again!`,
    '41017': `Reward has been claimed.`,
    '40351': `It is necessary to make a deposit in order to withdraw.`,
    '41021': `You cannot claim this reward.`,
    '41022': `Claim failed, you did not reach the deposit amount.`,
    '41023': `Claim failed, you did not reach the betting amount.`,
    '40199': `IP registration restrictions not met`,
    '40413': `A recharge is required within 7 days to claim.`,
    '40630': 'Sorry, you have not met the conditions required to scratch the card!',
    '40631': 'Sorry, the scratch card activity is temporarily closed!',
    '40632': 'Sorry, you have used up all your scratch attempts for today!',
  },
  successMsg: 'success',
  loading: 'Loading',
  extractionFailed: 'Extraction failed',
  // 模板相关
  app: 'Verified by App',
  reviews: 'reviews',
  downloads: 'Downloads',
  rated: 'Rated for',
  play: 'Play',
  install: 'Instalar',
  removed: 'The app has been removed',
  share: 'Share',
  wishlist: 'Add to wishlist',
  about: 'About this app',
  updated: 'Updated on',
  data: 'Data safety',
  safety:
    'Safety starts with understanding how developers collect and share your data. Data privacy and security practices may vary based on your use,region, and age. The developer provided this information and may update it over time.',
  parties: 'No data shared with third parties',
  sharing: 'Learn more about how developers declare sharing',
  types: 'This app may collect these data types',
  location: 'Location, Personal info and 5 others',
  transit: 'Data is encrypted in transit',
  request: 'You can request that data be deleted',
  details: 'See details',
  Games: 'Games',
  Apps: 'Apps',
  Movies: 'Movies',
  Books: 'Books',
  Kids: 'Kids',
  now: 'Now',
  environmental: 'Successful environmental testing',
  detecting: 'Detecting the installation environment...',
  found: 'Application not found',
  installed: 'Successfully installed', // 已成功安装
  experience:
    'Please find the app icon on your home screen and tap Run to enjoy a more convenient and smooth experience.', // 请在您的主屏幕上找到应用图标并点击运行，享受更加便捷流畅的体验。
  name: 'Application Name', // 应用名称
  Mobile: 'Mobile Icons', // 手机端图标
  icon: 'PC Icon', // PC端图标
  noSupport: 'Your browser does not support pwa',
  noDownloadUrl: 'You have not configured the download address for the App',
  unregister: 'Please uninstall the desktop app',
  noFindApp: 'Application not found',
  cancelDownload: 'You have cancelled the installation!',
  failDownload: 'Installation failed!'
};
