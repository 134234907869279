import request from './request';
import { loginRegisterData } from './interface';

// 获取用户消息列表
export const getMessageList = params =>
  request({
    url: '/message/getMessageUserList',
    method: 'get',
    params
  });
// 获取用户未查看消息数量
export const getUserCount = (params = {}) =>
  request({
    url: '/message/getUserCount',
    method: 'get',
    params
  });
  // 修改消息状态
export const updateMessageState = data =>
  request({
    url: '/message/updateState',
    method: 'post',
    data
  });
