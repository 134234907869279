import Big from 'big.js';

export const subtractDecimals = (num1, num2) => {
  const bigNum1 = new Big(num1);
  const bigNum2 = new Big(num2);
  const result = bigNum1.minus(bigNum2);
  return result.toString();
};

const getStr = () => {
  const language = localStorage.getItem('language') || 'pt';
  if (language === 'en') {
    return 'en-US';
  }
  if (language === 'pt') {
    return 'pt-BR';
  }
};
// 格式化
export const formatter = new Intl.NumberFormat(getStr(), {
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});
