import request from './request'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

/** 获取浏览器指纹 */
const FINGER_PRINT_KEY = 'yz_finger_print_key'
export async function generateFingerprint() {
  const fp = await FingerprintJS.load()
  const result = await fp.get()
  localStorage.setItem(FINGER_PRINT_KEY, result.visitorId)
  return result.visitorId
}

interface DeviceInfo {
  /** 应用ID */
  applianceId?: string
  /** 渠道ID */
  channelId?: string
  /** 设备号 */
  imei?: string
  /** 机型 */
  model?: string
  /** 浏览器版本 */
  browserVersion?: string
  /** 分辨率 */
  resolution?: string
  /** IP */
  ip?: string
  /** 地区 */
  area?: string
}

/** 获取设备信息 */
export const getDeviceInfo = async () => {
  const params = new URLSearchParams(window.location.href.split('?')[1]);
  const appId = params.get('appId') || undefined
  const channelId = params.get('channelId') || undefined

  const userAgent = navigator.userAgent
  const isiPhone = /iPhone/.test(navigator.userAgent)
  const isAndroid = /Android/.test(navigator.userAgent)

  const screenWidth = window.screen.width
  const screenHeight = window.screen.height
  const data: DeviceInfo = {
    applianceId: appId,
    channelId,
    browserVersion: userAgent,
    model: isAndroid ? 'Android' : isiPhone ? 'iPhone' : 'PC',
    resolution: `${screenWidth}*${screenHeight}`
  }

  // try {
  //   const res = await fetch('https://ipapi.co/json')
  //   const resJson = await res.json()
  //   data.ip = resJson.ip
  //   data.area = `${resJson.country_capital} / ${resJson.country_name}`
  // } catch (err) {
  //   console.error(err)
  // }

  return data
}

/** 埋点上报 */
export enum EventEnum {
  /** 进入安装页 */
  EnterPage = 0,
  /** 点击安装应用 */
  ClickButton = 1,
  /** 安装成功 */
  InstallApp = 2,
  /** 打开应用 */
  OpenApp = 3
}

interface RecordParams extends DeviceInfo {
  /** 事件类型 */
  event: EventEnum
  /** 消息通知 */
  notify?: boolean
  /** FCM token */
  token?: string
}
export const recordAnalytics = async (data: RecordParams) => {
  const deviceInfo = await getDeviceInfo()

  const info = {
    ...deviceInfo,
    ...data
  }
  const fingerprint = localStorage.getItem(FINGER_PRINT_KEY)
  if (fingerprint) {
    info.imei = fingerprint
  } else {
    info.imei = await generateFingerprint()
  }

  request({
    url: 'https://backend.pwa.x6d59e.cc/app/record/saveAppRecord',
    // url: '/app/record/saveAppRecord',
    method: 'post',
    data: info
  });
}
