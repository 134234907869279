import { FC } from "react";
import i_bai from '@/assets/icon/i_bai.png';

import classNames from "classnames";

interface TipsProps {
  tipTitle: string;
  tipList: string[];
  className?: string;
  tipClassName?: string;
  tipIcon?: string;
}

const Tips: FC<TipsProps> = ({
  tipTitle,
  tipList,
  className,
  tipClassName,
  tipIcon
}) => {
  return (
    <>
      <div
        className={classNames(
          'flex items-start text-white justify-start px-5 py-2.5 mt-4 mb-4 bg-[#6453FDB2]/70 rounded-lg',
          className
        )}
      >
        <img className="w-5 mr-2" src={tipIcon ?? i_bai} alt="" />
        <span>{tipTitle}</span>
      </div>
      <ul className={classNames('text-white', tipClassName)}>
        {tipList.map((item, index) => (
          <li key={index} className="mt-1 mb-4 break-words">
            {item}
          </li>
        ))}
      </ul>
    </>
  );
};

export default Tips;