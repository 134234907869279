// import { getUserInfo } from '@/tools';
import { Toast } from 'antd-mobile';
import { getUserInfo } from '@/tools';
import axios, { AxiosRequestConfig } from 'axios';
import i18n from '@/i18n/index';
import { deleteUserInfo } from '@/store';
import { noAuthUrls } from './configs';
import {
  isIOS,
  isAndroid,
  isFromIOSScreen,
  isInPWA,
  isInAndroidApp,
  isInIosApp,
  isWindows,
  isMacOS
} from '@/env';
interface ResponseData<T = any> {
  code: number;
  msg: string;
  success?: boolean;
  traceId?: string;
  data?: T;
  errorData?: T;
}

const client = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL,
  // timeout: 5000
});

client.interceptors.request.use(
  config => {
    const token = getUserInfo().getToken();
    // console.log('token', token);
    token && (config.headers['Access-Token'] = token);
    const clientIp = localStorage.getItem('clientIp') || '';
    // console.log('interceptors-clientIp', clientIp);
    clientIp && (config.headers['Client-Ip'] = clientIp);
    config.headers['cache-control'] = 'no-cache'

    // 增加上报类型
    addTrackPrams(config.headers)
    return config;
  },
  err => err
);

client.interceptors.response.use(
  res => {
    const t = i18n.t;
    if (res.data.code === 200) {
      return res.data;
    } else if (res.data.code === 101) {
      if (!noAuthUrls.includes(res.config.url!)) {
        Toast.show({ content: t(`errorMsg.${res.data.code}`) });
        // 用户未登录
        deleteUserInfo();
        setTimeout(() => {
          const urlParams = new URLSearchParams(location.search);
          location.href = `${location.origin}/login?${urlParams}`
        }, 2000);
      }
      return res.data;
    } else {
      if (res.data.code === 40527) {
        // 此错误码单独处理
        return res.data;
      }
      const translation = t(`errorMsg.${res.data.code}`, {
        defaultValue: `errorMsg.${res.data.code}`
      });
      const isMissingTranslation = translation === `errorMsg.${res.data.code}`;
      // 不在翻译文件中的code显示msg
      if (isMissingTranslation) {
        res.data.msg && Toast.show({ content: res.data.msg });
      } else {
        Toast.show({ content: t(`errorMsg.${res.data.code}`) });
      }
      return res.data;
    }
  },
  err => err
);

const addTrackPrams = (headers) => {
  let loginUserType = 'unknown'
  if (isIOS) loginUserType = 'iOS_H5'
  if (isAndroid) loginUserType = 'Android_H5'
  if (!isIOS && !isAndroid) loginUserType = 'PC'
  if (isInPWA()) loginUserType = 'PWA'
  if (isInAndroidApp) loginUserType = 'Android_APP'
  if (isInIosApp) loginUserType = 'iOS_APP'
  if (isFromIOSScreen()) loginUserType = 'iOS_Bookmark'

  let clientSystemType = 'unknown'
  if (isIOS) clientSystemType = 'iOS'
  if (isAndroid) clientSystemType = 'Android'
  if (isWindows) clientSystemType = 'Windows'
  if (isMacOS) clientSystemType = 'MacOS'

  const params = {
    // 终端类型 苹果H5、安卓H5、PC、PWA、APK、APK上架包、iOS、iOS书签
    loginUserType,
    // 系统类型：Android、iOS、Windows等
    clientSystemType,
    // equipmentIdentification 设备标识：获取设备的唯一标识符，例如：a3dc575268c1e7f4d9c0e4aad7c7769f
    equipmentIdentification: localStorage.getItem('yz_finger_print_key') || 'unknown',
    // equipmentName 设备名称：手机新型号或代号，比如 iPhone 16、VIVO、Honor 15等
    equipmentName: localStorage.getItem('deviceName') || 'unknown'
  }

  Object.entries(params).forEach(([k, v]) => {
      headers[k] = v
  })
}

const request = (config: AxiosRequestConfig): Promise<ResponseData> => {
  return client.request(config);
};

export default request;
