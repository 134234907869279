import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import down_arrow from '@/assets/icons/rebate/down_arrow.png';
import top_arrow from '@/assets/icons/rebate/top_arrow.png';
import activity_triangle_tip from '@/assets/icons/common/activity_triangle_tip.png';
import { useCommonVisible } from '@/hooks';
import { Grid, InfiniteScroll, Popup, SpinLoading } from 'antd-mobile';
import { useMemo, useState, useEffect } from 'react';
import classNames from 'classnames';
import './index.less';
import * as api from '@/api';
import NavBarComponent from '@/components/NavBar';
import CommonTable from '@/components/Table';
import NoData from '@/components/NoData';
import PopupFooter from '@/components/PopupFooter';

const RebateDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [hasMore, setHasMore] = useState(false);
  const [searchParams] = useSearchParams();
  const activityId = searchParams.get('activityId');
  const cascaderModal = useCommonVisible();
  const {
    currentRoom = {},
    currentCategory = {},
    gameCategorys = []
  } = location.state;
  const [category, setCategory] = useState(currentCategory);
  const [room, setRoom] = useState(currentRoom);
  const [currentCategoryId, setCurrentCategoryId] = useState(
    currentCategory.id
  );
  const [currentRoomId, setCurrentRoomId] = useState(currentRoom.id);
  const [roomRateList, setRoomRateList] = useState<any[]>([]);
  const [cascaderRoomList, setCascaderRoomList] = useState<any[]>([]);
  const [excludeGameList, setExcludeGameList] = useState<any[]>([]);
  const [excludeGamePageOptions, setExcludeGamePageOptions] = useState({
    page: 1,
    size: 20
  });

  useEffect(() => {
    getRebateRate();
    getExcludeGameList();
  }, [category.id, room.id]);

  useEffect(() => {
    genCascaderRoomList();
  }, [navigate]);

  const currentRoomList = useMemo(
    () =>
      cascaderRoomList.find(item => item.id === currentCategoryId)?.children ||
      [],
    [currentCategoryId, cascaderRoomList]
  );

  const getExcludeGameList = async () => {
    const result = await api.getExcludeGameList({
      ...excludeGamePageOptions,
      roomId: room.id,
      activityId
    });
    if (result.code === 200) {
      if (excludeGameList.length >= result.data.total) {
        setHasMore(false);
      } else {
        setHasMore(true);
        setExcludeGamePageOptions({
          ...excludeGamePageOptions,
          page: excludeGamePageOptions.page + 1
        });
        setExcludeGameList([
          ...excludeGameList,
          ...(result.data.records || [])
        ]);
      }
    }
  };

  const getRebateRate = async () => {
    const result = await api.getRebateRate({
      roomId: room.id,
      categoryId: category.id,
      activityId
    });
    if (result.code === 200) {
      setRoomRateList(result.data);
    }
  };

  const getRoomsByCategory = async id => {
    const result = await api.getGameRooms({ category: id });
    if (result.code === 200) {
      return result.data?.map(item => ({
        ...item,
        id: item.id
      }));
    } else {
      return [];
    }
  };

  const genCascaderRoomList = async () => {
    const result = await Promise.all(
      gameCategorys.map(async item => {
        const children = await getRoomsByCategory(item.id);
        return {
          ...item,
          _key: item.id,
          children: children.map(cItem => ({
            ...cItem,
            _key: `${item.id}-${cItem.id}`
          }))
        };
      })
    );
    setCascaderRoomList(result);
  };

  const changeRoom = item => {
    if (item._key === room._key) {
      cascaderModal.close();
      return;
    }
    setRoom(item);
    const category = cascaderRoomList.find(
      item => item.id === currentCategoryId
    );
    setCategory(category);
    setExcludeGamePageOptions({
      page: 1,
      size: 20
    });
    setExcludeGameList([]);
    setRoomRateList([]);
    cascaderModal.close();
  };

  const changeCascaderVisible = () => {
    if (cascaderModal.visible) {
      cascaderModal.close();
    } else {
      setCurrentCategoryId(category.id);
      setCurrentRoomId(room.id);
      cascaderModal.open();
    }
  };

  const columns = [
    {
      title: 'Apostas Válidas',
      prop: 'validAmount'
    },
    {
      title: 'Taxa de Rebate',
      prop: 'rate',
      render: item => <div className="text-[#FFDF12]">{item.rate}%</div>
    }
  ];

  return (
    <div className="w-full h-[calc(100vh-var(--vh-offset,0px))] rebate overflow-y-scroll">
      <NavBarComponent
        className="sticky"
        left={
          <span className="text-white text-lg font-[500]">
            Regras de Rebate
          </span>
        }
      />
      <div
        className="inline-flex items-center px-4 py-3 text-white text-base self-start"
        onClick={changeCascaderVisible}
      >
        <span>{category?.display || category?.code || category?.name}</span>
        <div className="mx-2 w-3 h-[2px] bg-[#D9D9D9]"></div>
        <span>{room?.display || room?.code || room?.name}</span>
        <img
          src={cascaderModal.visible ? top_arrow : down_arrow}
          className="w-6 h-6 ml-1"
        />
      </div>

      {/* 投注返现率表格 */}
      <div className="px-4">
        <CommonTable columns={columns} dataSource={roomRateList}></CommonTable>
      </div>

      {/* 不参与游戏列表 */}
      <div className="px-4 pt-5 flex-grow flex flex-col pb-8">
        <div className="flex items-center h-10 rounded-lg bg-[#6453FD]/70">
          <img src={activity_triangle_tip} className="w-6 h-6 mx-3" />
          <span className="text-white text-base">
            Jogos Excluídos do Rebate
          </span>
        </div>
        <div className="flex-grow py-2">
          <Grid columns={2} gap={[10, 8]}>
            {!!excludeGameList.length &&
              excludeGameList.map((item, index) => (
                <Grid.Item key={item.gameId}>
                  <div
                    className={classNames(
                      'h-10 relative rounded-sm overflow-hidden',
                      index % 2 <= 0 ? 'bg-[#6453FD]/40' : 'bg-[#6453FD]/20'
                    )}
                  >
                    <img
                      src={item.customIcon || item.icon}
                      className="w-[33px] h-full absolute left-0 top-0"
                    />
                    <div className="h-full ml-12 flex items-center">
                      <div className="text-sm text-white line-clamp-2 text-ellipsis">
                        {item.display || item.gameName}
                      </div>
                    </div>
                  </div>
                </Grid.Item>
              ))}
          </Grid>

          {excludeGameList.length ? (
            hasMore && (
              <InfiniteScroll loadMore={getExcludeGameList} hasMore={hasMore}>
                {hasMore && (
                  <>
                    <SpinLoading style={{ '--size': '16px' }} />
                    <span className="ml-[5px]">Carregando.....</span>
                  </>
                )}
              </InfiniteScroll>
            )
          ) : (
            <NoData />
          )}
        </div>
      </div>

      {/* 分类厅室过滤器 */}
      <Popup
        visible={cascaderModal.visible}
        onMaskClick={cascaderModal.close}
        bodyClassName="w-full bg-[#2F1780] bottom-0 h-5/6 rounded-t-[24px]"
      >
        <div className="w-full h-11 text-base flex justify-center items-center bg-[#6453FD]/75 text-white sticky top-0 rounded-t-[20px]">
          Condições de Filtro
        </div>
        <p className="text-[#fff] text-[17px] my-3 px-4">Filtrar jogos</p>
        <div className="flex px-4 h-[70%]">
          <div className="w-28 overflow-y-scroll">
            {cascaderRoomList.map(item => (
              <div
                key={item._key}
                onClick={() => {
                  setCurrentCategoryId(item.id);
                  setCurrentRoomId('');
                }}
                className={classNames(
                  'text-base h-11 leading-[44px] text-center rounded-lg mb-4',
                  +currentCategoryId === +item.id
                    ? 'text-white bg-[#6453FD]'
                    : 'text-white/75 bg-[#6453FD]/40'
                )}
              >
                {item.display || item.code || item.name}
              </div>
            ))}
          </div>
          <div className="flex-grow overflow-y-scroll ml-4">
            {currentRoomList.map(item => (
              <div
                key={item._key}
                onClick={() => setCurrentRoomId(item.id)}
                className={classNames(
                  'text-base h-11 leading-[44px] text-center rounded-lg mb-4',
                  currentRoomId === item.id
                    ? 'bg-[#6453FD]/70 text-white'
                    : 'bg-[#6453FD]/20 text-white/50'
                )}
              >
                {item.display || item.code || item.name}
              </div>
            ))}
          </div>
        </div>

        <div className="mx-4">
          <PopupFooter
            onCancel={cascaderModal.close}
            onConfirm={() => {
              if (currentRoomId) {
                changeRoom(
                  currentRoomList.find(item => item.id === currentRoomId)
                );
              }
            }}
          />
        </div>
      </Popup>
    </div>
  );
};

export default RebateDetail;
