import NavBarComponent from '@/components/NavBar';
import arrowRight from '@/assets/icons/common/arrow_right.png';
import unverify from '@/assets/icons/common/unverify.png';
import verify from '@/assets/icons/common/verify.png';
import eyeOff from '@/assets/icons/common/eye_off.png';
import eyeOn from '@/assets/icons/common/eye_on.png';
import phone from '@/assets/icons/common/phone.png';
import Brazilian from '@/assets/icons/common/Brazilian.png';
import { deleteUserInfo, updateUserInfo, useUserInfo } from '@/store';
import Popup from 'antd-mobile/es/components/popup';
import { useCommonVisible } from '@/hooks';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import * as api from '@/api';
import { Toast } from 'antd-mobile';
import { debounce } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PopupFooter from '@/components/PopupFooter';
import { encryptPassword } from '@/utils';
import { useNavigate, useLocation } from 'react-router-dom';
import { InputFormWrapper } from './InputFormWrapper';
import NickNamePopup from './NickNamePopup';

export const createStringValidator = (
  minLength: number,
  maxLength: number,
  minMessage: string,
  maxMessage: string
) =>
  z
    .string()
    .min(minLength, { message: minMessage })
    .max(maxLength, { message: maxMessage });

const createMinLengthValidator = (minLength: number, message: string) =>
  z.string().min(minLength, { message });

const passwordValidator = createStringValidator(
  8,
  20,
  'Defina uma senha de 8 a 20 dígitos.',
  'Defina uma senha de 8 a 20 dígitos.'
);

const phoneValidator = createMinLengthValidator(1, 'Celular é obrigatório');

const verifyPhoneSchema = z.object({
  phone: phoneValidator,
  password: passwordValidator,
  code: z
    .string()
    .length(4, { message: 'Insira o código de verificação de 4 dígitos.' })
});
type TVerifyPhoneFormData = z.infer<typeof verifyPhoneSchema>;

const passwordSchema = z
  .object({
    oldPassword: passwordValidator,
    newPassword: passwordValidator,
    confirmPassword: passwordValidator
  })
  .refine(data => data.newPassword === data.confirmPassword, {
    message: 'As senhas devem ser iguais.',
    path: ['confirmPassword']
  });
type TPasswordFormData = z.infer<typeof passwordSchema>;

const Profile = () => {
  const navigate = useNavigate();
  const userInfo = useUserInfo();
  const location = useLocation();
  const verify = location.state?.verify;
  const nickNameModal = useCommonVisible();
  const eyeStates = {
    verifyPhone: useCommonVisible(),
    oldPassword: useCommonVisible(),
    newPassword: useCommonVisible(),
    confirmPassword: useCommonVisible()
  };
  const [countdown, setCountdown] = useState(0);
  const [captchaId, setCaptchaId] = useState('');
  const { t } = useTranslation();
  const sendCodeTimer = useRef<any>(null);
  const verifyPhoneModal = useCommonVisible();
  const verifyPhoneForm = useForm<TVerifyPhoneFormData>({
    resolver: zodResolver(verifyPhoneSchema),
    defaultValues: {
      phone: userInfo.username!,
      password: '',
      code: ''
    }
  });

  const passwordModal = useCommonVisible();
  const passwordForm = useForm<TPasswordFormData>({
    resolver: zodResolver(passwordSchema),
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    }
  });

  // 应提取封装/引入ahooks useCountDown
  useEffect(() => {
    if (countdown > 0) {
      sendCodeTimer.current = setTimeout(
        () => setCountdown(countdown - 1),
        1000
      );
      return () => clearTimeout(sendCodeTimer.current);
    }
  }, [countdown]);

  useEffect(() => {
    if (verify) {
      verifyPhoneModal.open();
    }
  }, [verify]);

  const verifyPhoneSubmit = verifyPhoneForm.handleSubmit(async data => {
    const result = await api.phoneBind({
      phone: data.phone,
      password: data.password,
      captchaVal: data.code,
      captchaId
    });

    if (result.code === 200) {
      Toast.show({
        icon: 'success',
        content: t('successMsg')
      });
      verifyPhoneModal.close();
      updateUserInfo({
        ...userInfo,
        smsVerify: 1,
        phone: data.phone
      });
    }
  });

  const passwordSubmit = passwordForm.handleSubmit(async data => {
    const encryptOldPassword = `RSA_${encryptPassword(data.oldPassword)}`;
    const encryptNewPassword = `RSA_${encryptPassword(data.newPassword)}`;
    const result = await api.changePassword({
      oldPassword: encryptOldPassword,
      newPassword: encryptNewPassword
    });
    if (result.code === 200) {
      Toast.show({
        icon: 'success',
        content: t('successMsg')
      });
      passwordModal.close();
      deleteUserInfo();
      navigate('/login/1');
    }
  });

  const sendCode = debounce(async () => {
    const formValues = verifyPhoneForm.getValues();
    const result = await api.getLimitedSms({
      captchaType: 'Bind',
      phoneNumber: formValues.phone
    });
    if (result.code === 200 && result.data) {
      setCountdown(60);
      setCaptchaId(result.data.captchaId);
    }
  }, 250);

  return (
    <div className="w-full h-[calc(100vh-var(--vh-offset,0px))] overflow-y-scroll">
      <NavBarComponent
        className="sticky"
        left={
          <span className="text-white text-lg font-[500]">Configuração</span>
        }
      />

      <div className="mx-5 mt-5">
        <div className="flex items-center mb-3">
          <div className="w-[3px] h-4 rounded-[3px] bg-white mr-2" />
          <span className="text-white text-[15px]">Configurações Básicas</span>
        </div>
        <div className="rounded-lg overflow-hidden">
          <div className="flex justify-between items-center bg-[#6453FD]/40 px-5 py-3 mb-[2px]">
            <span className="text-white text-[15px]">Perfil:</span>
            <img
              src={`/images${userInfo.avatar}`}
              className="w-15 h-15 rounded-full object-cover"
            />
          </div>
          <div
            className="flex justify-between items-center bg-[#6453FD]/20 px-5 py-3 mb-[2px]"
            onClick={nickNameModal.open}
          >
            <span className="text-white text-[15px]">Apelido:</span>
            <div className="flex items-center">
              <span className="text-white/75 text-[15px]">
                {userInfo.nickname}
              </span>
              <img src={arrowRight} alt="" className="w-6 h-6" />
            </div>
          </div>
          <div className="flex justify-between items-center bg-[#6453FD]/40 px-5 py-3 mb-[2px]">
            <span className="text-white text-[15px]">Conta registrada:</span>
            <span className="text-white/75 text-[15px]">
              {userInfo.username}
            </span>
          </div>
          <div className="flex justify-between items-center bg-[#6453FD]/20 px-5 py-3">
            <span className="text-white text-[15px]">ID:</span>
            <span className="text-white/75 text-[15px]">{userInfo.userId}</span>
          </div>
        </div>
      </div>

      <div className="mx-5 mt-5">
        <div className="flex items-center mb-3">
          <div className="w-[3px] h-4 rounded-[3px] bg-white mr-2" />
          <span className="text-white text-[15px]">
            Configurações de Segurança
          </span>
        </div>
        <div className="rounded-lg overflow-hidden">
          <div
            className="flex justify-between items-center bg-[#6453FD]/40 px-5 py-3 mb-[2px]"
            onClick={() => {
              if (!userInfo.smsVerify) {
                verifyPhoneModal.open();
              }
            }}
          >
            <span className="text-white text-[15px]">Celular:</span>
            <div className="flex items-center">
              <span className="text-white/75 text-[15px]">
                {userInfo.username}
              </span>
              <img
                src={userInfo.smsVerify ? verify : unverify}
                alt=""
                className="w-6 h-6 ml-1"
              />
            </div>
          </div>
          <div
            className="flex justify-between items-center bg-[#6453FD]/20 px-5 py-3 mb-[2px]"
            onClick={passwordModal.open}
          >
            <span className="text-white text-[15px]">Senha:</span>
            <div className="flex items-center">
              <span className="text-white/75 text-[15px]">⁕⁕⁕⁕⁕⁕⁕⁕</span>
              <img src={arrowRight} alt="" className="w-6 h-6" />
            </div>
          </div>
        </div>
      </div>

      {/* 编辑昵称弹出层 */}
      <NickNamePopup nickNameModal={nickNameModal} />

      {/* 手机验证弹出层 */}
      <Popup
        visible={verifyPhoneModal.visible}
        bodyClassName="w-full bg-[#2F1780] bottom-0 h-[600px]"
      >
        <div className="w-full h-11 text-base flex justify-center items-center bg-[#6453FD]/75 text-white sticky top-0 rounded-t-lg">
          Número verificado
        </div>
        <div className="px-4 py-1">
          <div className="mb-2">
            <InputFormWrapper
              label="Celular"
              addonBefore={
                <div className="flex items-center">
                  <img src={Brazilian} alt="" className="w-6 mr-1" />
                  <span className="text-[15px] text-white font-[600]">+55</span>
                </div>
              }
              errorMsg={verifyPhoneForm.formState.errors.phone?.message}
            >
              <input
                type="text"
                {...verifyPhoneForm.register('phone')}
                placeholder="Inserir Celular"
                className="w-full !bg-transparent h-11 px-4 placeholder-[#ddd] text-white outline-none"
              />
            </InputFormWrapper>
          </div>

          <div className="mb-2">
            <InputFormWrapper
              label="senha"
              addonBefore={
                <img
                  src={eyeStates.verifyPhone.visible ? eyeOn : eyeOff}
                  className="w-6"
                  onClick={eyeStates.verifyPhone.toggle}
                />
              }
              errorMsg={verifyPhoneForm.formState.errors.password?.message}
            >
              <input
                type={eyeStates.verifyPhone.visible ? 'text' : 'password'}
                {...verifyPhoneForm.register('password')}
                placeholder="Inserir senha"
                className="w-full !bg-transparent h-11 px-3 placeholder-[#ddd] text-white outline-none"
              />
            </InputFormWrapper>
          </div>
          <div className="mb-8">
            <InputFormWrapper
              label="código de verificação"
              addonBefore={<img src={phone} alt="" className="w-6" />}
              addonAfter={
                <div
                  className="text-white/50 text-[13px] px-3 py-1 border-white/50 border-[1px] rounded-[5px]"
                  onClick={sendCode}
                >
                  {countdown > 0 ? `${countdown}s` : 'Código'}
                </div>
              }
              errorMsg={verifyPhoneForm.formState.errors.code?.message}
            >
              <input
                type="text"
                {...verifyPhoneForm.register('code')}
                placeholder="Código de verificação"
                className="w-full !bg-transparent h-11 px-3 placeholder-[#ddd] text-white outline-none"
              />
            </InputFormWrapper>
          </div>
          <PopupFooter
            onCancel={() => {
              verifyPhoneForm.reset({
                phone: userInfo.phone || userInfo.username!,
                password: '',
                code: ''
              });
              verifyPhoneModal.close();
            }}
            onConfirm={debounce(verifyPhoneSubmit, 250)}
          />
        </div>
      </Popup>

      {/* 编辑密码弹出层 */}
      <Popup
        visible={passwordModal.visible}
        bodyClassName="w-full bg-[#2F1780] bottom-0 h-[600px]"
      >
        <div className="w-full h-11 text-base flex justify-center items-center bg-[#6453FD]/75 text-white sticky top-0 rounded-t-lg">
          Configuração de Senha
        </div>
        <div className="px-4 py-1">
          <div className="mb-2">
            <InputFormWrapper
              label="original senha"
              addonAfter={
                <img
                  src={eyeStates.oldPassword.visible ? eyeOn : eyeOff}
                  className="w-6"
                  onClick={eyeStates.oldPassword.toggle}
                />
              }
              errorMsg={passwordForm.formState.errors.oldPassword?.message}
            >
              <input
                type={eyeStates.oldPassword.visible ? 'text' : 'password'}
                {...passwordForm.register('oldPassword')}
                placeholder="Digite original senha"
                className="w-full !bg-transparent h-11 px-4 placeholder-[#ddd] text-white outline-none"
              />
            </InputFormWrapper>
          </div>
          <div className="mb-2">
            <InputFormWrapper
              label="new senha"
              addonAfter={
                <img
                  src={eyeStates.newPassword.visible ? eyeOn : eyeOff}
                  className="w-6"
                  onClick={eyeStates.newPassword.toggle}
                />
              }
              errorMsg={passwordForm.formState.errors.newPassword?.message}
            >
              <input
                type={eyeStates.newPassword.visible ? 'text' : 'password'}
                {...passwordForm.register('newPassword')}
                placeholder="Digite nova senha"
                className="w-full !bg-transparent h-11 px-4 placeholder-[#ddd] text-white outline-none"
              />
            </InputFormWrapper>
          </div>
          <div className="mb-8">
            <InputFormWrapper
              label="Confirmar new senha"
              addonAfter={
                <img
                  src={eyeStates.confirmPassword.visible ? eyeOn : eyeOff}
                  className="w-6"
                  onClick={eyeStates.confirmPassword.toggle}
                />
              }
              errorMsg={passwordForm.formState.errors.confirmPassword?.message}
            >
              <input
                type={eyeStates.confirmPassword.visible ? 'text' : 'password'}
                {...passwordForm.register('confirmPassword')}
                placeholder="Digite novamente a nova senha"
                className="w-full !bg-transparent h-11 px-4 placeholder-[#ddd] text-white outline-none"
              />
            </InputFormWrapper>
          </div>
          <PopupFooter
            onCancel={() => {
              passwordForm.reset({
                oldPassword: '',
                newPassword: '',
                confirmPassword: ''
              });
              passwordModal.close();
            }}
            onConfirm={debounce(passwordSubmit, 250)}
          />
        </div>
      </Popup>
    </div>
  );
};
export default Profile;
