import request from './request';

export const getGameList = () =>
  request({
    url: '/game/info',
    method: 'GET'
  });

export const getGameRooms = params =>
  request({
    url: '/game/info/room',
    method: 'GET',
    params
  });

export const getGameListByRoom = params =>
  request({
    url: '/game/info/room/list',
    method: 'GET',
    params
  });

export const getGameCategorys = () =>
  request({
    url: '/game/info/category',
    method: 'GET'
  });

// 基于分类获取下属游戏

export const getGameListByCategory = params =>
  request({
    url: '/game/info/category/list',
    method: 'GET',
    params
  });

export const addGameCollect = data =>
  request({
    url: '/user/game/collect/add',
    method: 'POST',
    data
  });

export const deleteGameCollect = data =>
  request({
    url: '/user/game/collect/delete',
    method: 'POST',
    data
  });

export const gameCollectList = (params = {}) =>
  request({
    url: '/user/game/collect/list',
    method: 'GET',
    params
  });
