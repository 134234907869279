import { isEmpty } from 'lodash';
import request from './request';

export const saveFcmToken = (params = {}) =>
  request({
    url: '/message/updateUserMessageToken',
    method: 'GET',
    params
  });
// 是否弹框,有无渠道
export const getSpringboard = params =>
  request({
    url: !isEmpty(params) ? '/pwa/springboard' : '/guide/config/springboard',
    method: 'GET',
    params
  });
// 获取有渠道pwa安装引导配置
export const getInstallConfig = params =>
  request({
    url: '/pwa/config',
    method: 'GET',
    params
  });
// 获取无渠道pwa安装引导配置
export const getGuideConfigInfo = () =>
  request({
    url: '/guide/config/getGuideConfigInfo',
    method: 'GET'
  });
