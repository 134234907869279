// import React from 'react';
// import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import 'antd-mobile/es/global';
import App from './App.tsx';
import './index.less';
import localforage from 'localforage';
import VConsole from 'vconsole';
// import logEnv from '@/utils/testEnv';
import vhCheck from 'vh-check';

import { render } from 'react-dom';
import { AliveScope } from 'react-activation';
import { ErrorBoundary } from "react-error-boundary";

const _vhCheck = vhCheck();
console.log('vhCheck', _vhCheck);

if (import.meta.env.VITE_ENV !== 'production') {
  new VConsole();
  // setTimeout(logEnv, 500);
}


document.addEventListener('contextmenu', function(e) {
  // @ts-ignore
    if (e.target.tagName === 'IMG') {
        e.preventDefault(); // 禁用右键菜单
    }
}, false);

localforage.config({
  driver: localforage.INDEXEDDB, // 使用 IndexedDB 作为存储驱动
  name: 'localforage-database',
  version: 1.0,
  storeName: 'localforage-store' // 存储名称
});

// ReactDOM.createRoot(document.getElementById('root')!).render(
//   <AliveScope>
//     <Router>
//       <App />
//     </Router>
//   </AliveScope>
// );

const errorFallback = <div className="p-[20px] bg-[#2F1780] rounded-[10px] absolute left-[20px] right-[20px] text-center top-0 bottom-0 m-auto h-[160px] flex items-center flex-col justify-evenly border-[2px] border-[#FFDF12]">
  <p className='text-center text-2xl absolute top-1/2 left-0 right-0 -translate-y-1/2 text-center text-white'>Sorry, Something went wrong</p>
</div>;

render(
  <ErrorBoundary fallback={errorFallback}>
  <AliveScope>
    <Router>
      <App />
    </Router>
  </AliveScope>
  </ErrorBoundary>
  ,
  document.getElementById('root')
);
