import { configList } from './operation';
import request from './request';
import { SYSTEM_ACTIVITY_LIST, ACTIVITY_NAMES, ACTIVITY_DATA } from '@/consts';

// 查询查询活动配置详情
export const getConfigList = async () => {
  const { code, data } = await configList();
  if (code === 200) {
    return data
      .filter(item => item.status === 0)
      .map(handleListItem)
  }

  return [];
};

export const getPromocaoList = async () =>
  request({
    url: '/operation/activity/config/map',
    method: 'GET'
  });

const handleListItem = li => {
  const item = {
    ...ACTIVITY_DATA[li.activityType],
    ...li
  };

  if (SYSTEM_ACTIVITY_LIST.includes(item.activityType)) {
    item.link = '/promocao/' + item.linkName;
  } else {
    item.link = '/' + item.linkName;
  }

  item.name = ACTIVITY_NAMES[item.activityType] || ''

  return item;
};

export const handlePromocaoList = async () => {
  const { code, data } = await getPromocaoList();
  const res = {
    singleList: [],
    list: []
  };
  if (code === 200) {
    res.singleList = data.Single.filter(item => item.status === 0).map(
      handleListItem
    );

    // @ts-ignore
    res.list = Object.entries(data)
      .map(([k, list]) => {
        if (k !== 'Single') {
          const vlist = (list as any[])
            .filter(item => item.status === 0)
            .map(handleListItem);

          return vlist.length ? [ACTIVITY_NAMES[k], vlist] : null;
        }
      })
      .filter(Boolean);
  }

  return res;
};
