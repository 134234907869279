import { useEffect, useRef, useState } from 'react';
import { formatCountdown, getNextTimeSlotCountdown } from '.';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useIsOpenStatus, useUserInfo } from '@/store';
import { getUserInfo } from '@/tools';
import dayjs from 'dayjs';
import qs from 'qs';
import { domainConfigList } from '@/api';
import { IndexedDBHelper } from "./indexDB";
import { Toast } from 'antd-mobile';
// 倒计时
export const useCountdownTime = (initialTime = 0, onFinish?: () => void) => {
  const [totalTime, setTotalTime] = useState(initialTime);
  const intervalRef = useRef<any>(null);

  useEffect(() => {
    setTotalTime(initialTime);
  }, [initialTime]);

  useEffect(() => {
    if (initialTime > 0) {
      intervalRef.current = setInterval(() => {
        setTotalTime(prevTime => {
          if (prevTime <= 1000) {
            onFinish?.();
            clearInterval(intervalRef.current);
            return 0;
          }
          return prevTime - 1000;
        });
      }, 1000);
    } else {
      onFinish?.();
    }

    return () => clearInterval(intervalRef.current);
  }, [initialTime, totalTime]);

  const formatNumber = num => num.toString().padStart(2, '0');

  const days = Math.floor(totalTime / (24 * 60 * 60 * 1000));
  const hours = Math.floor(
    (totalTime % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000)
  );
  const minutes = Math.floor((totalTime % (60 * 60 * 1000)) / (60 * 1000));
  const seconds = Math.floor((totalTime % (60 * 1000)) / 1000);
  return [
    days,
    formatNumber(hours),
    formatNumber(minutes),
    formatNumber(seconds)
  ];
};

// 靠近下一段时间段的倒计时
export function useNextTimeSlotCountdown(timeSlots, isOpenRedRainType) {
  const [countdown, setCountdown] = useState('');
  const intervalRef = useRef<any>(null);
  useEffect(() => {
    // 活动未开始时，才显示倒计时
    if (isOpenRedRainType === 0) {
      intervalRef.current = setInterval(() => {
        const nextSlotDiff = getNextTimeSlotCountdown(timeSlots);
        if (nextSlotDiff !== null) {
          setCountdown(formatCountdown(nextSlotDiff));
        } else {
          setCountdown('');
        }
      }, 1000); // 每秒更新一次倒计时
    }

    return () => clearInterval(intervalRef.current); // 清理计时器
  }, [timeSlots]);

  return countdown;
}

// 只有这些需要登录
const noLoginUrl = [
  '/deposito',
  '/saque',
  '/mensagem',
  '/registro',
  '/profile'
];

export const useJumpLink = () => {
  const { getToken } = getUserInfo();
  const navigate = useNavigate();
  const { search } = useLocation();
  // const setTabKey = useIsOpenStatus(state => state.setTabKey);
  const updateIsOpenStatus = useIsOpenStatus(state => state.updateIsOpenStatus);

  const handlJumpLink = item => {
    if (!getToken() && noLoginUrl.includes(item.link)) {
      navigate('/login/1');
      return;
    }
    if (item.link) {
      if (item.linkType === 1) {
        if (item.link === '/saque') {
          // 提款页先展示弹窗
          // setTabKey('saque');
          updateIsOpenStatus();
        } else {
          // 内置链接
          const params = new URLSearchParams(search.split('?')[1]);
          params.set('activityId', item.activityId);
          const url = `${item.link}?${params.toString()}`;
          navigate(url);
        }
      } else if (item.target === 2) {
        // 外跳链接
        window.open(item.link, '_blank');
      } else if (item.target === 1) {
        window.location.href = item.link;
      }
    }
  };
  return [handlJumpLink];
};

export const useFormattedDate = dateString => {
  const formatDateInPortuguese = date => {
    const months = [
      'jan.',
      'fev.',
      'mar.',
      'abr.',
      'mai.',
      'jun.',
      'jul.',
      'ago.',
      'set.',
      'out.',
      'nov.',
      'dez.'
    ];
    const day = date.date();
    const month = months[date.month()];
    const year = date.year();

    return `${day} de ${month} de ${year}`;
  };

  const date = dayjs(dateString);
  const formattedDate = formatDateInPortuguese(date);

  return formattedDate;
};

// 获取域名链接
export const useGetDomain = (noActivity?: boolean) => {
  const userInfo = useUserInfo.getState();
  const { inviteCode, channelId } = userInfo;
  const [domain, setDomain] = useState('');
  const [searchParams] = useSearchParams();
  const activityId = searchParams.get('activityId');
  // 获取域名
  const queryDomainConfigList = async () => {
    const { code, data } = await domainConfigList();
    if (code == 200) {
      const domainObj =
        data.find(item => item.type == 'AgentShare') ||
        data.find(item => item.status == 1);
      const stringParams = {};
      if (inviteCode) {
        stringParams['inviteCode'] = inviteCode;
      }
      if (!noActivity) {
        // 需要ActivityId
        if (!activityId) {
          console.log('activityId', activityId);
          // Toast.show({ content: '缺少activityId', duration: 5000 })
        }else{
          stringParams['fromActivityId'] = activityId;
        }
      }
      if (channelId) {
        stringParams['ch'] = channelId;
      }
      if (domainObj) {
        setDomain(`https://${domainObj.domain}/?${qs.stringify(stringParams)}`);
      } else {
        setDomain(`${location.origin}/?${stringParams}`);
      }
    }
  };
  useEffect(() => {
    queryDomainConfigList();
  }, [inviteCode]);

  return [domain];
};





const useIndexedDB = (dbName: string, storeName: string) => {
  // const [dbHelper, setDbHelper] = useState<IndexedDBHelper | null>(null);
  const [data, setData] = useState<any[]>([]);
  const helper = new IndexedDBHelper(dbName, storeName);

  // const initDB = async () => {
  //   helper.db = await helper.init();
  //   setDbHelper(helper);
  //   const allData = await helper.getAll();
  //   setData(allData);
  //   setLoading(false);
  // };

  const addData = async (item: any) => {
    if (!helper.db) {
      // initDB();
      helper.db = await helper.init();
    }
    await helper.add(item);
    const allData = await helper.getAll();
    setData(allData);
  };

  const getData = async (id: string | number) => {
    if (!helper.db) {
      // initDB();
      helper.db = await helper.init();
    }
    // if (dbHelper) {
    return await helper.get(id);
    // }
    // return null;
  };

  return { data, addData, getData };
};

export default useIndexedDB;
