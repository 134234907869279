import request from './request';
import { loginRegisterData } from './interface';

export const login = (data: loginRegisterData) =>
  request({
    url: '/login',
    method: 'post',
    data
  });

export const getATK = (params = {}) =>
  request({
    url: '/gp/os',
    method: 'get',
    params
  });

export const logoutATK = (params = {}) =>
  request({
    url: '/gp/logoutAtk',
    method: 'get',
    params
  });

export const getGameUrl = data =>
  request({
    url: '/gp/login',
    method: 'post',
    data
  });

export const logout = () =>
  request({
    url: '/logout',
    method: 'get'
  });

export const getUserDetail = () =>
  request({
    url: '/user/detail',
    method: 'get'
  });
