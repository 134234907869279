import noData_default from '@/assets/icons/common/noData_default.png';
import noData_network from '@/assets/icons/common/noData_network.png';
import noData_resource from '@/assets/icons/common/noData_resource.png';
import noData_message from '@/assets/icons/common/noData_message.png';
import { useMemo } from 'react';

type TNoData = 'default' | 'network' | 'resource' | 'message';

interface INoDataProps {
  type?: TNoData;
}

const dataMap: Record<TNoData, { img: string; text: string }> = {
  default: { img: noData_default, text: 'Não mais' },
  network: { img: noData_network, text: 'Erro de rede' },
  resource: { img: noData_resource, text: '404 not found' },
  message: { img: noData_message, text: 'Sem mensagens' }
};

const NoData = ({ type = 'default' }: INoDataProps) => {
  const result = useMemo(() => dataMap[type] || dataMap.default, [type]);

  return (
    <div className="flex flex-col items-center justify-center mt-5">
      <img src={result?.img} className="w-52" />
      <span className="text-white/50 text-[13px] mt-4">{result?.text}</span>
    </div>
  );
};

export default NoData;
