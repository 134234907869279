import { create } from 'zustand';
import i18n from '@/i18n';
// import { timeStamp } from 'console';
import { getServicoList, handlePromocaoList } from '@/api';
// import { getPromocaoList } from '@/api';

export const useLangStore = create(() => {
  const language = localStorage.getItem('language') || 'en';
  return language;
});

/**
 *
 * @param language 要设置的语言
 */
export const changeLang = (language: string) => {
  // 修改语言
  useLangStore.setState(language);
  i18n.changeLanguage(language);
  localStorage.setItem('language', language);
};

export const useThemeStore = create(() => ({
  theme: 'theme1'
}));

/**
 *
 * @param theme 要设置的主题
 */
export const changeTheme = (theme: string) => {
  // 修改主题
  useThemeStore.setState({ theme });
};

export interface UserInfoState {
  username: string | null;
  token: string | null;
  userId?: number | null;
  userType?: number;
  groupType?: number;
  nickname?: string | null;
  avatar?: string | null;
  parentTree?: string | null;
  parentFeeTree?: string | null;
  inviterId?: string | null;
  inviteCode?: string | null;
  clientIp?: string | null;
  channelId?: string | null;
  smsVerify?: number;
  phone?: string | null;
}

// export const useUserInfo = create<UserInfoState>(() => ({
//   username: JSON.parse(localStorage.getItem('userInfo') || '{}').username || '',
//   token: JSON.parse(localStorage.getItem('userInfo') || '{}').token || ''
// }));

export const useUserInfo = create<UserInfoState>(() =>
  JSON.parse(localStorage.getItem('userInfo') || '{}')
);

/**
 *
 * @param userInfo 用户信息
 */
export const updateUserInfo = (userInfo: UserInfoState) => {
  // 修改用户信息
  useUserInfo.setState(userInfo);
  localStorage.setItem('userInfo', JSON.stringify(userInfo));
};

export const deleteUserInfo = () => {
  // 删除用户信息
  useUserInfo.setState({}, true);
  localStorage.removeItem('userInfo');
};

interface openStatusData {
  isOpen: boolean;
  tabKey: string;
  updateIsOpenStatus: () => void;
  closeIsOpenStatus: () => void;
  setTabKey: (key: string) => void;
  setOpenStatus: (status: boolean) => void;
}
/**
 * 控制活动列表展开状态
 */
export const useIsOpenStatus = create<openStatusData>(set => {
  console.log('触发初始化');
  return {
    isOpen: false,
    tabKey: '',
    updateIsOpenStatus: () =>
      set(state => {
        console.log('updateIsOpenStatus 更新');
        return { isOpen: !state.isOpen };
      }),
    closeIsOpenStatus: () =>
      set(() => {
        console.log('closeIsOpenStatus 关闭');
        return { isOpen: false, tabKey: '' };
      }),
    setTabKey: key =>
      set(() => {
        console.log('setTabKey', key);
        return { tabKey: key };
      }),
    setOpenStatus: status =>
      set(() => {
        console.log('setOpenStatus 设置', status);
        return { isOpen: status };
      })
  };
});

interface tabKeyData {
  tabKey: string;
  setTabKey: (key: string) => void;
}
export const useTabKey = create<tabKeyData>(set => {
  const tabKeys = {
    '/home': 'incio',
    '/promocao': 'promocao',
    '/servico': 'servico',
    '/userCenter': 'meu'
  };

  const tabKey = tabKeys[location.pathname] || '';

  return {
    tabKey,
    setTabKey: key =>
      set(() => {
        console.log('setTabKey', key);
        return { tabKey: key };
      })
  };
});

interface activeConfig {
  allConfigList: any;
  updateAllConfigList: (data: any) => void;
}
/**
 * 存储活动列表数据
 */
export const useActiveConfigList = create<activeConfig>(set => ({
  allConfigList: [],
  updateAllConfigList: data => set(() => ({ allConfigList: data }))
}));

// 控制首页默认开屏页面
interface showAnimateData {
  showAnimate: boolean;
  closeShowAnimate: () => void;
}
export const useShowAnimate = create<showAnimateData>(set => ({
  showAnimate: true,
  closeShowAnimate: () => set({ showAnimate: false })
}));

/**控制验证码绑定是否可关闭 */
interface phoneBoxShow {
  phoneBoxShowNum: number;
  updateShowNum: () => void;
}
/**
 * 控制手机绑定弹框展示次数 (如果不是强制验证,展示过一次之后则不展示)
 */
export const usePhoneBoxShow = create<phoneBoxShow>(set => ({
  phoneBoxShowNum: 0,
  updateShowNum: () =>
    set(state => ({ phoneBoxShowNum: state.phoneBoxShowNum + 1 }))
}));

/**
 * 服务卡片数据
 */
interface ServicoList {
  list: ServicoItem[];
  setList: (list: ServicoItem[]) => void;
  getAndUpdateList: () => void;
}

interface ServicoItem {
  id: number;
  avatar?: string; //头像
  account?: string; //跳转地址
  name?: string; //名称
  status?: string; //状态
  type?: string; //客户类型
}

const getServico = async () => {
  const { code, data } = await getServicoList();
  if (code === 200) {
    return data.filter(item => {
      return item.status == 'Online';
    });
  }

  return []
}

export const useServicoList = create<ServicoList>(set => ({
  list: [],
  setList: list => set(() => ({ list })),
  getAndUpdateList: async () => {
    try {
      const list = await getServico()
      set(() => ({ list }))
    } catch (e) {
      console.error('failed to get servicoList', e);
    }
  }
}));

// interface iPromocaoListItem {
//   // id: number;
//   // avatar?: string; //头像
//   // account?: string; //跳转地址
//   // name?: string; //名称
//   // status?: string; //状态
//   // type?: string; //客户类型
// }

interface iPromocaoList {
  list: any[];
  singleList: any[],
  setList: (list: ServicoItem[]) => void;
  getAndUpdateList: () => void;
}

export const usePromocaoList = create<iPromocaoList>(set => ({
  list: [],
  singleList: [],
  setList: list => set(() => ({ list })),
  getAndUpdateList: async () => {
    const {list, singleList} = await handlePromocaoList()
    set(() => ({ list, singleList }))
  }
}));