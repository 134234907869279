import request from './request';

// 判断注册时是否需要短信验证
export const getVerifySms = (params = {}) =>
  request({
    url: '/operation/captcha/sms/verify',
    method: 'GET',
    params
  });

// 发送验证码
export const getLimitedSms = (data = {}) =>
  request({
    url: '/operation/captcha/sms/limited',
    method: 'POST',
    data
  });

// 绑定手机号
export const phoneBind = (data = {}) =>
  request({
    url: '/phone/bind',
    method: 'POST',
    data
  });
// 获取配置信息 
export const getUserInfo = (params = {}) =>
  request({
    url: '/user/info',
    method: 'GET',
    params
  });
