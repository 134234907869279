import request from './request';

// U3用户点击REVEL按钮获取进入游戏信息
export const getRevelUrl = () =>
  request({
    url: '/activity/ggl/url',
    method: 'get',
  });

// U1进入刮卡页
export const getGglInfo = () =>
  request({
    url: '/activity/ggl/view',
    method: 'get'
  });

// U2首页显示待刮奖卡券数量
export const getGglScratched = () =>
  request({
    url: '/activity/ggl/scratched',
    method: 'get'
  });

// U4查询已领取记录列表
export const getGglRecord = (data: {page: number, size: number}) =>
  request({
    url: '/activity/ggl/record',
    method: 'post',
    data
  });