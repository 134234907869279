import { useNavigate, useSearchParams } from 'react-router-dom';
import { RightOutline } from 'antd-mobile-icons';
import pix from '@/assets/icons/saque/logo_pix.png';
import { useEffect, useState } from 'react';
import { getPayChannel } from '@/api';
import { If, Then } from 'react-if';
import { useIsOpenStatus } from '@/store';
import NavBarComponent from '@/components/NavBar';

const ChooseSaque = () => {
  const navigate = useNavigate();
  const [payType, setPayType] = useState<any>([]);
  const [searchParams] = useSearchParams();
  const closeIsOpenStatus = useIsOpenStatus(state => state.closeIsOpenStatus);

  const getChannelList = async () => {
    try {
      const { data } = await getPayChannel();
      setPayType(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getChannelList();
  }, []);

  return (
    <div className="w-full h-[calc(100vh-var(--vh-offset,0px))] overflow-y-scroll">
      <NavBarComponent
        className="sticky"
        left={<span className="text-white text-lg font-[500]">Saque</span>}
      />
      <div className="flex-1 overflow-auto mx-5">
        <div className="my-[18px] text-[15px] text-white">Canal de pagamento:</div>
        <If condition={payType.length}>
          <Then>
            {payType.map((item, index) => {
              return (
                <button
                  key={item.id}
                  onClick={() => {
                    closeIsOpenStatus();
                    navigate(
                      `/saque?${searchParams}&methodId=${item.methodId}&min=${item.minPayAmt}&max=${item.maxPayAmt}&supportTypes=${item.supportType}`
                    );
                  }}
                  className="w-[343px] h-[70px] mb-[12px] rounded-[10px] flex items-center justify-between pl-[40px] pr-[20px] border border-white"
                >
                  <img className="w-[100px] h-[36px]" src={pix} alt="" />
                  <div className="w-[1px] h-[32px] bg-white"></div>
                  <div className="flex items-center">
                    <div className="w-[36px] h-[36px] rounded-[7px] border-[1px] border-[#fff] flex items-center justify-center text-white mr-12">
                      {index + 1}
                    </div>
                    <RightOutline className="w-4 h-4 text-white" />
                  </div>
                </button>
              );
            })}
          </Then>
        </If>
      </div>
      <div className="mx-5 text-center text-[13px] absolute bottom-8 text-white">
        Após o pagamento bem-sucedido, o saldo do jogo é atualizado em até 3
        minutos. Se o pagamento falhar, por favor escolha outro canal de
        pagamento.
      </div>
    </div>
  );
};
export default ChooseSaque;
