import request from './request';

// 查询客服配置
export const getServicoList = (params = {}) =>
  request({
    url: '/operation/cs/config/list',
    method: 'get',
    params
  });

//首页轮播消息获取
export const getNoticeList = (params = {}) =>
  request({
    url: '/operation/notice/config/list',
    method: 'get',
    params
  });
//获取首页底部文案信息
export const getOperationSiteList = (params = {}) =>
  request({
    url: '/operation/site/config/list',
    method: 'get',
    params
  });

//获取未登录时点击提现弹框背景图片
export const getLoginImageInfo = (params = {}) =>
  request({
    url: '/advertising/space/getLoginImageInfo',
    method: 'get',
    params
  });
//获取网站分享配置数据
export const getGenericShare = (params = {}) =>
  request({
    url: '/generic/share',
    method: 'get',
    params
  });
