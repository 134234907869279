import { PWA_Installed } from '@/utils';
import { createContext, useContext, useEffect, useState } from 'react';

const PWAContext = createContext<PWAConfigType>({
  deferredPrompt: null,
  setDeferredPrompt: '',
  detectPercent: 0,
  setDetectPercent: ''
});

type PWAConfigType = {
  deferredPrompt;
  setDeferredPrompt;
  detectPercent;
  setDetectPercent;
};

// 后面应把所有pwa安装逻辑放到此Provider中

export const usePWAConfig = () => useContext(PWAContext);

export const PWAProvider = ({ children }) => {
  const [deferredPrompt, setDeferredPrompt] = useState(null) as any;
  const [detectPercent, setDetectPercent] = useState(0);

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', e => {
      e.preventDefault();
      setDeferredPrompt(e);
      console.log('---- beforeinstallprompt ----', e);
      setDetectPercent(100);
      localStorage.removeItem(PWA_Installed);
      // localStorage.removeItem('homeParams');
    });
  }, []);

  const config = {
    deferredPrompt,
    setDeferredPrompt,
    detectPercent,
    setDetectPercent
  };

  return <PWAContext.Provider value={config}>{children}</PWAContext.Provider>;
};
