import axios from 'axios';

const request = axios.create({
  baseURL: import.meta.env.VITE_REPORT_URL
});

export const report = data =>
  request({
    url: `/info_upload`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      ...data,
      user_ip: localStorage.getItem('clientIp')
    }
  });
