export const SYSTEM_ACTIVITY_LIST = [
  // 代理中心
  'AGENT_REWARD',
  // VIP 奖励
  'VIP_REWARD',
  // 等级奖励
  'LEVEL_REWARD',
  // 代理返现
  'AGENT_RETURN',
  // 留存奖励
  'REMAIN',
  // 兑换码
  'REDEEM',
  // 拼多多
  'PINDUODUO_REWARD'
];

export const ACTIVITY_NAMES = {
  RECHARGE_ACTIVITY: 'Depósito',
  RELIEF: 'Auxílio',
  TURNTABLE: 'Roda',
  TREASURE_BOX: 'Baú',
  REALTIME_REBATE: 'Rebate',
  RED_ENVELOPE_RAIN: 'Chuva de Dinheiro',
  CUSTOM_ACTIVITY: 'Evento',
  PINDUODUO_REWARD: 'Magnata',

  AGENT_REWARD: 'Agentes',
  VIP_REWARD: 'VIP',
  LEVEL_REWARD: 'Nivel',
  AGENT_RETURN: 'Cashback',
  REMAIN: 'Diário',
  REDEEM: 'Troca',
  
  promocao: 'Promoção',
  All: 'Tudo'
};

export const ACTIVITY_DATA = {
  // 代理返现
  AGENT_RETURN: {
    linkName: 'cashback',
    type: 'AGENT_RETURN'
  },
  // 红包雨
  RED_ENVELOPE_RAIN: {
    linkName: 'redpackage',
    type: 'RED_ENVELOPE_RAIN'
  },
  // 等级奖励
  LEVEL_REWARD: {
    linkName: 'level',
    type: 'LEVEL_REWARD'
  },
  // VIP 奖励
  VIP_REWARD: {
    linkName: 'vip',
    type: 'VIP_REWARD'
  },
  // 累充奖励
  RECHARGE_AWARD: {
    linkName: 'bonus',
    type: 'RECHARGE_AWARD'
  },
  // 留存奖励
  REMAIN: {
    linkName: 'retention',
    type: 'REMAIN'
  },
  // 代理中心
  AGENT_REWARD: {
    linkName: 'agentcenter',
    type: 'AGENT_REWARD'
  },
  // 拼多多
  PINDUODUO_REWARD: {
    linkName: 'cashwheel',
    type: 'PINDUODUO_REWARD'
  },
  // 兑换码
  REDEEM: {
    linkName: 'giftcode',
    type: 'REDEEM'
  },
  // 救济金
  RELIEF: {
    linkName: 'week',
    type: 'RELIEF'
  },
  // 超级大转盘
  TURNTABLE: {
    linkName: 'lottery',
    type: 'TURNTABLE'
  },
  REALTIME_REBATE: {
    linkName: 'rebate',
    type: 'REALTIME_REBATE'
  },
  // 宝箱活动
  TREASURE_BOX: {
    linkName: 'treasurebox',
    type: 'TREASURE_BOX'
  }
};
