import { PopupComponents } from './PopupComponents';
import { useNavigate, useSearchParams } from 'react-router-dom';
import closeIcon from '@/assets/images/close_bai.png';
import { getLoginImageInfo } from '@/api/servico';
import { useEffect, useState } from 'react';
import './loginOrRegister.less';
import { Popup } from 'antd-mobile';

export const ToLoginOrRegister = ({
  isOpen,
  setIsOpen,
  previousRouter = ''
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const queryParams = searchParams.toString();
  const [receba, setReceba] = useState<any>({});
  //获取提现的背景图
  const queryLoginImageInfo = async () => {
    try {
      const { data, code } = await getLoginImageInfo();
      if (code === 200) {
        setReceba(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    queryLoginImageInfo();
  }, []);

  return (
    <Popup
      visible={isOpen}
      onMaskClick={() => {
        setIsOpen(isOpen => !isOpen);
      }}
      // direction="BtoT"
      bodyClassName="w-full bg-transparent bottom-0 h-2/3 rounded-t-[24px]"
    >
      <div className="w-full h-full flex flex-col items-center rounded-t-[20px] bg-[#2F1780]">
        <div className="relative">
          {receba.status == 0 && (
            <img
              className="w-full rounded-t-[20px]"
              src={receba.previewImage}
              alt=""
            />
          )}
          <img
            className="size-[32px] absolute top-[15px] left-[12px]"
            onClick={() => {
              setIsOpen(false);
            }}
            src={closeIcon}
            alt=""
          />
        </div>
        <p className="text-[#fff]/85 text-[17px] mt-[20px]">
          Por favor, registrar/faça login primeiro
        </p>
        <p className="text-[#fff]/85 text-[14px] mt-[4px]">
          Você precisa de uma conta de login para jogar.
        </p>
        <div className="text-[#FFDF12] text-[15px] flex gap-[10px] mt-[30px]">
          <button
            onClick={() => {
              if (previousRouter) {
                navigate(queryParams ? `/login/1?${queryParams}` : '/login/1', {
                  state: {
                    previousRouter
                  }
                });
              } else {
                navigate(queryParams ? `/login/1?${queryParams}` : '/login/1');
              }
              setIsOpen(false);
            }}
            className="w-[96px] login-btn"
          >
            Entrar
          </button>
          <button
            onClick={() => {
              navigate(queryParams ? `/login/2?${queryParams}` : '/login/2');
              setIsOpen(false);
            }}
            className="w-[96px] register-btn bg-[#FFDF12]"
          >
            Registro
          </button>
        </div>
      </div>
    </Popup>
  );
};
