import request from './request';
// 查询查询活动配置详情
export const configDetail = params =>
    request({
      url: '/operation/activity/config/detail',
      method: 'GET',
      params
    });
  
// id查询查询活动配置详情
export const configDetailById = params =>
    request({
      url: '/operation/activity/config/by/id',
      method: 'GET',
      params
    });
  
// 查询活动配置列表
export const configList = () =>
  request({
    url: '/operation/activity/config/list',
    method: 'GET'
  });
  
// 查询首页下载配置
export const downloadConfigList = () =>
  request({
    url: '/operation/dw/config/list',
    method: 'GET'
  });
  